import React, { useState, useEffect, useRef } from 'react'
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from "react-router-dom"
// import 'react-credit-cards/es/styles-compiled.css';
import Connection from '../../connection';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import CheckIcon from '@mui/icons-material/Check';
import { ToastContainer, toast } from 'react-toastify';

import { Elements } from "@stripe/react-stripe-js";
import CheckcardForm from "./CheckcardForm";
import { loadStripe } from "@stripe/stripe-js";

function Wallet_payment({ showpayment, handleClose, handlepaymentClose, appointment_price }) {
    const navigate = useNavigate();

    const [user_id, setUser_id] = React.useState('');
    const [user_name, setUser_name] = React.useState('');

    const [showpayment_done, setShowpayment_done] = useState(false);

    useEffect(() => {
        // User ID From LocalStorage
        const auth = localStorage.getItem("customer");
        const parsed = JSON.parse(auth);
        const user_id = parsed[0].id;
        const user_name = parsed[0].name;

        setUser_id(user_id)
        setUser_name(user_name)
        /////////////
    }, [])

    const [stripePromise, setStripePromise] = useState(null);
    const [clientSecret, setClientSecret] = useState("");

    useEffect(() => {
        fetch(Connection + "webapp_cnfig").then(async (r) => {
            const { publishableKey } = await r.json();
            setStripePromise(loadStripe(publishableKey));
        });
    }, []);

    useEffect(() => {
        const amount = appointment_price * 100; // Set the desired amount
        fetch(Connection + `webap_create_intent?amount=${amount}`, {
            method: "POST",
            headers: {
                'Authorization': localStorage.getItem("JWT_TOKEN"),
            }
        })
            .then(async (result) => {
                var { clientSecret } = await result.json();
                setClientSecret(clientSecret);
            })
            .catch((error) => {
                // Handle any errors here
                console.error("Error fetching payment intent:", error);
            });
    }, []);


    const handlepaymentdoneOPEN = () => {
        setShowpayment_done(true);
        toast.success('Payment has been Successfull');
    };

    const handlepaymentdoneClose = () => {
        setShowpayment_done(false);

        document.body.style.overflow = 'auto';
        navigate('/')
    };


    return (
        <div className="modal_paymentBackground">

            <div className='modalpaymentContainer'>
                <div className="headerpaymentCloseBtn">
                    <button onClick={() => { handlepaymentClose(false); }}>
                        <ArrowBackIosNewIcon className='iconnn' />
                    </button>
                    <h3>Card Payment</h3>
                </div>


                {clientSecret && stripePromise && (
                    <Elements stripe={stripePromise} options={{ clientSecret }}>
                        <CheckcardForm handlepaymentdoneOPEN={handlepaymentdoneOPEN} appointment_price={appointment_price} user_id={user_id} />
                    </Elements>
                )}
            </div>


            <Dialog className='cancelapoint_Dialog' open={showpayment_done}>
                <div className='done_view'>
                    <CheckIcon />
                </div>

                <DialogTitle className='Cancel_dialog_toptitl'>
                    <h4>Payment processed successfully</h4>
                    <h5>You have successfully paid $ {appointment_price} from your card to your Wallet.</h5>
                </DialogTitle>

                <DialogActions className='cancelapoint_Ok_nd_Close'>
                    <Button onClick={handlepaymentdoneClose} className='cancelapoint_Ok'>DONE</Button>
                </DialogActions>

                <ToastContainer />
            </Dialog>


        </div>
    );
}

export default Wallet_payment;
import React, { useState, useEffect, useMemo, useContext } from 'react'
import Connection from "../../connection";
import "./Delete_Account.css";
import FadeLoader from "react-spinners/FadeLoader";
import { user_profile, doc_profile } from '../../Image_connection';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PulseLoader from "react-spinners/PulseLoader";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { GlobalInfo } from "../../App"

export default function Delete_Account() {
    const { loginenter } = useContext(GlobalInfo)

    const [spinner, setSpinner] = useState(false);
    const [showDialog, setShowDialog] = useState(false);

    const [profile, setProfile] = useState(null);

    const [user_id, setUser_id] = useState('');
    const [local_password, setLocal_password] = useState('');
    const [password, setPassword] = useState('');
    const [passhide, setPasshide] = useState(true);

    const seePassword = () => {
        setPasshide(!passhide);
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        const auth = localStorage.getItem("customer");
        const parsed = JSON.parse(auth);
        const id = parsed[0].id;
        const local_pasword = parsed[0].password;
        const role = parsed[0].role;
        setLocal_password(local_pasword)
        setUser_id(id)

        let profile1 = parsed[0].profile;
        if (profile1 == null) {
            setProfile(null)
        }
        else {
            let profile
            if (role == 'user') {
                profile = user_profile + profile1
            }
            else {
                profile = doc_profile + profile1
            }

            setProfile(profile)
        }
    }, [])


    const Password_Check = () => {
        if (password == "") {
            alert("Please Enter Your Password.")
        }
        else {
            if (local_password == password) {
                setShowDialog(true)
            }
            else {
                alert("Invalid Password")
            }
        }
    }

    const delete_account = async () => {
        try {
            setShowDialog(false)
            setSpinner(true)

            let result = await fetch(Connection + "delete_Account", {
                method: 'post',
                body: JSON.stringify({ user_id }),
                headers: {
                    'Authorization': localStorage.getItem("JWT_TOKEN"),
                    "Content-Type": 'application/json'
                }
            })
            result = await result.json()
            const record4 = result.response

            if (record4 === 'Token verification failed') {
                console.log('Token verification failed');
                alert("Please try again later");
            }
            else if (record4 == "fail") {
                console.log('Record fail');
                alert("Please try again later");
            }
            else {
                toast.success('You have succesfully deleted your account');
                localStorage.removeItem("customer");
                loginenter()
            }
            setSpinner(false)
        } catch (error) {
            console.error('Error:', error);
            alert("Please try again later");
            setSpinner(false);
        }
    }


    return (

        <div className="Delete_Background">

            <div className="DelteContainer">
                <div style={{ width: '100%', backgroundColor: '#874DAF', padding: '5px 10px', display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                    <img
                        style={{ width: '55px', height: '55px', borderRadius: '40px', objectFit: 'cover' }}
                        src={profile || 'https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG.png'}
                        alt="Profile"
                    />
                    <div style={{ marginLeft: '10px', justifyContent: 'center' }}>
                        <p className='delete_text'>Delete Account</p>
                    </div>
                </div>

                <p style={{ marginTop: 10, marginBottom: 10 }}>Please enter your password and confirm to delete your account</p>


                <div style={{ width: '95%', alignSelf: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 20, position: 'relative' }}>
                    <input type={passhide ? 'password' : 'text'}
                        onChange={(e) => setPassword(e.target.value)}
                        style={{ width: '100%', alignSelf: 'center', backgroundColor: '#e6e6ed', height: '40px', borderRadius: '8px', paddingLeft: '15px', color: 'black', marginTop: '7px', border: '1px solid black' }}
                        placeholder="Password"
                        value={password}
                    />

                    {passhide ? (
                        <RemoveRedEyeIcon
                            onClick={seePassword}
                            style={{ position: 'absolute', right: '10px', bottom: '7px', fontSize: '25px', color: '#7752c2', cursor: 'pointer', }}
                        />
                    ) : (
                        <VisibilityOffIcon
                            onClick={seePassword}
                            style={{ position: 'absolute', right: '10px', bottom: '7px', fontSize: '25px', color: '#7752c2', cursor: 'pointer', }}
                        />
                    )}
                </div>

                <img
                    className='imagelogoondelete'
                    src={require("../../assets/Mas_ClinicaLogo_2.png")}
                    alt="Profile"
                />

                <div className="deletebuttoContainer">
                    <button onClick={() => Password_Check()} className="customButton">
                        Delete
                    </button>
                </div>
            </div>



            <Dialog className='timedate_Dialog' open={showDialog}>
                <p style={{ margin: 20, color: 'black', fontWeight: 'bold' }}>Are you sure to want to delete your account?</p>
                <DialogActions className='timedate_Ok_nd_Close'>
                    <Button onClick={() => setShowDialog(false)} className='freeapoint_Ok'>CANCEL</Button>
                    <Button onClick={() => delete_account()} className='freeapoint_Ok'>OK</Button>
                </DialogActions>
            </Dialog>


            <ToastContainer />


            {spinner == true &&
                <div style={{ width: '100%', height: '100%', backgroundColor: 'rgba(2, 2, 2, 0.8)', position: 'absolute', top: 0, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <div style={{ display: 'flex', width: '30%', backgroundColor: 'white', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: '30px', boxShadow: '0 1px 5px rgba(0, 0, 0, 0.2)', borderRadius: '6px', }}>
                        <PulseLoader
                            color={"#874DAF"}
                            loading={spinner}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                        <p style={{ fontSize: '16px', color: '#874DAF', fontWeight: 'bold', marginLeft: '15px' }}>
                            Progressing your request
                        </p>
                    </div>
                </div>
            }
        </div>
    );
}
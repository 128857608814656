import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { user_profile, doc_profile, user_ins } from '../../Image_connection';
import "./Modal_detail.css";
import moment from 'moment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Connection from '../../connection';
import PulseLoader from "react-spinners/PulseLoader";
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import html2canvas from 'html2canvas';
import Modal_cancel_appoint from '../../MyComponent/Modal_cancel_appoint/Modal_cancel_appoint';
import Modal_reschedual_appoint from '../../MyComponent/Modal_reschedual_appoint/Modal_reschedual_appoint';

import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import VideocamIcon from '@mui/icons-material/Videocam';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

function Modal({ handleClose, getProducts, get_name, get_address, get_email, get_mobile_number, get_id, get_fcm_token, get_web_fcm, get_voip_token, get_profile, profile_url, get_online_status, get_gender, get_experience, payment_method, date, day, time, appointment_id, fee, status, category, appointment_type, user_type, appointment_review }) {

   const navigate = useNavigate();

   const [spinner, setSpinner] = useState(false);
   const [skalton, setSkalton] = useState(true);

   const [front_image, setFront_image] = useState(null);
   const [back_image, setBack_image] = useState(null);

   const [data, setData] = useState([]);
   const [reason, setReason] = useState('');
   const [symptoms, setSymptoms] = useState('');
   const [issue, setIssue] = useState('');
   const [allergic, setAllergic] = useState('');
   const [medication, setMedication] = useState('');

   const [id, setId] = useState('');
   const [my_name, setMy_name] = useState('');
   const [role, setRole] = useState('');
   const [user_address, setUser_address] = useState('');

   const [text1, setText1] = useState(2);
   const [text2, setText2] = useState(1);
   const [checkDesign, setCheckDesign] = useState('Details');

   const [cancelapoinSho, setCancelapoinSho] = useState(false);
   const [reschedualapoinSho, setReschedualapoinSho] = useState(false);

   const changeBtn = (text, val) => {
      setText1(1);
      setText2(1);

      if (text === "text1") {
         setText1(2);
      }
      else if (text === "text2") {
         setText2(2);
      }

      setCheckDesign(val);
   };

   useEffect(() => {
      const auth = localStorage.getItem("customer");
      const parsed = JSON.parse(auth);
      const id = parsed[0].id;
      const name = parsed[0].name;
      const role = parsed[0].role;
      const user_address = parsed[0].address;

      setId(id)
      setMy_name(name)
      setRole(role)
      setUser_address(user_address)

      getIssueAppointment(id);

      const jwt_tokens = localStorage.getItem("JWT_TOKEN");
      if (payment_method == 'Insurance') {
         checkinsurancehaveornot(id, jwt_tokens)
      }
   }, [])


   const checkinsurancehaveornot = async (user_id, Token) => {
      try {
         let result = await fetch(Connection + "check_insurance", {
            method: 'post',
            body: JSON.stringify({ user_id }),
            headers: {
               'Authorization': Token,
               "Content-Type": 'application/json'
            }
         })
         result = await result.json()
         const record4 = result.response

         if (record4 === 'Token verification failed') {
            console.log(record4);
         }
         else if (record4 == 'success') {
            const reco = result.data

            let frnt_age = reco[0].front_image
            let front_image = user_ins + frnt_age

            let bac_imag = reco[0].back_image
            let back_image = user_ins + bac_imag

            setFront_image(front_image)
            setBack_image(back_image)
         }
         else { }
      } catch (error) {
         console.error('Error:', error);
      }
   };

   const getIssueAppointment = async (id) => {
      try {
         setSkalton(true);

         let result = await fetch(Connection + "getissue_appointment_for_doctor", {
            method: 'post',
            body: JSON.stringify({ id, appointment_id }),
            headers: {
               'Authorization': localStorage.getItem("JWT_TOKEN"),
               "Content-Type": 'application/json'
            }
         })
         const response = await result.json();
         let response1 = response.response;

         if (response1 === 'Token verification failed') {
            console.log(response1);
         }
         else if (response1 === 'fail') {
            console.log(response1);
         }
         else {
            let reason = response1[0].reason;
            let symptoms = response1[0].symptoms;
            let issue = response1[0].issue;
            let allergic = response1[0].allergic;
            let medication = response1[0].medication;

            setData(response1);
            setReason(reason);
            setSymptoms(symptoms);
            setIssue(issue);
            setAllergic(allergic);
            setMedication(medication);
         }

         setSkalton(false);
      } catch (error) {
         console.error('Error:', error);
         setSkalton(false)
      }
   };


   const complete_appointment = async () => {
      try {
         if (!medication) {
            alert("Please enter Physician Solution")
         }
         else {
            setSpinner(true);

            // Date And Time For Notification Date 
            const aa = moment(new Date()).format("YYYY-MM-DD hh:mm A");
            const split = aa.split(' ')
            const notification_date = split[0]
            const n_time = split[1]
            const am_pm = split[2]
            const notification_time = n_time + "" + am_pm
            //////////////////////////////

            let tax = 6
            let d_earning = Number(fee - tax).toFixed(2);
            let type = 'complete'

            let amount;
            if (user_type === "hisd") {
               amount = '0'
            }
            else {
               amount = d_earning;
            }

            let doctor_id = id
            let user_id = get_id
            let user_name = get_name
            let doctor_name = my_name

            let response = await fetch(Connection + "complete_doc_appointment_a", {
               method: 'post',
               body: JSON.stringify({ doctor_id, user_id, appointment_id, medication, amount, notification_date, notification_time, type, user_name, doctor_name }),
               headers: {
                  'Authorization': localStorage.getItem("JWT_TOKEN"),
                  "Content-Type": 'application/json'
               }
            })
            const result = await response.json();
            const record4 = result.response

            if (record4 == "Token verification failed") {
               console.log("When Token verification failed =>", record4)

               setSpinner(false);
               alert("Something went wrong try again later");
            }
            else if (record4 === "success4") {
               toast.success('You have successfully completed this appointment.');

               if (user_type !== "hisd") {
                  const user = localStorage.getItem("customer");
                  let parsed = JSON.parse(user);
                  const balance = parseFloat(parsed[0].balance);

                  if (balance !== '') {
                     const get_balance = parseFloat(parsed[0].balance);
                     const dd_earning = parseFloat(d_earning);
                     parsed[0].balance = get_balance + dd_earning;
                     localStorage.setItem("customer", JSON.stringify(parsed))
                  }
                  else {
                     const dd_earning = parseFloat(d_earning);
                     parsed[0].balance = balance + dd_earning;
                     localStorage.setItem("customer", JSON.stringify(parsed))
                  }
               }

               setSpinner(false);
               handleClose(false)
               app_notification()
               web_notification()
               const jwt_tokens = localStorage.getItem("JWT_TOKEN");
               getProducts(doctor_id, role, jwt_tokens)
            }
            else {
               setSpinner(false);
               alert("Something went wrong try again later");
            }
         }
      } catch (error) {
         console.error('Error:', error);
         setSpinner(false);
         alert("Something went wrong try again later");
      }
   };


   const app_notification = async () => {
      let to = get_fcm_token;
      console.log("To is => ", to)

      fetch('https://fcm.googleapis.com/fcm/send', {
         method: 'POST',
         headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'key= AAAAaCD4Bcc:APA91bETamRJX5KYdE6sCHUzquj_DpmfqiqH3ogXH1K1m1SH5U5_60OPWrPP5hHMyq_1pQcUN2WMWAPpCOzFDJJ4lDt6X3IDNwLuohzTB8ja3EDGM-FDTePfmCEYpFTsUoH-wguvF6Ux'//cloud server key
         },
         body: JSON.stringify({
            "to": to,
            "priority": "high",
            "notification": {
               "title": "Mas Clinicas",
               "body": "Dr." + my_name + " is complete appointment with you.",
               "sound": 'default',
               "icon": "myicon",
            }
         }
         )
      }).then(res => res.json())
         .then(resjson => console.log("test", resjson))
         .catch(err => console.log("error =>", err))
   }


   const web_notification = async () => {
      let to = get_web_fcm;
      console.log("To is => ", to)

      fetch('https://fcm.googleapis.com/fcm/send', {
         method: 'POST',
         headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'key= AAAAPzWsmh8:APA91bGwGLlejpat2ndQPc3708f5KqvTPRFm9oZ7RQyBB9khWgUn_2JFZJdIf5Lta7dS2M9wTj_OYaxxmZ3FRVckZGoDJpEWyUC81G1hJliAhiKUGaZJS0P3cP-T_Z5VwGKPQzNsk_75'//cloud server key
         },
         body: JSON.stringify({
            "to": to,
            "priority": "high",
            "notification": {
               "title": "Mas Clinicas",
               "body": "Dr." + my_name + " is complete appointment with you.",
               "sound": 'default',
               "icon": "myicon",
            }
         }
         )
      }).then(res => res.json())
         .then(resjson => console.log("test", resjson))
         .catch(err => console.log("error =>", err))
   }


   const Accept_appointment = async () => {
      try {
         setSpinner(true)

         let status;
         if (payment_method == 'Insurance') {
            status = 'wait_for_pay'
         }
         else {
            status = 'pending'
         }

         let result = await fetch(Connection + "do_it_pending", {
            method: 'post',
            body: JSON.stringify({ appointment_id, status }),
            headers: {
               'Authorization': localStorage.getItem("JWT_TOKEN"),
               "Content-Type": 'application/json'
            }
         })
         const response = await result.json();
         const record4 = response.response;

         if (record4 === 'Token verification failed') {
            console.log('Token verification failed');
            alert("Please try again later");
         }
         else if (record4 === 'fail') {
            console.log('Record Fail');
            alert("Please try again later");
         }
         else {
            toast.success('Please be available on appointment time');

            handleClose(false)
            const jwt_tokens = localStorage.getItem("JWT_TOKEN");
            getProducts(id, role, jwt_tokens)
         }

         setSpinner(false)
      } catch (error) {
         console.error('Error:', error);
         setSpinner(false);
         alert("Please try again later");
      }
   };



   const handleDownloadImage = () => {
      const prescriptionContainer = document.querySelector('.prescription-container');

      if (prescriptionContainer) {
         html2canvas(prescriptionContainer)
            .then((canvas) => {
               const url = canvas.toDataURL('image/png');

               // Create an anchor element and trigger the download
               const a = document.createElement('a');
               a.href = url;
               a.download = `Prescription_${appointment_id}.png`; // You can customize the filename
               document.body.appendChild(a);
               a.click();

               // Cleanup
               document.body.removeChild(a);
            })
            .catch((error) => {
               console.error('Error capturing image:', error);
            });
      }
   };


   const agora_notification = async () => {
      console.log("fcm_token", get_fcm_token)
      if ((get_fcm_token === null || get_fcm_token === '') && (get_web_fcm === null || get_web_fcm === '')) {
         alert("This user is currently unavailable.");
      }
      else {
         document.body.style.overflow = 'auto';

         const call_check = 'do_call'
         const url = `/AgoraCall?call_check=${call_check}&fcm_token=${get_fcm_token}&web_fcm=${get_web_fcm}&role=${role}&deviceToken=${get_voip_token}`;
         navigate(url);
      }
   };


   const chat_romm = async () => {
      document.body.style.overflow = 'auto';

      const check_chat = 'do_chat'
      const url = `/Chat?check_chat=${check_chat}&receiver_image=${profile_url}&chat_image=${get_profile}&chat_name=${get_name}&receiver_id=${get_id}&fcm_token=${get_fcm_token}&web_fcm=${get_web_fcm}&active=${get_online_status}&sender_name=${my_name}&receiver_name=${get_name}&chat_name_disable=${get_name}`;
      navigate(url);
   }


   const alertfreenotcancel = () => {
      alert("You can't cancel HISD Select appointment.")
   }

   const handlecancelappoint = () => {
      setCancelapoinSho(false);
   };

   const handlerescheappoint = () => {
      setReschedualapoinSho(false);
   };


   return (
      <div className="modal_detailBackground">

         <div className="modaldetailContainer">
            <div className="titledetailCloseBtn">
               <button onClick={() => { handleClose(false); }}>
                  <ArrowBackIcon />
               </button>
               {appointment_type == 'urgent' ?
                  <h3>Urgent Care</h3>
                  :
                  <h3>Appointment Details</h3>
               }
               <h1></h1>
            </div>

            <hr style={{ marginTop: 15, marginBottom: 3 }} />

            {status == 'complete' && role == 'user' &&
               <div style={{ width: '90%', backgroundColor: 'white', borderRadius: 10, margin: '10px auto', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', boxShadow: '0px 2px 3.84px rgba(0, 0, 0, 0.25)', elevation: 5, paddingTop: 3, paddingBottom: 3 }}>
                  <button style={{ width: '49%' }} onClick={() => changeBtn('text1', 'Details')} className={text1 === 1 ? 'inactive-button' : 'active-button'}>
                     <span className={text1 === 1 ? 'inactive-text' : 'active-text'}>{'Details'}</span>
                  </button>

                  <button style={{ width: '49%' }} onClick={() => changeBtn('text2', 'Prescription')} className={text2 === 1 ? 'inactive-button' : 'active-button'}>
                     <span className={text2 === 1 ? 'inactive-text' : 'active-text'}>{'Prescription'}</span>
                  </button>
               </div>
            }

            {role == 'doctor' && (status == "complete" || status == "pending") &&
               <div style={{ width: '90%', backgroundColor: 'white', borderRadius: 10, margin: '10px auto', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', boxShadow: '0px 2px 3.84px rgba(0, 0, 0, 0.25)', elevation: 5, paddingTop: 3, paddingBottom: 3 }}>
                  <button style={{ width: '49%' }} onClick={() => changeBtn('text1', 'Details')} className={text1 === 1 ? 'inactive-button' : 'active-button'}>
                     <span className={text1 === 1 ? 'inactive-text' : 'active-text'}>{'Details'}</span>
                  </button>

                  <button style={{ width: '49%' }} onClick={() => changeBtn('text2', 'Prescription')} className={text2 === 1 ? 'inactive-button' : 'active-button'}>
                     <span className={text2 === 1 ? 'inactive-text' : 'active-text'}>{'Prescription'}</span>
                  </button>
               </div>
            }

            {checkDesign == "Details" &&
               <>
                  {role == 'doctor' ?
                     <>
                        <div className="profile_container">
                           <div className="profile_image_container">
                              {get_profile ?
                                 <img className="profile_image" src={profile_url} alt="doctor-image" />
                                 :
                                 <img className="profile_image" src={require('../../assets/empty.png')} alt="Empty" />
                              }
                           </div>

                           <div className="profile_info">
                              <p className="user_name">{get_name}</p>
                              <p className="gender">{get_gender}</p>
                           </div>
                        </div>

                        {payment_method == 'Insurance' &&
                           <>
                              {status === 'request' &&
                                 <button onClick={Accept_appointment}
                                    className="accepted-button"
                                 >
                                    <p style={{ color: 'white', fontWeight: 'bold' }}>Accept</p>
                                 </button>
                              }
                           </>
                        }

                        <div className="info_container">
                           <div className="info_row">
                              <i className="material-icons icon"><EmailOutlinedIcon /></i>
                              <p className="info-text">{get_email}</p>
                           </div>

                           <div className="info_row">
                              <i className="ionicons icon"><CallOutlinedIcon /></i>
                              <p className="info-text">{get_mobile_number}</p>
                           </div>

                           <div className="info_row">
                              <i className="ionicons icon"><LocationOnOutlinedIcon /></i>
                              <p className="info-text">{get_address}</p>
                           </div>
                        </div>


                        {status === 'pending' && (
                           <div className="button_container">
                              <button className="chat_button" onClick={() => chat_romm()}>
                                 <QuestionAnswerIcon style={{ color: '#874DAF', fontSize: '18px' }} />
                                 <span>Chat with Patient</span>
                              </button>

                              <button className="video_main_div">
                                 <div onClick={() => agora_notification()} className="video_button">
                                    <VideocamIcon style={{ color: '#0751fc', fontSize: '25px' }} />
                                 </div>
                              </button>
                           </div>
                        )}

                        {appointment_type != 'urgent' &&
                           <>
                              <div className="divider"></div>
                              <div className="date-time-container">
                                 <p className="gray-text">Appointment Date & Time</p>
                                 <p className="bold-text">{`${day} ${date} | ${time}`}</p>
                              </div>
                           </>
                        }

                        <div className="divider"></div>

                        <div className="payment-info">
                           <div className="payment-header">
                              <p className="payment-title">Payment Method</p>
                              <div className="payment-status">
                                 <CheckOutlinedIcon style={{ color: '#874DAF' }} />
                                 <span className="status-text">{payment_method}</span>
                              </div>
                           </div>
                        </div>
                     </>
                     :
                     <div className="doctor-details-container">
                        <div className="doctor-info">
                           <div>
                              <h2 className="doctor-name">Dr. {get_name}</h2>
                              <p className="doctor-category">{category} | {get_experience} years</p>
                           </div>

                           <div className="profile-image-container">
                              {get_profile ?
                                 <img className="profile-image" src={profile_url} alt="doctor-image" />
                                 :
                                 <img className="profile-image" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIEFLVeftvEbJfqWQ2hnRMSPuF_b8VMn4PdPhXOJdkPm-bfdpWTZZYAUJyHVXS4wrsDAE&usqp=CAU" alt="doctor-image" />
                              }
                           </div>
                        </div>

                        <div className="additional-details">
                           <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                              <span className="icon">&#128249;</span>
                              <p className="detail-text">Online Consultation</p>
                           </div>

                           <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                              <span className="icon">&#128197;</span>
                              <p className="detail-text">{day}, {date} / {time}</p>
                           </div>

                           <hr className="divider" />

                           <div className="patient-info">
                              <div className="info-item">
                                 <p className="info-label">Patient Name :</p>
                                 <p className="info-value">{my_name}</p>
                              </div>

                              <div className="info-item">
                                 <p className="info-label">Address :</p>
                                 <p className="info-value">{get_address}</p>
                              </div>
                           </div>

                           <hr className="divider" />

                           <div className="payment-details">
                              <p className="payment-label">Amount to Pay</p>
                              <p className="payment-value">${fee}</p>
                           </div>
                        </div>
                     </div>
                  }


                  {status == 'pending' && role == 'user' && appointment_type == 'online' &&
                     <div className="appointment-buttons">
                        {user_type == 'hisd' ?
                           <button onClick={() => alertfreenotcancel()} className="cancel-button">
                              Cancel
                           </button>
                           :
                           <button onClick={() => { setCancelapoinSho(true) }} className="cancel-button">
                              Cancel
                           </button>
                        }

                        <div style={{ width: 1, backgroundColor: 'lightgray', height: '100%' }}></div>
                        <button onClick={() => { setReschedualapoinSho(true) }} className="reschedule-button">
                           Reschedule
                        </button>
                     </div>
                  }

                  <div className="your-component-container">
                     {skalton ? (
                        <div className="skalton-container">
                           <PulseLoader
                              color={"gray"}
                              loading={skalton}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                           />
                        </div>
                     ) : (
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

                           {payment_method == 'Insurance' &&
                              <>
                                 <div className="data-container" style={{ marginBottom: 10 }}>
                                    <p style={{ color: "#874DAF", fontWeight: 'bold', fontSize: 14 }}>Front Image</p>

                                    <hr className="divider" />

                                    <img src={front_image} alt="" className="imageshowindetail" />
                                 </div>

                                 <div className="data-container" style={{ marginBottom: 10 }}>
                                    <p style={{ color: "#874DAF", fontWeight: 'bold', fontSize: 14 }}>Front Image</p>

                                    <hr className="divider" />

                                    <img src={back_image} alt="" className="imageshowindetail" />
                                 </div>
                              </>
                           }


                           {data.length === 0 ? (
                              <div className="no-data-container">
                                 <p style={{ color: 'black' }}>No treatment result</p>
                              </div>
                           ) : (
                              <div className="data-container">
                                 <p style={{ color: "#874DAF", fontWeight: 'bold', fontSize: 14 }}>More Details</p>

                                 <hr className="divider" />

                                 <div className="detail-row">
                                    <p style={{ color: 'black', fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Reason:</p>
                                    <p style={{ color: 'black', fontSize: 12, maxWidth: '90%' }}>{reason}</p>
                                 </div>

                                 <div className="detail-row">
                                    <p style={{ color: 'black', fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Symptoms:</p>
                                    <p style={{ color: 'black', fontSize: 12, maxWidth: '90%' }}>{symptoms}</p>
                                 </div>

                                 <div className="detail-row">
                                    <p style={{ color: 'black', fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Issues:</p>
                                    <p style={{ color: 'black', fontSize: 12, maxWidth: '90%' }}>{issue}</p>
                                 </div>

                                 <div className="detail-row">
                                    <p style={{ color: 'black', fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Allergic:</p>
                                    <p style={{ color: 'black', fontSize: 12, maxWidth: '90%' }}>{allergic}</p>
                                 </div>
                              </div>
                           )}
                        </div>
                     )}
                  </div>
               </>
            }


            {checkDesign == "Prescription" &&
               <div className="your-component-container">
                  {skalton ? (
                     <div className="skalton-container">
                        <PulseLoader
                           color={"gray"}
                           loading={skalton}
                           aria-label="Loading Spinner"
                           data-testid="loader"
                        />
                     </div>
                  ) : (
                     <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        {data.length === 0 ?
                           <div className="no-data-container">
                              <p style={{ color: 'black' }}>No treatment result</p>
                           </div>
                           :
                           <>
                              {status === 'complete' &&
                                 <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '-25px' }}>
                                    <div className="prescription-container">
                                       <p className="prescription-title">Prescription</p>
                                       <div className="divider"></div>
                                       <p className="prescription-text">{medication}</p>
                                    </div>

                                    <button onClick={handleDownloadImage}
                                       className="download-button"
                                    >
                                       <DownloadOutlinedIcon style={{ color: 'white', fontSize: 22, marginRight: 5 }} />
                                       <p style={{ color: 'white', fontWeight: 'bold' }}>Download</p>
                                    </button>
                                 </div>
                              }


                              {status == 'pending' && role == 'doctor' &&
                                 <>
                                    <p className="physician-solution">Physician Solution</p>
                                    <textarea
                                       onChange={(e) => setMedication(e.target.value)}
                                       value={medication}
                                       className="text-input"
                                       placeholder={"Visit Summary"}
                                    ></textarea>
                                    <button onClick={() => complete_appointment()}
                                       className={'button-android'}
                                    >
                                       <p style={{ color: 'white', fontWeight: 'bold' }}>Complete</p>
                                    </button>
                                 </>
                              }
                           </>
                        }
                     </div>
                  )}
               </div>
            }


         </div>

         {cancelapoinSho == true &&
            <Modal_cancel_appoint handleClose={handleClose} handlecancelappoint={handlecancelappoint} get_apointments={getProducts} appointment_id={appointment_id} doctor_id={get_id} user_id={id} doctor_nam={get_name} user_nam={my_name} user_email={get_email} date={date} time={time} fee={fee} fcm_token={get_fcm_token} web_fcm={get_web_fcm}>
               <p>Modal Content Goes Here</p>
            </Modal_cancel_appoint>
         }


         {reschedualapoinSho == true &&
            <Modal_reschedual_appoint handleClose={handleClose} handlerescheappoint={handlerescheappoint} get_apointments={getProducts} appointment_id={appointment_id} doctor_id={get_id} user_id={id} doctor_nam={get_name} user_nam={my_name} user_email={get_email} category={category} appointme_date={date} time={time} fcm_token={get_fcm_token} web_fcm={get_web_fcm} type_of={appointment_type}>
               <p>Modal Content Goes Here</p>
            </Modal_reschedual_appoint>
         }


         {spinner && (
            <div style={{ width: '100%', height: '100%', backgroundColor: 'rgba(2, 2, 2, 0.8)', position: 'absolute', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
               <div style={{ display: 'flex', width: '30%', backgroundColor: 'white', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: '30px', boxShadow: '0 1px 5px rgba(0, 0, 0, 0.2)', borderRadius: '6px', }}>
                  <PulseLoader
                     color={"#874DAF"}
                     loading={spinner}
                     aria-label="Loading Spinner"
                     data-testid="loader"
                  />
                  <p style={{ fontSize: '16px', color: '#874DAF', fontWeight: 'bold', marginLeft: '15px' }}>
                     Progressing your request
                  </p>
               </div>
            </div>
         )}

      </div>
   );
}

export default Modal;

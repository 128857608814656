import React, { useState, useEffect } from 'react'
import './Clinic_Number.css';
import { doc_profile } from '../../Image_connection';
import Connection from "../../connection";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PulseLoader from "react-spinners/PulseLoader";

function Insurance_fee({ handleClose }) {
    const [user_id, setUser_id] = React.useState('');
    const [insurance_fee, setInsurance_fee] = React.useState('');
    const [profile, setProfile] = useState(null);

    const [spinner, setSpinner] = useState(false);


    useEffect(() => {
        const auth = localStorage.getItem("customer");
        const parsed = JSON.parse(auth);
        const id = parsed[0].id;
        const insurance_fee = parsed[0].insurance_fee;

        setUser_id(id)
        setInsurance_fee(insurance_fee)


        let profile1 = parsed[0].profile;
        if (profile1 == null) {
            setProfile(null)
        }
        else {
            let profile = doc_profile + profile1
            setProfile(profile)
        }
    }, [])


    const add_insurance_fee = async () => {
        try {
            if (insurance_fee == "" || insurance_fee == null) {
                alert("Please enter your clinic number.");
            }
            else {
                setSpinner(true)
                const id = user_id
                let result = await fetch(Connection + "add_insurance_fee", {
                    method: 'post',
                    body: JSON.stringify({ insurance_fee, id }),
                    headers: {
                        'Authorization': localStorage.getItem("JWT_TOKEN"),
                        "Content-Type": 'application/json'
                    }
                })
                result = await result.json()
                const record4 = result.response

                if (record4 === 'Token verification failed') {
                    console.log(record4);
                    alert("Please try again later");
                }
                else if (record4 === "fail") {
                    alert("Please try again later");
                }
                else {
                    const user = localStorage.getItem("customer");
                    let parsed = JSON.parse(user);
                    parsed[0].insurance_fee = insurance_fee;
                    localStorage.setItem("customer", JSON.stringify(parsed))
                    toast.success('You have successfully add your insurance fee.');
                }
                setSpinner(false)
            }
        } catch (error) {
            console.error('Error:', error);
            setSpinner(false);
        }
    }


    return (
        <div className="Clinicnumberbground">

            <div className="ClinicnumberContainer">
                <div style={{ width: '97%', backgroundColor: '#874DAF', padding: '5px 10px', display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: 15, alignSelf: 'center' }}>
                    <img
                        style={{ width: '55px', height: '55px', borderRadius: '40px', objectFit: 'cover' }}
                        src={profile || 'https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG.png'}
                        alt="Profile"
                    />
                    <div style={{ marginLeft: '10px', justifyContent: 'center' }}>
                        <p style={{ color: 'white', fontWeight: 'bold', fontSize: '18px', margin: '0' }}>Insurance Fee</p>
                    </div>
                </div>

                <h3 style={{ color: 'gray', fontWeight: 'normal', fontSize: 14, marginLeft: 15, marginRight: 15, marginTop: 15, marginBottom: 10 }}>Here you can add your Insurance Fee.</h3>


                <div className="label">Insurance Fee</div>
                <input
                    type="text"
                    placeholder="Enter Insurance Fee"
                    className="phoneinput"
                    value={insurance_fee}
                    onChange={(e) => setInsurance_fee(e.target.value)}
                />


                {insurance_fee == null || insurance_fee == '' ?
                    <div onClick={() => add_insurance_fee()} className='Clinicnumber_btn'>
                        <p>Add Insurance Fee</p>
                    </div>
                    :
                    <div onClick={() => add_insurance_fee()} className='Clinicnumber_btn'>
                        <p>Update Insurance Fee</p>
                    </div>
                }

                <ToastContainer />
            </div>


            {spinner == true &&
                <div style={{ width: '100%', height: '100%', backgroundColor: 'rgba(2, 2, 2, 0.8)', position: 'absolute', top: 0, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <div style={{ display: 'flex', width: '30%', backgroundColor: 'white', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: '30px', boxShadow: '0 1px 5px rgba(0, 0, 0, 0.2)', borderRadius: '6px', }}>
                        <PulseLoader
                            color={"#874DAF"}
                            loading={spinner}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                        <p style={{ fontSize: '16px', color: '#874DAF', fontWeight: 'bold', marginLeft: '15px' }}>
                            Progressing your request
                        </p>
                    </div>
                </div>
            }
        </div>
    );
}

export default Insurance_fee;

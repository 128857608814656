import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom"
import { useStripe, useElements } from "@stripe/react-stripe-js";
// import "./Modal_Payment.css";
import Connection from '../../connection';
import PulseLoader from "react-spinners/PulseLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function CheckcardForm({ handlepaymentdoneOPEN, appointment_price, user_id }) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const [spinner, setSpinner] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    try {
      console.log("Before ConfirmPayment");
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/completion`,
        },
        redirect: "if_required",
      });

      if (error) {
        if (error.type === "card_error" || error.type === "validation_error") {
          setMessage(error.message);
        }
        else {
          setMessage("An unexpected error occurred.");
        }
      }
      else {
        Add_wallet()
      }

      console.log("After ConfirmPayment");
    }
    catch (error) {
      console.error("Error confirming payment:", error);
      setMessage("An unexpected error occurred.");
    }

    setIsProcessing(false);
  };


  const Add_wallet = async () => {
    try {
      let amount = appointment_price

      let result = await fetch(Connection + "Add_wallet_a", {
        method: 'post',
        body: JSON.stringify({ user_id, amount }),
        headers: {
          'Authorization': localStorage.getItem("JWT_TOKEN"),
          "Content-Type": 'application/json'
        }
      })

      result = await result.json()
      let record = result.response

      if (record === 'Token verification failed') {
        console.log(record);
        alert("Something went wrong try again later");
      }
      if (record === "success") {
        let userData = result.user;
        console.log("User Data:", userData);

        toast.success('Payment has been Successfull');
        localStorage.setItem("customer", JSON.stringify(userData))

        handlepaymentdoneOPEN();
      }
      else {
        alert("Something went wrong try again later");
      }
      setIsProcessing(false);
    } catch (error) {
      console.error('Error:', error);
      setIsProcessing(false);
      alert("Something went wrong try again later");
    }
  }


  return (
    <>
      <form id="payment_form" onSubmit={handleSubmit}>
        <PaymentElement id="payment_element" />

        <button className="payments_button" disabled={isProcessing || !stripe || !elements} id="submit">
          <span id="button-text">
            {isProcessing ? "Processing ... " : "Pay using this card"}
          </span>
        </button>

        {message && <div id="payment-message">{message}</div>}
      </form>

      {spinner == true &&
        <div className='spinner_main_loader'>
          <div className='spinner_inside'>
            <PulseLoader
              color={"#874DAF"}
              loading={spinner}
              //  size={5}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        </div>
      }

      <ToastContainer />
    </>
  );
}
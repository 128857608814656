import React, { useState, useEffect } from 'react'
import Connection from "../../connection";
import { doc_profile } from '../../Image_connection';
import {
   AccessTime,
   PhotoCamera
} from "@material-ui/icons";
import { Link, parsePath, useParams, useNavigate, useLocation } from "react-router-dom"
import Modal from '../../MyComponent/Modal/Modal';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

export default function Doctor_by_category() {
   const params = useParams();
   let propscategory;
   if (params.category) {
      propscategory = params.category
   }
   const location = useLocation();
   const searchParams = new URLSearchParams(location.search);
   const type_of = searchParams.get('value1');
   const usertype = searchParams.get('value2');

   const navigate = useNavigate();

   const [doctor, setDoctor] = useState([]);

   const [doctor_id, setDoctor_id] = React.useState("");
   const [doctor_name, setDoctor_name] = React.useState("");
   const [insurance_fee, setInsurance_fee] = React.useState("");
   const [fee, setFee] = React.useState("");
   const [category, setCategory] = React.useState("");
   const [city, setCity] = React.useState("");
   const [email, setEmail] = React.useState("");
   const [fcm_token, setFcm_token] = React.useState("");
   const [web_fcm, setWeb_fcm] = React.useState("");

   const [skalton, setSkalton] = React.useState(false);

   const [patLoading, setPatLoading] = useState(false);

   const [show, setShow] = useState(false);

   const handleShow = (doctor_id, doctor_name, insurance_fee, fee, category, city, email, fcm_token, web_token) => {
      document.body.style.overflow = 'hidden';

      setDoctor_id(doctor_id)
      setDoctor_name(doctor_name)
      setInsurance_fee(insurance_fee)
      setFee(fee)
      setCategory(category)
      setCity(city)
      setEmail(email)
      setFcm_token(fcm_token)
      setWeb_fcm(web_token)

      setShow(true);
   };

   const handleClose = () => {
      document.body.style.overflow = 'auto';

      setShow(false);
   };

   const [day_11, setDay_11] = useState('');
   const [final_date_1, setFinal_date_1] = useState('');

   useEffect(() => {
      window.scrollTo(0, 0);

      // Doctor check
      // get_doctor()
      const allDoctors = localStorage.getItem("all_doctors_fun");
      if (allDoctors) {
         let record4 = JSON.parse(allDoctors);

         if (usertype == "normal") {
            if (type_of == 'urgent') {
               let online = "online"
               let available = "true"
               let consult_now = "On"

               record4 = record4.filter(
                  (record) =>
                     record.online === online &&
                     record.available === available &&
                     record.consult_now === consult_now
               );
            }
            else {
               record4 = record4.filter((record) => record.category === propscategory);
               console.log("sssss", record4);
            }
         }
         else {
            if (type_of == 'urgent') {
               let online = "online"
               let available = "true"
               let consult_now = "On"
               let free_hisd = "true"

               record4 = record4.filter(
                  (record) =>
                     record.online === online &&
                     record.available === available &&
                     record.consult_now === consult_now &&
                     record.free_hisd === free_hisd
               );
            }
            else {
               let free_hisd = "true"

               record4 = record4.filter((record) => record.category === propscategory && record.free_hisd === free_hisd);
               console.log("sssss", record4);
            }
         }
         setDoctor(record4)
      }
      // ////////////////////////////


      var today = new Date();
      var nextweek_T = new Date(today.getFullYear(), today.getMonth(), today.getDate());
      const date0 = nextweek_T.toString()
      const ddd = date0.split(' ')
      const day_1 = ddd[0]
      const dd_2 = ddd[1]
      const dd_3 = ddd[2]
      const dd_4 = ddd[3]
      const final_date_1 = dd_2 + ' ' + dd_3 + ', ' + dd_4
      setDay_11(day_1)
      setFinal_date_1(final_date_1)

      console.log("category1", params.category)
      console.log("type_of", type_of)
      console.log("usertype", usertype)
   }, [])


   const get_doctor = async () => {
      try {
         const auth = localStorage.getItem("customer");
         const parsed = JSON.parse(auth);
         const testapi = parsed[0].test;
         const Token = localStorage.getItem("JWT_TOKEN");

         let result;
         if (testapi == 'test') {
            result = await fetch(Connection + "test_doctor", {
               headers: {
                  'Authorization': Token
               }
            })
         }
         else {
            result = await fetch(Connection + "get_doctor", {
               headers: {
                  'Authorization': Token
               }
            })
         }
         let recodd = await result.json();
         let record4 = recodd.response;

         if (record4 === 'Token verification failed') {
            console.log('Token verification failed');
         }
         else if (record4 === 'fail') {
            console.log('Record Fail');
         }
         else {

            if (usertype == "normal") {
               if (type_of == 'urgent') {
                  let online = "online"
                  let available = "true"
                  let consult_now = "On"

                  record4 = record4.filter(
                     (record) =>
                        record.online === online &&
                        record.available === available &&
                        record.consult_now === consult_now
                  );
               }
               else {
                  record4 = record4.filter((record) => record.category === propscategory);
                  console.log("sssss", record4);
               }
            }
            else {
               if (type_of == 'urgent') {
                  let online = "online"
                  let available = "true"
                  let consult_now = "On"
                  let free_hisd = "true"

                  record4 = record4.filter(
                     (record) =>
                        record.online === online &&
                        record.available === available &&
                        record.consult_now === consult_now &&
                        record.free_hisd === free_hisd
                  );
               }
               else {
                  let free_hisd = "true"

                  record4 = record4.filter((record) => record.category === propscategory && record.free_hisd === free_hisd);
                  console.log("sssss", record4);
               }
            }
         }
         setDoctor(record4);
         setSkalton(false);
      } catch (error) {
         console.error('Error:', error);
         setSkalton(false);
      }
   };


   const handleDoctorClick = (id) => {
      const url = `/Doctor_Details/${id}?value1=${type_of}&value2=${usertype}`;
      navigate(url);
   }


   return (
      <div className='dashboard_Home'>

         {skalton == true ?
            <SkeletonTheme baseColor="lightgray" highlightColor="#bebebe">
               <div className='skelton_doctorS_div'>
                  <Skeleton count={2} height={300} style={{ marginTop: 20 }} />
               </div>
            </SkeletonTheme>
            :
            <>
               {doctor != '' ?
                  <>
                     {doctor.map((row) => {

                        return (
                           <div className='doctor_card_ho'>
                              <div className='card_inside_head'>
                                 <div className='anothercardinside'>
                                    {row.profile == null ?
                                       <div className='prof_imag'>
                                          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIEFLVeftvEbJfqWQ2hnRMSPuF_b8VMn4PdPhXOJdkPm-bfdpWTZZYAUJyHVXS4wrsDAE&usqp=CAU" alt="" className="profille_img" />
                                       </div>
                                       :
                                       <div className='prof_imag'>
                                          <img src={doc_profile + row.profile} alt="" title="Profile Image" className="profille_img" />
                                          {row.online == 'online' ?
                                             <div className='dotforonline'>
                                                <div style={{ width: 10, height: 10, borderRadius: 100, backgroundColor: 'lightgreen' }} className='singledot'></div>
                                             </div>
                                             :
                                             <div className='dotforonline'>
                                                <div style={{ width: 10, height: 10, borderRadius: 100, backgroundColor: 'lightgray' }} className='singledot'></div>
                                             </div>
                                          }
                                       </div>
                                    }

                                    <div className='name_etc_detail'>
                                       <h3>
                                          Dr. {row.name}
                                          {row.online == 'online' ?
                                             <h6>(Online)</h6>
                                             :
                                             <></>
                                          }
                                       </h3>
                                       <h4 className='justdotdot'>{row.category}</h4>
                                       <h4>{row.degree}, ({row.address})</h4>
                                       <h4 style={{ marginTop: 10 }}>{row.c_name}</h4>

                                       <div className='center_more_detail'>
                                          <div className='mordetailone'>
                                             <h4>Reviews</h4>
                                             <h3>3,984+</h3>
                                          </div>

                                          <div className='center_line'></div>

                                          <div>
                                             <h4>Experience</h4>
                                             <h3>{row.experience} years</h3>
                                          </div>

                                          <div className='center_line'></div>

                                          <div>
                                             <h4>Satisfaction</h4>
                                             <h3>97%</h3>
                                          </div>
                                       </div>
                                    </div>
                                 </div>

                                 <div className='apointment_two_btn'>
                                    <button onClick={() => handleShow(row.id, row.name, row.insurance_fee, row.fee, row.category, row.city, row.email, row.fcm_token, row.web_fcm)} className="submit" id='submite'>
                                       {type_of == 'online' &&
                                          <p>Online Appointment</p>
                                       }

                                       {type_of == 'offline' &&
                                          <p>Clinic Appointment</p>
                                       }

                                       {type_of == 'urgent' &&
                                          <p>Urgent Care</p>
                                       }
                                    </button>
                                    <button onClick={() => handleDoctorClick(row.id)} className="submit" id='reset'>View Profile</button>
                                 </div>
                              </div>

                              <div className='card_inside_botom'>
                                 <div className='firs_div_inbotm'>
                                    <PhotoCamera className="sidebarIcon" />
                                    <h3>Video Consultation</h3>
                                 </div>

                                 <div className='secon_div_inbotm'>
                                    <AccessTime className="sidebarIcon" />
                                    <h3>11:00 PM - 11:59 PM</h3>
                                 </div>

                                 <div className='third_div_inbotm'>
                                    <h4>Available Today</h4>
                                    <h5>Rs {row.fee}</h5>
                                 </div>
                              </div>
                           </div>
                        )
                     })}
                  </>
                  :
                  <div style={{ width: '100%', justifyContent: 'center', alignItems: 'center', height: '80vh', display: 'flex' }}>
                     <p>No Record Found</p>
                  </div>
               }
            </>
         }


         {show &&
            <Modal
               show={true}
               handleClose={handleClose}
               day_11={day_11}
               final_date_1={final_date_1}
               doctor_id={doctor_id}
               doctor_name={doctor_name}
               insurance_fee={insurance_fee}
               fee={fee}
               category={category}
               city={city}
               email={email}
               fcm_token={fcm_token}
               web_fcm={web_fcm}

               type_of={type_of}
               usertype={usertype}
            >
               <p>Modal Content Goes Here</p>
            </Modal>
         }

      </div>
   );
}
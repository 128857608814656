import React, { useState, useEffect } from 'react'
import './Patient_All_Appointment.css';
import Connection from "../../connection";
import { doc_profile, user_profile } from '../../Image_connection';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Link, useNavigate } from 'react-router-dom';
import 'react-loading-skeleton/dist/skeleton.css'
import Modal_detail from '../../MyComponent/Modal_detail/Modal_detail';
import Modal_cancel_appoint from '../../MyComponent/Modal_cancel_appoint/Modal_cancel_appoint';
import Modal_reschedual_appoint from '../../MyComponent/Modal_reschedual_appoint/Modal_reschedual_appoint';
import Modal_ready_review from '../../MyComponent/Modal_ready_review/Modal_ready_review';

import Drawer from 'react-drawer';
import 'react-drawer/lib/react-drawer.css';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import HistoryIcon from '@mui/icons-material/History';
import VideocamIcon from '@mui/icons-material/Videocam';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';

export default function Patient_All_Appointment() {
  const navigate = useNavigate();

  const [skalton, setSkalton] = React.useState(true);

  const [main_array, setMain_array] = useState([]);
  const [upcom_arr, setUpcom_arr] = useState([]);
  const [past_arr, setPast_arr] = useState([]);
  const [urgent_upcom_arr, setUrgent_upcom_arr] = useState([]);
  const [urgent_past_arr, setUrgent_past_arr] = useState([]);

  const [show, setShow] = useState(false);
  const [cancelapoinSho, setCancelapoinSho] = useState(false);
  const [reschedualapoinSho, setReschedualapoinSho] = useState(false);
  const [readyreview, setReadyreview] = useState(false);

  const [user_id, setUser_id] = React.useState('');
  const [my_name, setMy_name] = React.useState('');
  const [role, setRole] = React.useState('');

  const [get_name, setGet_name] = React.useState('');
  const [get_address, setGet_address] = React.useState('');
  const [get_email, setGet_email] = React.useState('');
  const [get_mobile_number, setGet_mobile_number] = React.useState('');
  const [get_id, setGet_id] = React.useState('');
  const [get_fcm_token, setGet_fcm_token] = React.useState('');
  const [get_web_fcm, setGet_web_fcm] = React.useState('');
  const [get_voip_token, setGet_voip_token] = React.useState('');
  const [get_profile, setGet_profile] = React.useState('');
  const [profile_url, setProfile_url] = React.useState('');
  const [get_online_status, setGet_online_status] = React.useState('');
  const [get_gender, setGet_gender] = React.useState('');
  const [get_experience, setGet_experience] = React.useState('');
  const [appointment_review, setAppointment_review] = React.useState('');

  const [payment_method, setPayment_method] = React.useState('');
  const [date, setDate] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [day, setDay] = React.useState('');
  const [time, setTime] = React.useState('');
  const [doctor_name, setDoctor_name] = React.useState('');
  const [category, setCategory] = React.useState('');
  const [doctor_address, setDoctor_address] = React.useState('');
  const [doctor_profile, setDoctor_profile] = React.useState('');
  const [fee, setFee] = React.useState('');
  const [user_name, setUser_name] = React.useState('');
  const [doctor_experience, setDoctor_experience] = React.useState('');
  const [appointment_id, setAppointment_id] = React.useState('');
  const [app_type, setApp_type] = React.useState('');
  const [user_type, setUser_type] = React.useState('');
  const [doctor_id, setDoctor_id] = React.useState('');
  const [doctor_email, setDoctor_email] = React.useState('');
  const [fcm_token, setFcm_token] = React.useState('');
  const [web_fcm, setWeb_fcm] = React.useState('');


  // RBSheet
  const [cancelOpen, setCancelOpen] = React.useState(false);
  const cancelmodalOpen = (appointment_id, fee, time, date, get_name, get_email, get_fcm_token, get_web_fcm, get_id) => {
    setAppointment_id(appointment_id)
    setFee(fee)
    setTime(time)
    setDate(date)
    setDoctor_name(get_name)
    setDoctor_email(get_email)
    setFcm_token(get_fcm_token)
    setWeb_fcm(get_web_fcm)
    setDoctor_id(get_id)

    setCancelOpen(true);
  };
  const cancelmodalClose = () => {
    setCancelOpen(false);
  };
  const cancelmodalnd_next = () => {
    document.body.style.overflow = 'hidden';
    setCancelOpen(false);
    setCancelapoinSho(true);
  };
  ////////////////////



  // Change Btn
  const [text4, setText4] = React.useState(2);
  const [text5, setText5] = React.useState(1);
  const [check_first_design, setCheck_first_design] = React.useState('Upcoming');
  const changebt1 = (text, val) => {
    setText4(1);
    setText5(1);

    if (text === "text4") {
      setText4(2);
    }
    else if (text === "text5") {
      setText5(2);
    }

    setCheck_first_design(val)

    if (val == "Upcoming" && check_design == "Online Appointments") {
      setMain_array(upcom_arr)
    }
    else if (val == "Upcoming" && check_design == "Urgent Care") {
      setMain_array(urgent_upcom_arr)
    }
    else if (val == "Past" && check_design == "Online Appointments") {
      setMain_array(past_arr)
    }
    else if (val == "Past" && check_design == "Urgent Care") {
      setMain_array(urgent_past_arr)
    }
  }
  /////////////////////////



  // Change Btn
  const [text1, setText1] = React.useState(2);
  const [text2, setText2] = React.useState(1);
  const [check_design, setCheck_design] = React.useState('Online Appointments');

  const changebtn2 = (text, val) => {
    setText1(1);
    setText2(1);

    if (text === "text1") {
      setText1(2);
    }
    else if (text === "text2") {
      setText2(2);
    }

    setCheck_design(val)


    if (val == "Online Appointments" && check_first_design == "Upcoming") {
      setMain_array(upcom_arr)
    }
    else if (val == "Urgent Care" && check_first_design == "Upcoming") {
      setMain_array(urgent_upcom_arr)
    }
    else if (val == "Online Appointments" && check_first_design == "Past") {
      setMain_array(past_arr)
    }
    else if (val == "Urgent Care" && check_first_design == "Past") {
      setMain_array(urgent_past_arr)
    }

  }
  /////////////////////////



  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = 'auto';

    const auth = localStorage.getItem("customer");
    const parsed = JSON.parse(auth);
    const id = parsed[0].id;
    const name = parsed[0].name;
    const role = parsed[0].role;

    setUser_id(id)
    setMy_name(name)
    setRole(role)
    getProducts(id, role)
  }, [])

  const getProducts = async (id, role) => {
    try {
      let result = await fetch(Connection + "display_appointments_user_and_doctor", {
        method: 'post',
        body: JSON.stringify({ id, role }),
        headers: {
          'Authorization': localStorage.getItem("JWT_TOKEN"),
          "Content-Type": 'application/json'
        }
      })

      result = await result.json()
      const record4 = result.response;
      console.log('Record =>', record4);

      if (record4 === 'Token verification failed') {
        console.log('Token verification failed');
      }
      else if (record4 === 'fail') {
        console.log('Record Fail');
      }
      else {
        setMain_array(record4.filter(record4 => record4.status === 'pending' && record4.type === 'online'));

        setUpcom_arr(record4.filter(record4 => record4.status === 'pending' && record4.type === 'online'));
        setPast_arr(record4.filter(record4 => (record4.status === 'cancel' || record4.status === 'complete') && record4.type === 'online'));
        setUrgent_upcom_arr(record4.filter(record4 => record4.status === 'pending' && record4.type === 'urgent'));
        setUrgent_past_arr(record4.filter(record4 => (record4.status === 'cancel' || record4.status === 'complete') && record4.type === 'urgent'))
      }
      setSkalton(false);
    } catch (error) {
      console.error('Error:', error);
      setSkalton(false);
    }
  }


  const handleShow = (get_name, get_address, get_email, get_mobile_number, get_id, get_fcm_token, get_web_fcm, get_voip_token, get_profile, profile_url, get_online_status, get_gender, get_experience, payment_method, date, day, time, appointment_id, fee, status, category, type, user_type, appointment_review) => {
    document.body.style.overflow = 'hidden';
    // // user table
    setGet_name(get_name)
    setGet_address(get_address)
    setGet_email(get_email)
    setGet_mobile_number(get_mobile_number)
    setGet_id(get_id)
    setGet_fcm_token(get_fcm_token)
    setGet_web_fcm(get_web_fcm)
    setGet_voip_token(get_voip_token)
    setGet_profile(get_profile)
    setProfile_url(profile_url)
    setGet_online_status(get_online_status)
    setGet_gender(get_gender)
    setGet_experience(get_experience)

    setPayment_method(payment_method)
    setDate(date)
    setDay(day)
    setTime(time)
    setAppointment_id(appointment_id)
    setFee(fee)
    setStatus(status)
    setCategory(category)
    setApp_type(type)
    setUser_type(user_type)

    setAppointment_review(appointment_review)

    setShow(true);
  };

  const handleClose = () => {
    document.body.style.overflow = 'auto';
    setShow(false);
  };

  const handlecancelappoint = () => {
    document.body.style.overflow = 'auto';
    setCancelapoinSho(false);
  };


  const openreschedul = (date, get_id, profile_url, get_name, get_email, get_fcm_token, get_web_fcm, appointment_id, category, time) => {
    document.body.style.overflow = 'hidden';

    setDate(date)
    setDoctor_id(get_id)
    setDoctor_name(get_name)
    setDoctor_email(get_email)
    setFcm_token(get_fcm_token)
    setWeb_fcm(get_web_fcm)
    setAppointment_id(appointment_id)
    setCategory(category)
    setTime(time)
    setApp_type('online')

    setReschedualapoinSho(true);
  };
  const handlerescheappoint = () => {
    document.body.style.overflow = 'auto';
    setReschedualapoinSho(false);
  };


  const openreadyreviw = (appointment_id, category, get_id, get_address, profile_url, get_name, get_fcm_token, get_web_fcm) => {
    document.body.style.overflow = 'hidden';

    setAppointment_id(appointment_id)
    setCategory(category)
    setDoctor_id(get_id)
    setDoctor_address(get_address)
    setDoctor_profile(profile_url)
    setDoctor_name(get_name)
    setFcm_token(get_fcm_token)
    setWeb_fcm(get_web_fcm)

    setReadyreview(true);
  };
  const handlereadyreviw = () => {
    document.body.style.overflow = 'auto';
    setReadyreview(false);
  };


  const alertfreenotcancel = () => {
    alert("You can't cancel HISD Select appointment.")
  }


  const agora_notification = async (get_fcm_token, get_web_fcm, get_voip_token) => {
    console.log("fcm_token", get_fcm_token)
    console.log("get_web_fcm", get_web_fcm)
    console.log("role", role)

    if ((get_fcm_token === null || get_fcm_token === '') && (get_web_fcm === null || get_web_fcm === '')) {
      alert("This user is currently unavailable.");
    }
    else {
      const call_check = 'do_call'
      const url = `/AgoraCall?call_check=${call_check}&fcm_token=${get_fcm_token}&web_fcm=${get_web_fcm}&deviceToken=${get_voip_token}&role=${role}`;
      navigate(url);
    }
  };


  const chat_romm = async (profile_url, get_profile, get_name, get_id, get_fcm_token, get_web_fcm, get_voip_token, get_online_status) => {
    const check_chat = 'do_chat'
    const url = `/Chat?check_chat=${check_chat}&receiver_image=${profile_url}&chat_image=${get_profile}&chat_name=${get_name}&receiver_id=${get_id}&fcm_token=${get_fcm_token}&web_fcm=${get_web_fcm}&active=${get_online_status}&sender_name=${my_name}&receiver_name=${get_name}&chat_name_disable=${get_name}&deviceToken=${get_voip_token}`;
    navigate(url);
  }

  return (

    <div className='appointment_startup'>


      <div className='viewofchange_btn'>
        <div className='insideforbaccolr'>
          <div className={text1 === 1 ? "in_active_button" : "active_button"} onClick={() => changebtn2("text1", 'Online Appointments')}>
            <h4>Online Appointments</h4>
          </div>

          <div className={text2 === 1 ? "in_active_button" : "active_button"} onClick={() => changebtn2("text2", 'Urgent Care')}>
            <h4>Urgent Care</h4>
          </div>
        </div>
      </div>

      <div className="buttonContainer">
        <button className={text4 === 1 ? 'inactiveButton' : 'activeButton'} onClick={() => changebt1('text4', 'Upcoming')}>
          Upcoming
        </button>

        <button className={text5 === 1 ? 'inactiveButton' : 'activeButton'} onClick={() => changebt1('text5', 'Past')}>
          History
        </button>
      </div>


      {skalton == true ?
        <SkeletonTheme baseColor="lightgray" highlightColor="#bebebe">
          <div className='skelton_appoint_div'>
            <Skeleton count={2} className='sklnappointheight' />
          </div>
        </SkeletonTheme>
        :
        <>
          {main_array != '' ?
            <>
              {main_array.map((row) => {
                let date = row.date
                let time = row.time
                let day = row.day
                let type = row.type
                let timeAgo;

                const givenDate = new Date(`${date} ${time}`);
                const currenttDate = new Date();
                const differenceMs = currenttDate - givenDate;

                const minute = 60 * 1000;
                const hour = minute * 60;
                const dayy = hour * 24;
                const month = dayy * 30;
                const year = dayy * 365;

                const years = Math.floor(differenceMs / year);
                const months = Math.floor(differenceMs / month);
                const days = Math.floor(differenceMs / dayy);
                const hours = Math.floor(differenceMs / hour);
                const minutes = Math.floor(differenceMs / minute);

                if (years >= 1) {
                  timeAgo = `${years} year${years > 1 ? 's' : ''} ago`;
                } else if (months >= 1) {
                  timeAgo = `${months} month${months > 1 ? 's' : ''} ago`;
                } else if (days >= 1) {
                  timeAgo = `${days} day${days > 1 ? 's' : ''} ago`;
                } else if (hours >= 1) {
                  timeAgo = `${hours} hour${hours > 1 ? 's' : ''} ago`;
                } else if (minutes >= 1) {
                  timeAgo = `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
                } else {
                  timeAgo = `${date} ${time}`;
                }


                let get_id = row.get_id
                let get_name = row.get_name
                let get_email = row.get_email
                let get_address = row.get_address
                let get_fcm_token = row.get_fcm_token
                let get_web_fcm = row.get_web_fcm
                let get_voip_token = row.get_voip_token
                let get_online_status = row.get_online_status
                let get_mobile_number = row.get_mobile_number
                let get_gender = row.get_gender
                let get_experience = row.get_experience

                let payment_method = row.payment_method
                let appointment_id = row.id
                let fee = row.fee
                let status = row.status
                let category = row.category
                let user_type = row.user_type

                let get_profile = row.get_profile
                let profile_url;
                if (role == 'user') {
                  profile_url = doc_profile + get_profile
                }
                else {
                  profile_url = user_profile + get_profile
                }

                let appointment_review = row.appointment_review
                console.log("appointment_review", appointment_review)

                let doctor_name = row.doctor_name
                let user_name = row.user_name


                const fulldateString = `${date} ${time}`;
                const fulldate = new Date(fulldateString);
                const currentDate = new Date();

                let datestatus;
                if (fulldate > currentDate) {
                  datestatus = 'future'
                }
                else if (fulldate < currentDate) {
                  datestatus = 'overdue'
                }
                else {
                  datestatus = 'same'
                }


                return (
                  <div className='one_appointment_card' key={appointment_id} >
                    <div className='imag_nd_nameetc'>
                      <div className='pahli_image'>
                        {get_profile !== null ?
                          <img className="ima_apoint_doc" src={profile_url} alt="doctor-image" />
                          :
                          <img className="ima_apoint_doc" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIEFLVeftvEbJfqWQ2hnRMSPuF_b8VMn4PdPhXOJdkPm-bfdpWTZZYAUJyHVXS4wrsDAE&usqp=CAU" alt="doctor-image" />
                        }
                      </div>

                      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 8, marginTop: 4 }}>
                        {get_name ? (
                          <p style={{ color: 'black', fontWeight: 'bold', fontSize: 15 }}>{get_name}</p>
                        ) : (
                          <p style={{ color: 'black', fontWeight: 'bold', fontSize: 15 }}>
                            {role === 'user' ? doctor_name : user_name}
                          </p>
                        )}
                        <p style={{ color: '#39b4cc', fontSize: 15 }}>{get_address}</p>
                      </div>
                    </div>

                    <div className='viws_detais'
                      onClick={() => handleShow(
                        get_name,
                        get_address,
                        get_email,
                        get_mobile_number,
                        get_id,
                        get_fcm_token,
                        get_web_fcm,
                        get_voip_token,
                        get_profile,
                        profile_url,
                        get_online_status,
                        get_gender,
                        get_experience,

                        // appointment table same
                        payment_method,
                        date,
                        day,
                        time,
                        appointment_id,
                        fee,
                        status,
                        category,
                        type,
                        user_type,

                        // appointment review
                        appointment_review,
                      )}>
                      <h4>View Details</h4>
                    </div>


                    {(datestatus === 'overdue' && status === 'pending') ? (
                      <>
                        <div className='categ_detais'>
                          <h4 id='redid'><CalendarMonthIcon /> {row.time} | {row.date}</h4>
                        </div>

                        <div className='categ_detais'>
                          <h4 id='redid'><HistoryIcon /> {timeAgo}</h4>
                        </div>
                      </>
                    ) : (
                      <div className='categ_detais'>
                        <h4 id='noredid'><CalendarMonthIcon /> {row.time} | {row.date}</h4>
                      </div>
                    )}

                    {(role === 'doctor' && type === 'urgent' && status === 'pending') && (
                      <div className='categ_detais'>
                        <h4 id='imidiatrspsdoc'><VideocamIcon id="vidoeicn" /> Immediate Response Required</h4>
                      </div>
                    )}


                    <div className='Paid_progress'>
                      {user_type == 'hisd' ?
                        <h4>FREE HISD</h4>
                        :
                        <>
                          {payment_method == 'Insurance' &&
                            <h4>Insurance</h4>
                          }
                          <p style={{ color: '#00c480', fontSize: 14, fontWeight: 'bold' }}></p>
                        </>
                      }

                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <div className='doted_sta'></div>
                        {row.status == 'pending' &&
                          <h4>In-Process</h4>
                        }
                        {row.status == 'complete' &&
                          <h4>Showed-up</h4>
                        }
                        {row.status == 'cancel' &&
                          <h4>Cancelled</h4>
                        }
                        {row.status == 'request' &&
                          <h4>Requested</h4>
                        }
                      </div>
                    </div>


                    {(status !== 'cancel' && status !== 'complete') && (
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 5 }}>
                        {role === 'doctor' && (
                          <>
                            <div className='lasttwobtndoc'>
                              <div className='fistcallbutn'>
                                <button onClick={() => agora_notification(get_fcm_token, get_web_fcm, get_voip_token)} className='maincallbutn'>
                                  <VideocamIcon className='textsizcalicn' />
                                </button>
                              </div>

                              <button onClick={() => chat_romm(profile_url, get_profile, get_name, get_id, get_fcm_token, get_web_fcm, get_voip_token, get_online_status)}
                                className='secndchtbutn'>
                                <QuestionAnswerIcon className='iconsizcht' />
                                <span className='sizofchattxt'>Chat with Patient</span>
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    )}


                    {role == 'user' &&
                      <>
                        {status == 'pending' && type == 'online' &&
                          <>
                            <hr className="line"></hr>
                            <div className='last_cancel_nd_reschedule'>
                              {row.user_type == 'hisd' ?
                                <div onClick={() => alertfreenotcancel()} className='last_cancel'>
                                  <h3>Cancel Appointment</h3>
                                </div>
                                :
                                <div onClick={() => cancelmodalOpen(appointment_id, fee, time, date, get_name, get_email, get_fcm_token, get_web_fcm, get_id)} className='last_cancel'>
                                  <h3>Cancel Appointment</h3>
                                </div>
                              }

                              <div onClick={() => { openreschedul(date, get_id, profile_url, get_name, get_email, get_fcm_token, get_web_fcm, appointment_id, category, time) }} className='last_reschedule'>
                                <h3>Reschedule</h3>
                              </div>
                            </div>
                          </>
                        }

                        {status == 'complete' &&
                          <>
                            <hr className="line"></hr>
                            <div className='last_cancel_nd_reschedule'>
                              {appointment_review == appointment_id ?
                                <div className='last_submitreview'>
                                  <h3>Submitted Review</h3>
                                </div>
                                :
                                <div onClick={() => openreadyreviw(appointment_id, category, get_id, get_address, profile_url, get_name, get_fcm_token, get_web_fcm)} className='last_leavereview'>
                                  <h3>Leave Review</h3>
                                </div>
                              }
                            </div>
                          </>
                        }
                        {payment_method == 'Insurance' && <p style={{ color: '#00c480', fontSize: 14, fontWeight: 'bold', paddingLeft: 5 }}>Insurance Appointment</p>}
                      </>
                    }
                  </div>
                )
              })}
            </>
            :
            <div style={{ width: '100%', height: '60vh', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
              <p>No Record Found</p>
            </div>
          }
        </>
      }




      {show == true &&
        <Modal_detail
          show={show}
          handleClose={handleClose}
          getProducts={getProducts}

          get_name={get_name}
          get_address={get_address}
          get_email={get_email}
          get_mobile_number={get_mobile_number}
          get_id={get_id}
          get_fcm_token={get_fcm_token}
          get_web_fcm={get_web_fcm}
          get_voip_token={get_voip_token}
          get_profile={get_profile}
          profile_url={profile_url}
          get_online_status={get_online_status}
          get_gender={get_gender}
          get_experience={get_experience}

          // appointment table same
          payment_method={payment_method}
          date={date}
          day={day}
          time={time}
          appointment_id={appointment_id}
          fee={fee}
          status={status}
          category={category}
          appointment_type={app_type}
          user_type={user_type}

          // appointment review
          appointment_review={appointment_review}
        >
          <p>Modal Content Goes Here</p>
        </Modal_detail>
      }


      <div className={`bottom-sheet-overlay ${cancelOpen ? 'open' : ''}`}>
        <div className="cancel_sheet_content">
          <h2>Cancel Appointment</h2>
          <hr className='line'></hr>
          <p>Are you sure you want to cancel your appointment?</p>
          <p style={{ marginTop: 10 }}>Only 80% of the funds will return to your accouont?</p>
          <hr className='line'></hr>

          <div className='twobtn_cancel'>
            <button className='close' onClick={cancelmodalClose}>Close</button>
            <button className='yescancel' onClick={cancelmodalnd_next}>Yes, Cancel</button>
          </div>
        </div>
      </div>

      {cancelapoinSho == true &&
        <Modal_cancel_appoint handlecancelappoint={handlecancelappoint} get_apointments={getProducts} appointment_id={appointment_id} doctor_id={doctor_id} user_id={user_id} doctor_nam={doctor_name} doctor_email={doctor_email} fee={fee} time={time} date={date} fcm_token={fcm_token} web_fcm={web_fcm}>
          <p>Modal Content Goes Here</p>
        </Modal_cancel_appoint>
      }

      {reschedualapoinSho == true &&
        <Modal_reschedual_appoint handlerescheappoint={handlerescheappoint} get_apointments={getProducts} appointment_id={appointment_id} doctor_id={doctor_id} user_id={user_id} doctor_nam={doctor_name} doctor_email={doctor_email} category={category} appointme_date={date} time={time} fcm_token={fcm_token} web_fcm={web_fcm} type_of={app_type}>
          <p>Modal Content Goes Here</p>
        </Modal_reschedual_appoint>
      }

      {readyreview == true &&
        <Modal_ready_review handlereadyreviw={handlereadyreviw} get_apointments={getProducts} doctor_id={doctor_id} appointment_id={appointment_id} doctor_address={doctor_address} doctor_profile={doctor_profile} doctor_nam={doctor_name} category={category} fcm_token={fcm_token} web_fcm={web_fcm}>
          <p>Modal Content Goes Here</p>
        </Modal_ready_review>
      }

    </div>
  );
}
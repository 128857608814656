import React, { useState, useEffect, useContext } from 'react'
import './Home.css';
import { Link, useNavigate } from 'react-router-dom';
import Connection from "../../connection";
import { doc_profile } from '../../Image_connection';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { GlobalInfo } from "../../App"
import { firebaseApp, messaging } from '../../firebase';
import { getToken } from 'firebase/messaging';
import axios from 'axios';
import Modal_detail from '../../MyComponent/Modal_detail/Modal_detail';
import Modal_Pay_Option from '../../MyComponent/Modal_Pay_Option/Modal_Pay_Option';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import StarIcon from '@mui/icons-material/Star';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import SearchIcon from '@mui/icons-material/Search';
import CheckIcon from '@mui/icons-material/Check';
import Modal_categ from '../../MyComponent/Modal_categ/Modal_categ';
import Type_Selection from '../../MyComponent/Type_Selection/Type_Selection';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import HistoryIcon from '@mui/icons-material/History';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';

import Physician1 from '../../assets/Physician1.png';
import oral from '../../assets/oral.png';
import heart from '../../assets/heart.png';
import Pediatric1 from '../../assets/Pediatric1.png';
import Dermotoglist1 from '../../assets/Dermotoglist1.png';
import ortho from '../../assets/ortho.png';
import brain from '../../assets/brain.png';
import Fitness_Trainer from '../../assets/Fitness_Trainer.png';
import Mental_Health from '../../assets/Mental_Health.png';
import terapist from '../../assets/terapist.png';

import online_cons from '../../assets/online_cons.png';
import Online_forrr from '../../assets/Online_forrr.png';
import local_image_doc1 from '../../assets/local_image_doc1.png';
import free_hisd from '../../assets/free_hisd.png';
import HoustonISD from '../../assets/HoustonISD.png';
import Frame_3 from '../../assets/Frame_3.png';
import Frame_2 from '../../assets/Frame_2.png';
import mass_1 from '../../assets/mass_1.png';
import playstore_icon from '../../assets/playstore_icon.png';
import Appstore_icon from '../../assets/Appstore_icon.png';
import mobile_screen_shot from '../../assets/mobile_screen_shot.png';

const Home = (props) => {
  const { do_free_hisd, do_status, tokenExpire } = useContext(GlobalInfo)
  const navigate = useNavigate();

  const classes = useStyles();

  const [online_app, setOnline_app] = useState([]);
  const [pending_pay_app, setPending_pay_app] = useState([]);
  const [urgent_app, setUrgent_app] = useState([]);

  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [skalto1, setSkalto1] = useState(true);

  const [apointdetail, setApointdetail] = useState(false);
  const [paymentOnn, setPaymentOnn] = useState(false);


  const [get_name, setGet_name] = React.useState('');
  const [get_address, setGet_address] = React.useState('');
  const [get_email, setGet_email] = React.useState('');
  const [get_mobile_number, setGet_mobile_number] = React.useState('');
  const [get_id, setGet_id] = React.useState('');
  const [get_fcm_token, setGet_fcm_token] = React.useState('');
  const [get_web_fcm, setGet_web_fcm] = React.useState('');
  const [get_profile, setGet_profile] = React.useState('');
  const [profile_url, setProfile_url] = React.useState('');
  const [get_online_status, setGet_online_status] = React.useState('');
  const [get_gender, setGet_gender] = React.useState('');
  const [get_experience, setGet_experience] = React.useState('');
  const [appointment_review, setAppointment_review] = React.useState('');

  const [payment_method, setPayment_method] = React.useState('');
  const [date, setDate] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [day, setDay] = React.useState('');
  const [time, setTime] = React.useState('');
  const [category, setCategory] = React.useState('');
  const [fee, setFee] = React.useState('');
  const [insurance_fee, setInsurance_fee] = React.useState('');
  const [appointment_id, setAppointment_id] = React.useState('');
  const [app_type, setApp_type] = React.useState('');
  const [user_type, setUser_type] = React.useState('');

  const [alldoctor, setAlldoctor] = useState([]);
  const [allsponsor, setAllsponsor] = useState([]);
  const [skalton, setSkalton] = React.useState(true);

  const [user_nme, setUser_nme] = React.useState('');
  const [show, setShow] = useState(false);

  const [typemodal, setTypemodal] = useState(false);
  const [type_of, setType_of] = useState('');
  const [usertype, setUsertype] = useState('');

  const [doctorId, setDoctorId] = useState('');
  const [doctorcategory, setDoctorcategory] = useState('');

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener('online', handleOnlineStatusChange);
    window.addEventListener('offline', handleOnlineStatusChange);

    return () => {
      window.removeEventListener('online', handleOnlineStatusChange);
      window.removeEventListener('offline', handleOnlineStatusChange);
    };
  }, []);

  useEffect(() => {
    // if (tokenExpire == true) {
    //   alert("Token has expired. Please refresh your app.")
    // }

    const jwt_tokens = localStorage.getItem("JWT_TOKEN");
    Notification.requestPermission().then(permission => {
      if (permission === 'granted') {
        getToken(messaging, { vapidKey: "BHwo7XkJcDLDE1g1t-ZLtjVXqlmSn12QX2qihLtgIM1COE1GqOi4US04hjv25md38FC0dSy-2C_bFFbov-cV8D4" }).then(currentToken => {

          const auth = localStorage.getItem("customer");
          const parsed = JSON.parse(auth);
          const id = parsed[0].id;
          Update_fcmtoken(id, currentToken, jwt_tokens)
        });
      }
    });

    // User ID From LocalStorage
    const auth = localStorage.getItem("customer");
    const parsed = JSON.parse(auth);
    const id = parsed[0].id;
    const city = parsed[0].city;
    const role = parsed[0].role;
    const user_name = parsed[0].name;
    const testapi = parsed[0].test;
    setUser_nme(user_name)
    /////////////

    doctors_all_fav(testapi, jwt_tokens, city)
    display_appointments_patient_all(id, role, jwt_tokens)
  }, [])


  // For Scroll bottom to the top
  const [showScrollButton, setShowScrollButton] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const shouldShowButton = scrollTop > 200;
      setShowScrollButton(shouldShowButton);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  /////////////////////////////


  const Update_fcmtoken = async (id, fcm_token, Token) => {
    try {
      let result = await fetch(Connection + "update_both_token", {
        method: 'post',
        body: JSON.stringify({ id, fcm_token }),
        headers: {
          'Authorization': Token,
          "Content-Type": 'application/json'
        }
      })

      result = await result.json()
      let record = result.response

      if (record === 'Token verification failed') {
        console.log(record);
      }
      else if (record === 'fail') {
        console.log(record);
      }
      else {
        console.log(record);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };


  const doctors_all_fav = async (testapi, Token, city) => {
    try {
      let result;
      if (testapi == 'test') {
        result = await fetch(Connection + "test_doctors_o", {
          headers: {
            'Authorization': Token
          }
        })
      }
      else {
        result = await fetch(Connection + "all_doctors_o", {
          headers: {
            'Authorization': Token
          }
        })
      }

      result = await result.json()
      const record4 = result.response
      console.log("New Doctors =>", record4)


      if (record4 === 'Token verification failed') {
        console.log('Token verification failed');
        // alert("Token has expired. Please refresh your app.")
      }
      else if (record4 === 'fail') {
        console.log('Record Fail');
      }
      else {
        let table = [];
        let len = record4.length;

        for (let i = 0; i < len; i++) {
          let lat1 = record4[i].lat;
          if (lat1) {
            table.push(record4[i]);
          }
        }

        // Set redux state with all doctors
        const allDoctors = table.filter(record4 => record4.city === city);
        localStorage.setItem("all_doctors_fun", JSON.stringify(allDoctors))

        if (!allDoctors || allDoctors.length === 0) {
          setAlldoctor([])
        }
        else {
          setAlldoctor(allDoctors)
        }



        // Filter and store favorite clinics
        const objectClinicas = table.filter(record4 => record4.favourite === "favourite" && record4.city === city);
        localStorage.setItem("all_sponsor_doctor", JSON.stringify(objectClinicas))


        if (!objectClinicas || objectClinicas.length === 0) {
          setAllsponsor([])
        }
        else {
          const shortArray = objectClinicas.slice(0, 10);
          setAllsponsor(shortArray)
        }

      }

      setSkalton(false);
    } catch (error) {
      console.error('Error:', error);
      setSkalton(false);
    }
  }


  const goondoctorall = () => {
    const searchbar = 'true'
    const url = `/Doctor_list?searchbar=${searchbar}`;
    navigate(url)
    // navigate("/Doctor_list")
  }

  const goonlinkapp = (link) => {
    window.open(link, '_blank');
  }

  const goonmyapppointment = () => {
    navigate("/Patient_All_Appointment")
  }


  const handleShow = (value) => {
    const type_of = value['type_of']
    const usertype = value['usertype']

    setType_of(type_of)
    setUsertype(usertype)

    if (type_of == 'urgent') {
      const catgry = 'urgent'
      const url = `/Doctor_by_category/${catgry}?value1=${type_of}&value2=${usertype}`;
      navigate(url);
    }
    else {
      document.body.style.overflow = 'hidden';
      setShow(true);
    }
  };


  const handleClose = () => {
    document.body.style.overflow = 'auto';
    setShow(false);
  };


  const display_appointments_patient_all = async (id, role, Token) => {
    try {
      let result = await fetch(Connection + "display_appointments_user_and_doctor", {
        method: 'post',
        body: JSON.stringify({ id, role }),
        headers: {
          'Authorization': Token,
          "Content-Type": 'application/json'
        }
      })

      const response = await result.json();
      const record4 = response.response

      if (record4 === 'Token verification failed') {
        console.log('Token verification failed');
      }
      else if (record4 === 'fail') {
        console.log('Record Fail');
      }
      else {
        setOnline_app(record4.filter(record4 => (record4.status === 'request' || record4.status === 'pending') && record4.type === 'online'));
        setPending_pay_app(record4.filter(record4 => record4.status === 'wait_for_pay'));
        setUrgent_app(record4.filter(record4 => (record4.status === 'request' || record4.status === 'pending') && record4.type === 'urgent'));
      }

      setSkalto1(false);
    }
    catch (error) {
      console.error('Error:', error);
      setSkalto1(false);
    }
  }



  const apointdeatsho = (get_name, get_address, get_email, get_mobile_number, get_id, get_fcm_token, get_web_fcm, get_profile, profile_url, get_online_status, get_gender, get_experience, payment_method, date, day, time, appointment_id, fee, status, category, type, user_type, appointment_review, valuiii) => {
    document.body.style.overflow = 'hidden';

    // // user table
    setGet_name(get_name)
    setGet_address(get_address)
    setGet_email(get_email)
    setGet_mobile_number(get_mobile_number)
    setGet_id(get_id)
    setGet_fcm_token(get_fcm_token)
    setGet_web_fcm(get_web_fcm)
    setGet_profile(get_profile)
    setProfile_url(profile_url)
    setGet_online_status(get_online_status)
    setGet_gender(get_gender)
    setGet_experience(get_experience)

    setPayment_method(payment_method)
    setDate(date)
    setDay(day)
    setTime(time)
    setAppointment_id(appointment_id)
    setFee(fee)
    setStatus(status)
    setCategory(category)
    setApp_type(type)
    setUser_type(user_type)

    setAppointment_review(appointment_review)

    setInsurance_fee(fee)

    if (valuiii == 'payment') {
      setPaymentOnn(true);
    }
    else {
      setApointdetail(true);
    }
  };

  const handleapaymentclos = () => {
    console.log("working home")
    document.body.style.overflow = 'auto';
    setPaymentOnn(false);
  };

  const handleapontdetalClose = () => {
    document.body.style.overflow = 'auto';
    setApointdetail(false);
  };

  // const handleCategoryClick = async (category) => {
  //   const catrgoty = category['category']
  //   const appointments_type = 'appointment'

  //   // const url = `/Doctor_by_category/${catrgoty}?value1=${appointments_type}`;
  //   // navigate(url)
  // };

  // const handleDoctorClick = (id, value) => {
  //   console.log("id =>", id)
  //   console.log("value =>", value)

  //   setUsertype(usertype)
  //   setDoctorId(id)
  //   setTypemodal(true);
  //   // const appointments_type = 'appointment'
  //   // const url = `/Doctor_Details/${id}?value1=${appointments_type}`;
  //   // navigate(url)
  // }

  const TypeModalShow = (value) => {
    const id = value['id'];
    const category = value['category'];
    const user_type = value['usertype']

    setUsertype(user_type)
    setDoctorId(id)
    setDoctorcategory(category)

    if (user_type == 'hisd') {
      document.body.style.overflow = 'hidden';
      setTypemodal(true);
    }
    else if (category) {
      const type__of = 'online'
      const url = `/Doctor_by_category/${category}?value1=${type__of}&value2=${user_type}`;
      navigate(url);
    }
    else {
      const type__of = 'online'
      const url = `/Doctor_Details/${id}?value1=${type__of}&value2=${user_type}`;
      navigate(url);
    }
  };

  const typeModalclose = () => {
    document.body.style.overflow = 'auto';
    setTypemodal(false);
  };


  const workidalert = () => {
    alert("Your employee ID is under review.")
  }


  const render_appointment_list = (list_data, check_status) => {

    return list_data.map((appointment) => {
      let date = appointment.date;
      let time = appointment.time;
      let day = appointment.day;
      let type = appointment.type;
      let timeAgo;

      const givenDate = new Date(`${date} ${time}`);
      const currenttDate = new Date();
      const differenceMs = currenttDate - givenDate;

      const minute = 60 * 1000;
      const hour = minute * 60;
      const dayy = hour * 24;
      const month = dayy * 30;
      const year = dayy * 365;

      const years = Math.floor(differenceMs / year);
      const months = Math.floor(differenceMs / month);
      const days = Math.floor(differenceMs / dayy);
      const hours = Math.floor(differenceMs / hour);
      const minutes = Math.floor(differenceMs / minute);

      if (years >= 1) {
        timeAgo = `${years} year${years > 1 ? 's' : ''} ago`;
      } else if (months >= 1) {
        timeAgo = `${months} month${months > 1 ? 's' : ''} ago`;
      } else if (days >= 1) {
        timeAgo = `${days} day${days > 1 ? 's' : ''} ago`;
      } else if (hours >= 1) {
        timeAgo = `${hours} hour${hours > 1 ? 's' : ''} ago`;
      } else if (minutes >= 1) {
        timeAgo = `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
      } else {
        timeAgo = `${date} ${time}`;
      }

      let get_id = appointment.get_id;
      let get_name = appointment.get_name;
      let get_email = appointment.get_email;
      let get_address = appointment.get_address;
      let get_fcm_token = appointment.get_fcm_token;
      let get_web_fcm = appointment.get_web_fcm;
      let get_online_status = appointment.get_online_status;
      let get_mobile_number = appointment.get_mobile_number;
      let get_gender = appointment.get_gender;
      let get_experience = appointment.get_experience;

      let payment_method = appointment.payment_method;
      let appointment_id = appointment.id;
      let fee = appointment.fee;
      let status = appointment.status;
      let category = appointment.category;
      let user_type = appointment.user_type;

      let get_profile = appointment.get_profile;
      let profile_url = doc_profile + get_profile;

      let appointment_review = appointment.appointment_review;

      let doctor_name = appointment.doctor_name;

      const fulldateString = `${date} ${time}`;
      const fulldate = new Date(fulldateString);
      const currentDate = new Date();

      let datestatus;
      if (fulldate > currentDate) {
        datestatus = 'future';
      } else if (fulldate < currentDate) {
        datestatus = 'overdue';
      } else {
        datestatus = 'same';
      }

      return (
        <div>
          <div className='upcomngmain'>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px', padding: '0 14px' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <p style={{ color: 'black', fontSize: '16px', fontWeight: 'bold' }}>
                  {get_name ? get_name : doctor_name}
                  {user_type === "hisd" && <span style={{ color: '#00c480', fontSize: '10px', fontWeight: 'bold' }}>(FREE HISD)</span>}
                </p>
                {datestatus === 'overdue' ? (
                  <>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '3px' }}>
                      <i style={{ color: "#f26f6f", marginRight: '3px', fontSize: '16px' }}><CalendarTodayIcon fontSize="5px" /></i>
                      <p style={{ color: '#f26f6f', fontSize: '12px', fontWeight: 'bold' }}>{date} | {time}</p>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <i className="icon-back-in-time" style={{ color: "#f26f6f", fontSize: '16px', marginRight: '3px' }}><HistoryIcon fontSize='16px' /></i>
                      <p style={{ color: '#f26f6f', fontSize: '12px', fontWeight: 'bold' }}>{timeAgo}</p>
                    </div>
                  </>
                ) : (
                  <div style={{ display: 'flex', alignItems: 'center', marginTop: '3px' }}>
                    <i className="icon-calendar" style={{ color: "gray", fontSize: '16px', marginRight: '3px' }}><CalendarTodayIcon fontSize="5px" /></i>
                    <p style={{ color: 'gray', fontSize: '12px' }}>{date} | <span style={{ color: 'black', fontSize: '12px' }}>{time}</span></p>
                  </div>
                )}
              </div>


              {get_profile ?
                <img className="upcomimge" src={profile_url} alt="doctor-image" />
                :
                <img className='upcomimge' src={require('../../assets/empty.png')} alt="empty" />
              }
            </div>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '3px', padding: '0 14px' }}>
              <p style={{ color: '#874DAF', fontSize: '14px' }}>{category}</p>
              <p style={{ color: '#874DAF', fontSize: '14px', fontWeight: 'bold' }}>${fee}</p>
            </div>

            {type === 'urgent' && <p style={{ color: '#00c480', fontSize: '15px', fontWeight: 'bold', padding: '0 14px' }}>Urgent Care</p>}
            {type === 'offline' && <p style={{ color: '#00c480', fontSize: '15px', fontWeight: 'bold', padding: '0 14px' }}>Clinic Appointment</p>}
            {type === 'online' && <p style={{ color: '#00c480', fontSize: '15px', fontWeight: 'bold', padding: '0 14px' }}>Online Appointment</p>}
            {payment_method === 'Insurance' && <p style={{ color: '#00c480', fontSize: '15px', fontWeight: 'bold', padding: '0 14px' }}>Insurance</p>}

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0 14px' }}>
              {status === 'pending' ? <p style={{ color: 'black', fontSize: '14px' }}>● In-Process</p> : <p style={{ color: 'black', fontSize: '14px' }}>● requested</p>}
              {datestatus === 'overdue' && <p style={{ color: '#f26f6f', fontWeight: 'bold' }}>({datestatus})</p>}
            </div>

            {check_status == 'pending_insu_payment' ?
              <button className='see_detailbutton'
                onClick={() => {
                  if (get_id) {
                    apointdeatsho(
                      get_name, get_address, get_email, get_mobile_number, get_id, get_fcm_token, get_web_fcm, get_profile, profile_url, get_online_status, get_gender, get_experience,
                      payment_method, date, day, time, appointment_id, fee, status, category, type, user_type,
                      appointment_review,
                      'payment'
                    );
                  } else {
                    alert("Doctor not available");
                  }
                }}>
                <span style={{ fontWeight: '400', fontSize: '14px' }}>Payment</span>
                <i style={{ color: "white", fontSize: '17px', marginLeft: '5px', marginBottom: -5 }}><ArrowForwardOutlinedIcon /></i>
              </button>

              :

              <button className='see_detailbutton'
                onClick={() => {
                  if (get_id) {
                    apointdeatsho(
                      get_name, get_address, get_email, get_mobile_number, get_id, get_fcm_token, get_web_fcm, get_profile, profile_url, get_online_status, get_gender, get_experience,
                      payment_method, date, day, time, appointment_id, fee, status, category, type, user_type,
                      appointment_review,
                      'detail'
                    );
                  } else {
                    alert("Doctor not available");
                  }
                }}>
                <span style={{ fontWeight: '400', fontSize: '14px' }}>See Details</span>
                <i style={{ color: "white", fontSize: '17px', marginLeft: '5px', marginBottom: -5 }}><ArrowForwardOutlinedIcon /></i>
              </button>
            }
          </div>
        </div>
      );
    });
  };


  return (
    <div className="medical_website">
      <header className='top_section'>
        <div className='logo_nd_name'>
          <div className='circle_logo'>
            <img className="image_logo" src={mass_1} />
          </div>

          <h4>Hello, {user_nme}!</h4>
        </div>

        <h3>Find the Best Doctor Near You</h3>

        <div className='search_section'>
          <div className='both_join'>
            {/* <div className='pahli_input'>
              <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                 <LocationOnIcon style={{fontSize:'20',color:'#874daf'}} />
                 <input type="location" className="location_inpu" placeholder="Enter City" required/>
              </div>
              <KeyboardArrowDownOutlinedIcon/>
            </div> */}

            <div onClick={() => goondoctorall()} className='dusri_inpu'>
              <input type="Search" className="search_inpu" placeholder="Search by Doctor" required readOnly />
              <div className='searching_hol'>
                <SearchIcon style={{ color: '#874daf' }} />
              </div>
            </div>
          </div>
        </div>
      </header>


      {pending_pay_app.length > 0 &&
        <div className='allpendingappointm'>
          <h3>Insurance pending payment</h3>

          <div className='maindivpendingappo'>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: 10 }}>
              {render_appointment_list(pending_pay_app, 'pending_insu_payment')}
            </div>
          </div>
        </div>
      }


      {urgent_app.length > 0 &&
        <div className='allpendingappointm'>
          <h3>Urgent Care</h3>

          <div className='maindivpendingappo'>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: 10 }}>
              {render_appointment_list(urgent_app)}
            </div>
          </div>
        </div>
      }


      <div className='allpendingappointm'>
        <h3>Upcoming Appointment</h3>

        <div className='maindivpendingappo'>
          {skalto1 ? (
            <SkeletonTheme baseColor="lightgray" highlightColor="#bebebe">
              <div className='pending_appoi'>
                <Skeleton className='skeltofpendngupcm' />
                <Skeleton className='skeltofpendngupcm' />
                <Skeleton className='skeltofpendngupcm' />
                <Skeleton className='skeltofpendngupcm' />
              </div>
            </SkeletonTheme>
          ) : (
            <div style={{ width: '100%' }}>
              {online_app.length === 0 ? (
                <div className='nopenrecorddiv'>
                  <h4>No Record Found</h4>
                </div>
              ) : (
                <div style={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: 10 }}>
                  {render_appointment_list(online_app)}
                </div>
              )}
            </div>
          )}
        </div>
      </div>


      <h4 className='howhelpdesgin'>How can we help you today?</h4>

      <header className='headr_second'>
        <div onClick={() => handleShow({ type_of: 'online', usertype: 'normal' })} className='video_consultation'>
          <h4>Schedule Appointment</h4>
          <h5>Verified Doctors</h5>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '95%', height: '85%' }}>
            <img className="image_consult" src={Online_forrr} />
          </div>
        </div>

        <div onClick={() => handleShow({ type_of: 'urgent', usertype: 'normal' })} className='onlyurgentsingle'>
          <h4>Urgent Care</h4>
          <h5 style={{ display: 'flex', alignItems: 'center', marginTop: -2 }}>Doctors Online Now <p style={{ color: '#00c480', fontSize: 16, marginLeft: 2 }}>●</p></h5>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%', height: '80%', marginTop: 2 }}>
            <img className="image_consult" src={online_cons} />
          </div>
        </div>

        {/* <div className='next_two_btn'>
          <div onClick={() => handleShow({ type_of: 'offline', usertype: 'normal' })} className='book_appointm_btn'>
            <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div style={{ width: '80%', display: 'flex', flexDirection: 'column', marginTop: 10, marginLeft: 10 }}>
                <h4>In Person Clinic Appointment</h4>
                <h5>In-clinic Visit</h5>
              </div>

              <img className="image_book" src={local_image_doc1} />
            </div>
          </div>


          <div onClick={() => handleShow({ type_of: 'urgent', usertype: 'normal' })} className='free_consult_btn'>
            <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10, marginLeft: 10 }}>
                <h4>Urgent Care (No Wait)</h4>
                <h5>Doctors Online Now</h5>
              </div>

              <img className="image_free" src={online_cons} />
            </div>
          </div>
        </div> */}

        <div className='last_two_btn'>
          {do_free_hisd == 'true' ?
            <>
              {do_status == 'pending' ?
                <div onClick={() => workidalert()} className='instant_btn'>
                  <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, marginTop: 10 }}>
                      <h3>FREE HISD Select Doctors</h3>
                      <h5>Free Hisd</h5>
                      <img className="houstonisd" src={HoustonISD} />
                    </div>

                    <img className="image_instan" src={free_hisd} />
                  </div>
                </div>
                :
                <div onClick={() => TypeModalShow({ usertype: 'hisd' })} className='instant_btn'>
                  <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, marginTop: 10 }}>
                      <h3>FREE HISD Select Doctors</h3>
                      <h5>Free Hisd</h5>
                      <img className="houstonisd" src={HoustonISD} />
                    </div>

                    <img className="image_instan" src={free_hisd} />
                  </div>
                </div>
              }
            </>
            :
            <div className='instant_btn'>
              <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <h4>Free Hisd is</h4>
                <h4>not Available</h4>
              </div>
            </div>
          }

          <div onClick={() => goonmyapppointment()} className='free_instant_btn'>
            <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20, marginLeft: 20 }}>
                <h4>My Appointment</h4>
                <h5>My Requested Appointment</h5>
              </div>

              <img className="image_fre_instn" src={Frame_2} />
            </div>
          </div>
        </div>
      </header >


      {show == true &&
        <Modal_categ show={show} handleClose={handleClose} type_of={type_of} usertype={usertype}>
          <p>Modal Content Goes Here</p>
        </Modal_categ>
      }


      {
        typemodal == true &&
        <Type_Selection typemodal={typemodal} typeModalclose={typeModalclose} usertype={usertype} doctorId={doctorId} doctorcategory={doctorcategory}>
          <p>Modal Content Goes Here</p>
        </Type_Selection>
      }


      <section className="categories">
        <h4>Choose a Category</h4>
        <div className="category_container">
          <div className="category_item" onClick={() => TypeModalShow({ category: 'General Clinic', usertype: 'normal' })}>
            <div className='imageKo_circlw'>
              <img className="Icon" src={Physician1} alt={"Category"} />
            </div>
            <h3>General Clinic</h3>
          </div>

          <div className="category_item" onClick={() => TypeModalShow({ category: 'Pediatric', usertype: 'normal' })}>
            <div className='imageKo_circlw'>
              <img className="Icon" src={Pediatric1} alt={"Category"} />
            </div>
            <h3>Pediatric</h3>
          </div>

          <div className="category_item" onClick={() => TypeModalShow({ category: 'Dermatology', usertype: 'normal' })}>
            <div className='imageKo_circlw'>
              <img className="Icon" src={Dermotoglist1} alt={"Category"} />
            </div>
            <h3>Dermatology</h3>
          </div>

          <div className="category_item" onClick={() => TypeModalShow({ category: 'Mental Health', usertype: 'normal' })}>
            <div className='imageKo_circlw'>
              <img className="Icon" src={Mental_Health} alt={"Category"} />
            </div>
            <h3>Mental Health</h3>
          </div>

          <div className="category_item" onClick={() => TypeModalShow({ category: 'Therapist', usertype: 'normal' })}>
            <div className='imageKo_circlw'>
              <img className="Icon" src={terapist} alt={"Category"} />
            </div>
            <h3>Therapist</h3>
          </div>

          <div className="category_item" onClick={() => TypeModalShow({ category: 'Fitness Trainer', usertype: 'normal' })}>
            <div className='imageKo_circlw'>
              <img className="Icon" src={Fitness_Trainer} alt={"Category"} />
            </div>
            <h3>Fitness Trainer</h3>
          </div>
        </div>
      </section>



      <div className='new_doc_section'>
        <h3>All Sponsored Clinics</h3>

        <div className='all_doc_card'>

          {skalton == true ?
            <SkeletonTheme baseColor="lightgray" highlightColor="#bebebe">
              <div className='all_doc_card'>
                <Skeleton className='singleskelsame' />
                <Skeleton className='singleskelsame' />
                <Skeleton className='singleskelsame' />
              </div>
            </SkeletonTheme>
            :
            <>
              {allsponsor != '' ?
                <>
                  {allsponsor.map((row) => {
                    return (
                      <div key={row.id}>
                        <div className='one_docctor' onClick={() => TypeModalShow({ id: row.id, usertype: 'normal' })}>
                          <div className='img_nd_name'>
                            {row.profile == null ?
                              <div className='onedocimagemain'>
                                <img className="image_doctors" src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIEFLVeftvEbJfqWQ2hnRMSPuF_b8VMn4PdPhXOJdkPm-bfdpWTZZYAUJyHVXS4wrsDAE&usqp=CAU"} alt={"Profile"} />
                              </div>
                              :
                              <div className='onedocimagemain'>
                                <img className="image_doctors" src={doc_profile + row.profile} />
                              </div>
                            }

                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 15 }}>
                              <h4>Dr. {row.name}</h4>
                              <h5>{row.category}</h5>
                              <h6>{row.experience} years experience</h6>
                            </div>
                          </div>

                          <div className='reviw_nd_fee'>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#ffd700' }}>
                              <StarIcon color='#ffd700' />
                              <h4>4.9/5</h4>
                            </div>

                            <h4>$ {row.fee}</h4>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </>
                :
                <div style={{ width: '100%', textAlign: 'center', marginTop: 10, marginBottom: 30 }}>
                  <p>No Record Found</p>
                </div>
              }
            </>

          }

        </div>
      </div>


      {/* <hr style={{width:'100%',marginTop:60,marginBottom:30}}/> */}

      {/* Doctor Section */}
      <div className='doc_text_sect'>
        <h4>All Doctors</h4>
      </div>
      {skalton == true ?
        <SkeletonTheme baseColor="lightgray" highlightColor="#bebebe">
          <div className='home_doctor_skelton_div'>
            <Skeleton className='singleskeltfdc' />
            <Skeleton className='singleskeltfdc' />
            <Skeleton className='singleskeltfdc' />
            <Skeleton className='singleskeltfdc' />
          </div>
        </SkeletonTheme>
        :
        <>

          {alldoctor != '' ?
            <div className="topdivhead">
              {alldoctor.map((row) => {

                // onClick={() => handleShow(row.id,row.name,row.fee,category)}
                return (
                  <div key={row.id} className="doctor-card" onClick={() => TypeModalShow({ id: row.id, usertype: 'normal' })}>
                    {row.profile == null ?
                      <div className="doctor-card-image-container">
                        <img className="doctor-card-image" src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIEFLVeftvEbJfqWQ2hnRMSPuF_b8VMn4PdPhXOJdkPm-bfdpWTZZYAUJyHVXS4wrsDAE&usqp=CAU"} alt={"HAMZA"} />
                      </div>
                      :
                      <div className="doctor-card-image-container">
                        <img className="doctor-card-image" src={doc_profile + row.profile} alt={"Profile"} />
                      </div>
                    }

                    <div className="doctor-card-details">
                      <h3 className="doctor-card-name">{row.name}</h3>
                      <p className="doctor-card-specialty">{row.category}</p>
                    </div>
                  </div>
                )
              })}
            </div>
            :
            <div style={{ marginTop: 40 }}>
              <p>No Record Found</p>
            </div>
          }
        </>
      }


      <hr style={{ width: '100%', marginTop: 90 }} />

      <div className="services-section">
        <div className="service">
          <i className="fa fa-stethoscope"></i>
          <h2>Doctors & Specialists</h2>
          <p>Find the best doctors and specialists in your area.</p>
        </div>
        <div className="service">
          <i className="fa fa-heartbeat"></i>
          <h2>Health Records & History</h2>
          <p>Keep track of your health records and history.</p>
        </div>
        <div className="service">
          <i className="fa fa-medkit"></i>
          <h2>Medications & Prescriptions</h2>
          <p>Manage your medications and prescriptions.</p>
        </div>
      </div>


      <hr style={{ width: '100%', marginTop: 30, marginBottom: 50 }} />


      <section className='playstore_appsign'>
        <img className="image_playstore" src={mobile_screen_shot} />

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <div className='name_ty_logo'>
            <div className='circule_for_logo'>
              <img className="imf_log" src={mass_1} />
            </div>
            <h4>MAS CLINICAS</h4>
          </div>

          <div className='twoo_three_divs'>
            <CheckIcon style={{ color: '#014e78', fontSize: 15 }} />
            <h5>Download the app</h5>
          </div>

          <div className='twoo_three_divs'>
            <CheckIcon style={{ color: '#014e78', fontSize: 15 }} />
            <h5>Book Appointment</h5>
          </div>

          <div className='twoo_three_divs'>
            <CheckIcon style={{ color: '#014e78', fontSize: 15 }} />
            <h5>Consult with Doctor</h5>
          </div>

          <div className='two_btn_goplay'>
            <div onClick={() => goonlinkapp("https://play.google.com/store/apps/details?id=com.masclinicas")} className='PlayStore'>
              <img className="playstore_img" src={playstore_icon} />
              <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
                <h6>GET IT ON</h6>
                <h4>Google Play</h4>
              </div>
            </div>

            <div onClick={() => goonlinkapp("https://apps.apple.com/pk/app/mas-clinicas/id1631688867")} className='PlayStore'>
              <img className="playstore_img" src={Appstore_icon} />
              <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
                <h6>Available on the</h6>
                <h4>App Store</h4>
              </div>
            </div>
          </div>

        </div>
      </section>


      <section className='footer_top'></section>
      <div className="footer_second_end">
        <div className="col-12 col-sm-12 col-lg-6 mb-4 order-0 order-sm-0"><a className="text-decoration-none" href="#"><img src="assets/img/gallery/footer-logo.png" height="51" alt="" /></a>
          <p className="p_app_name">Mas Clinicas</p>
          <p className="text_light">The world's most trusted <br />telehealth company.</p>
        </div>

        <div className='footor_inside'>
          <div className="col-6 col-sm-4 col-lg-2 mb-3 order-2 order-sm-1">
            <h5 className="Departments">Departments</h5>
            <ul className="list-unstyled mb-md-4 mb-lg-0">
              <li className="lh-lg"><a className="footer_link" href="#!">Eye care</a></li>
              <li className="lh-lg"><a className="footer_link" href="#!">Cardiac are</a></li>
              <li className="lh-lg"><a className="footer_link" href="#!">Heart care</a></li>
            </ul>
          </div>

          <div className="col-6 col-sm-4 col-lg-2 mb-3 order-3 order-sm-2">
            <h5 className="Departments">Membership</h5>
            <ul className="list-unstyled mb-md-4 mb-lg-0">
              <li className="lh-lg"><a className="footer_link" href="#!">Free trial</a></li>
              <li className="lh-lg"><a className="footer_link" href="#!">Silver</a></li>
              <li className="lh-lg"><a className="footer_link" href="#!">Premium</a></li>
            </ul>
          </div>

          <div className="col-6 col-sm-4 col-lg-2 mb-3 order-3 order-sm-2">
            <h5 className="Departments"> Customer Care</h5>
            <ul className="list-unstyled mb-md-4 mb-lg-0">
              <li className="lh-lg"><a className="footer_link" href="#!">About Us</a></li>
              <li className="lh-lg"><a className="footer_link" href="#!">Contact US</a></li>
              <li className="lh-lg"><a className="footer_link" href="#!">Get Update</a></li>
            </ul>
          </div>
        </div>

      </div>


      <section className='footer_end'>
        <p>All rights Reserved &copy; Your Company, 2021</p>

        {/* <div class="col-12 col-sm-8 col-md-6">
            <p class="fs--1 my-2 text-center text-md-end text-200"> Made with&nbsp;
              <svg class="bi bi-suit-heart-fill" xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#F95C19" viewBox="0 0 16 16">
                <path d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z"></path>
              </svg>&nbsp;by&nbsp;<a class="fw-bold text-info" href="https://themewagon.com/" target="_blank">ThemeWagon </a>
            </p>
         </div> */}
      </section>

      {
        showScrollButton && (
          <button onClick={scrollToTop} className="scroll_top_button">
            <ArrowUpwardIcon />
          </button>
        )
      }


      {apointdetail == true &&
        <Modal_detail
          show={apointdetail}
          handleClose={handleapontdetalClose}
          getProducts={display_appointments_patient_all}

          get_name={get_name}
          get_address={get_address}
          get_email={get_email}
          get_mobile_number={get_mobile_number}
          get_id={get_id}
          get_fcm_token={get_fcm_token}
          get_web_fcm={get_web_fcm}
          get_profile={get_profile}
          profile_url={profile_url}
          get_online_status={get_online_status}
          get_gender={get_gender}
          get_experience={get_experience}

          // appointment table same
          payment_method={payment_method}
          date={date}
          day={day}
          time={time}
          appointment_id={appointment_id}
          fee={fee}
          status={status}
          category={category}
          appointment_type={app_type}
          user_type={user_type}

          // appointment review
          appointment_review={appointment_review}
        >
          <p>Modal Content Goes Here</p>
        </Modal_detail>
      }


      {paymentOnn == true &&
        <Modal_Pay_Option
          shopayment={true}
          handleapaymentclos={handleapaymentclos}
          display_appointments_patient_all={display_appointments_patient_all}

          appointment_id={appointment_id}
          insurance_fee={insurance_fee}
          check_payment_ins_or_appointment={'Insurance'}
        >
          <p>Modal Content Goes Here</p>
        </Modal_Pay_Option>
      }

    </div >
  );
}



const useStyles = makeStyles({
  topdivhead: {
    display: 'flex',
    width: '100%',
    gap: '15px',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    maxWidth: '100%',
    padding: '20px 55px 0px 55px'
  },
});
export default Home;
import React, { useState, useEffect, useContext } from 'react'
import { Link, useNavigate } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./Modal_Pay_Option.css";
import { GlobalInfo } from "../../App"
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import Connection from '../../connection';
import Modal_payment_done from '../Modal_payment_done/Modal_payment_done';
import Modal_Payment from '../Modal_Payment/Modal_Payment';
import Add_Insurance from '../../pages/Add_Insurance/Add_Insurance';

import CreditCardIcon from '@mui/icons-material/CreditCard';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import PulseLoader from "react-spinners/PulseLoader";

function Modal_Pay_Option({ shopayment, handleapaymentclos, display_appointments_patient_all, day, date, time, doctor_id, doctor_name, insurance_fee, fee, category, city, reason, symptoms, issue, allergic, all_promos, email, fcm_token, web_fcm, type_of, usertype, check_payment_ins_or_appointment, appointment_id }) {
   const { insure_check } = useContext(GlobalInfo)
   console.log("insure_check", insure_check)

   const navigate = useNavigate();
   const [showpayment_done, setShowpayment_done] = useState(false);
   const [showpayment, setShowpayment] = useState(false);
   const [ad_insurace, setAd_insurace] = useState(false);

   const [spinner, setSpinner] = useState(false);
   const [p_code, setP_code] = useState("");

   const [user_id, setUser_id] = useState("");
   const [balance, setBalance] = useState("");
   const [country, setCountry] = useState("");

   const [appointment_price, setAppointment_price] = useState("");
   const [method, setMethod] = useState("");
   const [nav_payment_done, setNav_payment_done] = useState("");

   const [promo_add, setPromo_add] = useState('');
   const [promo_text, setPromo_text] = useState(false);
   const [percentage, setPercentage] = useState("");
   const [promo_end, setPromo_end] = useState('false');

   const [final_fee, setFinal_fee] = useState("");
   const [ins_promo_update_fee, setIns_promo_update_fee] = useState("");


   // Dialog PopUp
   const [showDialog, setShowDialog] = useState(false);
   const handleShowDialog = () => {
      setShowDialog(true);
   };
   const handleCloseDialog = () => {
      setShowDialog(false);
   };
   /////////////


   useEffect(() => {
      const auth = localStorage.getItem("customer");
      const parsed = JSON.parse(auth);
      const id = parsed[0].id;
      const promo = parsed[0].promo;
      const balance = parsed[0].balance;
      const country = parsed[0].country;

      console.log("promo", promo)
      if (promo != 'true') {
         setPromo_end('false')
      } else {
         setPromo_end('true')
      }

      setUser_id(id)
      setPromo_add(promo)
      setBalance(balance)
      setCountry(country)

      let set_fee = Number(fee).toFixed(2);
      let set_fee1 = Number(insurance_fee).toFixed(2);
      setFinal_fee(set_fee)
      setIns_promo_update_fee(set_fee1)

      console.log("all_promos", all_promos)
   }, [])


   const check_promo = () => {
      setShowDialog(false);

      setTimeout(() => {
         if (p_code == "") {
            alert("Please enter promo code.")
         }
         else {

            let record = all_promos
            if (record == "") {
               setP_code("")
               alert("Invalid Promo Code.")
            }
            else {
               // const codeExists = record.some(item => item.code === p_code);
               const codeExists = record.some(item => {
                  return item.code.toLowerCase() === p_code.trim().toLowerCase();
               });

               if (codeExists == true) {
                  const matchingPromo = record.find(item => {
                     return item.code.toLowerCase() === p_code.trim().toLowerCase();
                  });

                  if (matchingPromo.allow.toLowerCase() === 'off') {
                     setP_code("")
                     alert("This promo code is currently not allowed.");
                  }
                  else {
                     let percentage = record[0].percentage
                     let minus_fee = Number((percentage / 100) * final_fee).toFixed(2);
                     let remain_fee = Number(final_fee - minus_fee).toFixed(2);

                     let minus_fee_1 = Number((percentage / 100) * ins_promo_update_fee).toFixed(2);
                     let remain_fee_1 = Number(ins_promo_update_fee - minus_fee_1).toFixed(2);

                     setFinal_fee(remain_fee)
                     setIns_promo_update_fee(remain_fee_1)

                     setPromo_add('true')
                     setPromo_text(true)
                     setPercentage(percentage)
                     setPromo_end('true')

                     toast.success('You have successfully got discount for entering promo code!');
                     console.log("sueecess")
                  }
               }
               else {
                  setP_code("")
                  alert("Invalid Promo Code.")
               }
            }
         }

      }, 700);
   }




   const Update_payment = async () => {
      if (check_payment_ins_or_appointment == 'Insurance') {
         if (Number(balance) < Number(ins_promo_update_fee)) {
            alert("You don't have sufficient balance in your wallet for booking this appointment.")
         }
         else {
            setAppointment_price(ins_promo_update_fee)
            setMethod('Wallet')
            setNav_payment_done('Insurance')
            setShowpayment_done(true);
         }

      }
      else {
         if (Number(balance) < Number(final_fee)) {
            alert("You don't have sufficient balance in your wallet for booking this appointment.")
         }
         else {
            setAppointment_price(final_fee)
            setMethod('Wallet')
            setNav_payment_done('Appointment')
            setShowpayment_done(true);
         }
      }
      // try {
      //    console.log('total_fee', total_fee)
      //    console.log('user_iid', user_id)
      //    console.log('balances', balance)

      //    if (Number(balance) < Number(total_fee)) {
      //       alert("You don't have sufficient balance in your wallet for booking this appointment.")
      //    }
      //    else {
      //       setSpinner(true)

      //       const amount = total_fee

      //       let result = await fetch(Connection + "update_payment_wallet_a", {
      //          method: 'post',
      //          body: JSON.stringify({ amount, user_id }),
      //          headers: {
      //             'Authorization': localStorage.getItem("JWT_TOKEN"),
      //             "Content-Type": 'application/json'
      //          }
      //       })
      //       result = await result.json()
      //       let record = result.response
      //       setSpinner(false)

      //       if (record === 'Token verification failed') {
      //          console.log(record);
      //          alert("Something went wrong try again later");
      //       }
      //       else if (record == "fail") {
      //          alert("Something went wrong try again later");
      //       }
      //       else {
      //          const user = localStorage.getItem("customer");
      //          const parsed = JSON.parse(user);
      //          const balance = parsed[0].balance

      //          if (balance != '') {
      //             const get_balance = parseFloat(parsed[0].balance);
      //             const total_fe = parseFloat(total_fee);
      //             parsed[0].balance = get_balance - total_fe;
      //             localStorage.setItem("customer", JSON.stringify(parsed))
      //          }
      //          else { }

      //          setMethod('Wallet')
      //          setShowpayment_done(true);
      //       }
      //    }
      // } catch (error) {
      //    console.error('Error:', error);
      //    setSpinner(false)
      //    alert("Something went wrong try again later");
      // }
   }



   const alertforpaypal = () => {
      alert("Paypal method will be add in future")
   }

   const alertfunctio = () => {
      alert("Add Insurance from our Mobile App.")
   }

   const frominsurance = () => {
      setAppointment_price(ins_promo_update_fee)
      setMethod('Insurance')
      setNav_payment_done('Appointment')
      setShowpayment_done(true);
   };

   const handlepaymentdoneClose = () => {
      console.log("back from done")
      const auth = localStorage.getItem('customer');
      const parsed = JSON.parse(auth);
      const id = parsed[0].id;
      const role = parsed[0].role;
      const jwt_tokens = localStorage.getItem("JWT_TOKEN");

      handleapaymentclos()
      display_appointments_patient_all(id, role, jwt_tokens)
      // setShowpayment_done(false);
   };

   const opencard_payment = () => {
      if (check_payment_ins_or_appointment == 'Insurance') {
         setAppointment_price(ins_promo_update_fee)
         setMethod('Card')
         setNav_payment_done('Insurance')
      }
      else {
         setAppointment_price(final_fee)
         setMethod('Card')
         setNav_payment_done('Appointment')
      }
      setShowpayment(true);
   }

   const handlepaymentClose = () => {
      setShowpayment(false);
   };

   const handlead_insuraceClose = () => {
      setAd_insurace(false);
   };


   return (
      <div className="modal_paymentoptionground">

         <div className="modalpaymentoptionContainer">
            <div className="titlepaymentCloseBtn">
               <button onClick={() => { handleapaymentclos(false); }}>X</button>
               {check_payment_ins_or_appointment == 'Appointment' ?
                  <h2>Current Fee: ${final_fee}</h2>
                  :
                  <h2>Current Fee: ${ins_promo_update_fee}</h2>
               }

               <h2>    </h2>
            </div>

            <hr style={{ marginTop: 15, marginBottom: 15 }} />

            {check_payment_ins_or_appointment == 'Appointment' ?
               <div className='just_h5'>
                  {promo_text == true ?
                     <h5>Congratulations! You get {percentage}% discount on this session.</h5>
                     :
                     <h5>You will get discount if you add correct promo code.</h5>
                  }
               </div>
               :
               <></>
            }

            <div onClick={opencard_payment} className='pay_wih_card'>
               <div className='anewdivinside'>
                  <CreditCardIcon />
                  <div className='upr_nic_nam'>
                     <h4>Pay with Card</h4>
                     <h5>All Credit / Depit Cards</h5>
                  </div>
               </div>
            </div>
            <hr className="line"></hr>


            {/* <div onClick={alertforpaypal} className='pay_wih_card'>
               <CurrencyRubleIcon style={{ color: '#02a3ed' }} />
               <div className='upr_nic_nam'>
                  <h4>Pay with Paypal</h4>
                  <h5>Log into Paypal</h5>
               </div>
            </div>
            <hr className="line"></hr> */}


            <div onClick={Update_payment} className='pay_wih_card'>
               <div className='anewdivinside'>
                  <AccountBalanceWalletOutlinedIcon />
                  <div className='upr_nic_nam'>
                     <h4>Pay with Wallet</h4>
                     <h5>Book quick appointment with Wallet</h5>
                  </div>
               </div>

               {balance ?
                  <h4>${balance}</h4>
                  :
                  <h4>$0</h4>
               }
            </div>
            <hr className="line"></hr>


            {check_payment_ins_or_appointment == 'Appointment' &&
               <>
                  {country == 'United States' && insurance_fee &&
                     <>
                        {insure_check == '' ?
                           <div onClick={() => alertfunctio()} className='pay_wih_card'>
                              <div className='anewdivinside'>
                                 <img className='insurance' src={require('../../assets/Insurance.png')} alt="Patient" />
                                 <div className='upr_nic_nam'>
                                    <h4>Pay with Insurance</h4>
                                    <h5>Book quick appointment with Insurance</h5>
                                 </div>
                              </div>
                           </div>
                           :
                           <div onClick={() => frominsurance()} className='pay_wih_card'>
                              <div className='anewdivinside'>
                                 <img className='insurance' src={require('../../assets/Insurance.png')} alt="Patient" />
                                 <div className='upr_nic_nam'>
                                    <h4>Pay with Insurance</h4>
                                    <h5>Book quick appointment with Insurance</h5>
                                 </div>
                              </div>
                           </div>
                        }
                        <hr className="line"></hr>
                     </>
                  }


                  {promo_add != 'true' &&
                     <div onClick={handleShowDialog} className='last_promo_butun'>
                        <h4>Add promo Code</h4>
                     </div>
                  }
               </>
            }

         </div>


         <Dialog className='Promo_Dialog' open={showDialog}
         // onClose={handleCloseDialog}
         >
            <DialogTitle className='Promo_dialog_title'>Add Promo Code</DialogTitle>

            <div className='Promo_Content_Inpu'>
               <input className="promo_inpu" placeholder="Enter Promo Code" onChange={(e) => setP_code(e.target.value)} required />
            </div>

            <DialogActions className='Promo_Ok_nd_Close'>
               <Button onClick={check_promo} className='Promo_Ok'>OK</Button>
               <div className='line'></div>
               <Button onClick={handleCloseDialog} className='Promo_Close'>Cancel</Button>
            </DialogActions>
         </Dialog>


         {showpayment_done &&
            <Modal_payment_done
               showpayment_done={true}
               display_appointments_patient_all={display_appointments_patient_all}
               handlepaymentdoneClose={handlepaymentdoneClose}
               day={day}
               date={date}
               time={time}
               appointment_price={appointment_price}
               category={category}
               doctor_name={doctor_name}
               city={city}
               doctor_id={doctor_id}
               reason={reason}
               symptoms={symptoms}
               issue={issue}
               allergic={allergic}
               promo_end={promo_end}
               method={method}
               email={email}
               fcm_token={fcm_token}
               web_fcm={web_fcm}
               nav_payment_done={nav_payment_done}
               appointment_id={appointment_id}

               type_of={type_of}
               usertype={usertype}
            >
               <p>Modal Appointment Done Goes Here</p>
            </Modal_payment_done>
         }


         {showpayment &&
            <Modal_Payment
               showpayment={true}
               handlepaymentClose={handlepaymentClose}
               handlepaymentdoneClose={handlepaymentdoneClose}
               day={day}
               date={date}
               time={time}
               appointment_price={appointment_price}
               category={category}
               doctor_name={doctor_name}
               city={city}
               doctor_id={doctor_id}
               reason={reason}
               symptoms={symptoms}
               issue={issue}
               allergic={allergic}
               promo_end={promo_end}
               method={method}
               email={email}
               fcm_token={fcm_token}
               web_fcm={web_fcm}
               nav_payment_done={nav_payment_done}
               appointment_id={appointment_id}

               type_of={type_of}
               usertype={usertype}
            >
               <p>Modal Payment Goes Here</p>
            </Modal_Payment>
         }


         {ad_insurace &&
            <Add_Insurance ad_insurace={true} handlepaymentClose={handlead_insuraceClose} day={day} date={date} time={time} appointment_price={ins_promo_update_fee} category={category} doctor_name={doctor_name} city={city} doctor_id={doctor_id} reason={reason} symptoms={symptoms} issue={issue} allergic={allergic} promo_end={promo_end} method={"Insurance"} email={email} fcm_token={fcm_token} web_fcm={web_fcm} type_of={type_of} usertype={usertype}>
               <p>Modal Payment Goes Here</p>
            </Add_Insurance>
         }


         {spinner == true &&
            <div className='spinner_main_loader'>
               <div className='spinner_inside'>
                  <PulseLoader
                     color={"#874DAF"}
                     loading={spinner}
                     //  size={5}
                     aria-label="Loading Spinner"
                     data-testid="loader"
                  />
                  {/* <h4>Progressing your request</h4> */}
               </div>
            </div>
         }


         <ToastContainer />
      </div>
   );
}

export default Modal_Pay_Option;

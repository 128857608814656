import React, { useState, useEffect, useContext } from 'react'
import './Doctor_Home.css';
import { Link, useNavigate } from 'react-router-dom';
import Connection from "../../connection";
import { user_profile } from '../../Image_connection';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import Switch from 'react-switch';
import { GlobalInfo } from "../../App"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PulseLoader from "react-spinners/PulseLoader";
import Modal_detail from '../../MyComponent/Modal_detail/Modal_detail';
import Add_schedulling from '../../MyComponent/Add_schedulling/Add_schedulling';
import { firebaseApp, messaging } from '../../firebase';
import { getToken } from 'firebase/messaging';

import CheckIcon from '@mui/icons-material/Check';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import HistoryIcon from '@mui/icons-material/History';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';

import mass_1 from '../../assets/mass_1.png';
import playstore_icon from '../../assets/playstore_icon.png';
import Appstore_icon from '../../assets/Appstore_icon.png';
import mobile_screen_shot from '../../assets/mobile_screen_shot.png';

const Doctor_Home = (props) => {
    const { do_free_hisd, do_status } = useContext(GlobalInfo)
    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const [ad_sche, setAd_sche] = useState(false);

    const [online_app, setOnline_app] = useState([]);
    const [pending_pay_app, setPending_pay_app] = useState([]);
    const [urgent_app, setUrgent_app] = useState([]);
    const [wait_for_approval, setWait_for_approval] = useState([]);

    const [skalton, setSkalton] = useState(true);
    const [spinner, setSpinner] = useState(false);

    const [isOn2, setIsOn2] = useState(false);

    const [user_name, setUser_name] = React.useState('');
    const [id, setId] = React.useState('');
    const [role, setRole] = React.useState('');
    const [consult_now, setConsult_now] = React.useState(false);


    const [get_name, setGet_name] = React.useState('');
    const [get_address, setGet_address] = React.useState('');
    const [get_email, setGet_email] = React.useState('');
    const [get_mobile_number, setGet_mobile_number] = React.useState('');
    const [get_id, setGet_id] = React.useState('');
    const [get_fcm_token, setGet_fcm_token] = React.useState('');
    const [get_web_fcm, setGet_web_fcm] = React.useState('');
    const [get_profile, setGet_profile] = React.useState('');
    const [profile_url, setProfile_url] = React.useState('');
    const [get_online_status, setGet_online_status] = React.useState('');
    const [get_gender, setGet_gender] = React.useState('');
    const [get_experience, setGet_experience] = React.useState('');

    const [payment_method, setPayment_method] = React.useState('');
    const [date, setDate] = React.useState('');
    const [day, setDay] = React.useState('');
    const [time, setTime] = React.useState('');
    const [appointment_id, setAppointment_id] = React.useState('');
    const [fee, setFee] = React.useState('');
    const [status, setStatus] = React.useState('');
    const [category, setCategory] = React.useState('');
    const [app_type, setApp_type] = React.useState('');
    const [user_type, setUser_type] = React.useState('');

    const jwt_tokens = localStorage.getItem("JWT_TOKEN");

    useEffect(() => {
        Notification.requestPermission().then(permission => {
            if (permission === 'granted') {
                getToken(messaging, { vapidKey: "BHwo7XkJcDLDE1g1t-ZLtjVXqlmSn12QX2qihLtgIM1COE1GqOi4US04hjv25md38FC0dSy-2C_bFFbov-cV8D4" }).then(currentToken => {
                    const austh = localStorage.getItem("customer");
                    const parsed = JSON.parse(auth);
                    const id = parsed[0].id;
                    Update_fcmtoken(id, currentToken, jwt_tokens)
                });
            }
        });

        // User ID From LocalStorage
        const auth = localStorage.getItem("customer");
        const parsed = JSON.parse(auth);
        const user_name = parsed[0].name;
        const id = parsed[0].id;
        const role = parsed[0].role;
        const consul_now = parsed[0].consult_now;
        const available = parsed[0].available;

        setId(id)
        setRole(role)
        setUser_name(user_name)


        if (consul_now == null || consul_now == 'Off') {
            setConsult_now(false)
        }
        else {
            setConsult_now(true)


            if (available == null || available == 'false') {
                setIsOn2(false)
            }
            else {
                setIsOn2(true)
            }
        }
        /////////////

        setTimeout(() => {
            check_doctor_sheduling(id, jwt_tokens)
        }, 100);
        display_appointments_patient_all(id, role, jwt_tokens)
    }, [])


    const Update_fcmtoken = async (id, fcm_token, Token) => {
        try {
            let result = await fetch(Connection + "update_both_token", {
                method: 'post',
                body: JSON.stringify({ id, fcm_token }),
                headers: {
                    'Authorization': Token,
                    "Content-Type": 'application/json'
                }
            })

            result = await result.json()
            let record = result.response

            if (record === 'Token verification failed') {
                console.log(record);
            }
            else if (record === 'fail') {
                console.log(record);
            }
            else {
                console.log(record);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };


    const check_doctor_sheduling = async (id, Token) => {
        try {
            const doctor_id = id
            let result = await fetch(Connection + "check_doctor_sheduling", {
                method: 'post',
                body: JSON.stringify({ doctor_id }),
                headers: {
                    'Authorization': Token,
                    "Content-Type": 'application/json'
                }
            })

            result = await result.json()
            const record4 = result.response

            if (record4 === 'Token verification failed') {
                console.log(record4);
            }
            else if (record4 === 'fail') {
                document.body.style.overflow = 'hidden';
                setAd_sche(true)
            }
            else { }
        } catch (error) {
            console.error('Error:', error);
        }
    };


    // For Scroll bottom to the top
    const [showScrollButton, setShowScrollButton] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const shouldShowButton = scrollTop > 200;
            setShowScrollButton(shouldShowButton);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    /////////////////////////////

    const goonlinkapp = (link) => {
        window.open(link, '_blank');
    }


    const toggleSwitch = (checked) => {
        setIsOn2(checked);
        update_consult_now_availbility(checked);
    };

    const update_consult_now_availbility = async (val) => {
        try {
            const doctor_id = id
            const avilbility = val;

            let result = await fetch(Connection + "update_consult_now_availbility", {
                method: 'post',
                body: JSON.stringify({ doctor_id, avilbility }),
                headers: {
                    'Authorization': localStorage.getItem("JWT_TOKEN"),
                    "Content-Type": 'application/json'
                }
            })
            const response = await result.json();
            const record4 = response.response;

            if (record4 === 'Token verification failed') {
                console.log('Token verification failed');
            }
            else if (record4 === 'fail') {
                console.log('Record Fail');
            }
            else {
                let available = record4[0].available;

                const user = localStorage.getItem("customer");
                const parsed = JSON.parse(user);
                parsed[0].available = available;

                localStorage.setItem("customer", JSON.stringify(parsed))

                if (available == null || available == 'false') {
                    setIsOn2(false)
                }
                else {
                    setIsOn2(true)
                }
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };



    const display_appointments_patient_all = async (id, role, Token) => {
        try {
            let result = await fetch(Connection + "display_appointments_user_and_doctor", {
                method: 'post',
                body: JSON.stringify({ id, role }),
                headers: {
                    'Authorization': Token,
                    "Content-Type": 'application/json'
                }
            })


            const response = await result.json();
            const record4 = response.response;

            if (record4 === 'Token verification failed') {
                console.log('Token verification failed');
            }
            else if (record4 === 'fail') {
                console.log('Record Fail');
            }
            else {
                setOnline_app(record4.filter(record4 => record4.status === "pending" && record4.type === 'online'));
                setPending_pay_app(record4.filter(record4 => (record4.status === 'request' || record4.status === 'wait_for_pay') && record4.payment_method === 'Insurance'));
                setUrgent_app(record4.filter(record4 => record4.status === "pending" && record4.type === 'urgent'));
                setWait_for_approval(record4.filter(record4 => record4.status === "request" && record4.payment_method != 'Insurance'))
            }
            setSkalton(false);
        }
        catch (error) {
            console.error('Error:', error);
            setSkalton(false);
        }
    }




    const Accept_appointment = async (appointment_id, time, date, payment_method) => {
        try {
            setSpinner(true)
            window.scrollTo(0, 0);
            document.body.style.overflow = 'hidden';

            let status;
            if (payment_method == 'Insurance') {
                status = 'wait_for_pay'
            }
            else {
                status = 'pending'
            }

            let result = await fetch(Connection + "do_it_pending", {
                method: 'post',
                body: JSON.stringify({ appointment_id, status }),
                headers: {
                    'Authorization': localStorage.getItem("JWT_TOKEN"),
                    "Content-Type": 'application/json'
                }
            })
            const response = await result.json();
            const record4 = response.response;

            if (record4 === 'Token verification failed') {
                console.log('Token verification failed');
                alert("Please try again later");
            }
            else if (record4 === 'fail') {
                console.log('Record Fail');
                alert("Please try again later");
            }
            else {
                toast.success('Please be available on appointment time');

                display_appointments_patient_all(id, role, jwt_tokens)
            }
            document.body.style.overflow = 'auto';
            setSpinner(false)
        } catch (error) {
            console.error('Error:', error);
            document.body.style.overflow = 'auto';
            setSpinner(false)
            alert("Please try again later");
        }
    };



    const render_appointment_list = (list_data, check_status) => {

        return list_data.map((appointment) => {
            let date = appointment.date
            let time = appointment.time
            let day = appointment.day
            let type = appointment.type
            let timeAgo;

            const givenDate = new Date(`${date} ${time}`);
            const currenttDate = new Date();
            const differenceMs = currenttDate - givenDate;

            const minute = 60 * 1000;
            const hour = minute * 60;
            const dayy = hour * 24;
            const month = dayy * 30;
            const year = dayy * 365;

            const years = Math.floor(differenceMs / year);
            const months = Math.floor(differenceMs / month);
            const days = Math.floor(differenceMs / dayy);
            const hours = Math.floor(differenceMs / hour);
            const minutes = Math.floor(differenceMs / minute);

            if (years >= 1) {
                timeAgo = `${years} year${years > 1 ? 's' : ''} ago`;
            } else if (months >= 1) {
                timeAgo = `${months} month${months > 1 ? 's' : ''} ago`;
            } else if (days >= 1) {
                timeAgo = `${days} day${days > 1 ? 's' : ''} ago`;
            } else if (hours >= 1) {
                timeAgo = `${hours} hour${hours > 1 ? 's' : ''} ago`;
            } else if (minutes >= 1) {
                timeAgo = `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
            } else {
                timeAgo = `${date} ${time}`;
            }

            let get_id = appointment.get_id
            let get_name = appointment.get_name
            let get_email = appointment.get_email
            let get_address = appointment.get_address
            let get_fcm_token = appointment.get_fcm_token
            let get_web_fcm = appointment.get_web_fcm
            let get_online_status = appointment.get_online_status
            let get_mobile_number = appointment.get_mobile_number
            let get_gender = appointment.get_gender
            let get_experience = appointment.get_experience

            let payment_method = appointment.payment_method
            let appointment_id = appointment.id
            let fee = appointment.fee
            let status = appointment.status
            let category = appointment.category
            let user_type = appointment.user_type

            let get_profile = appointment.get_profile
            let profile_url = user_profile + get_profile

            let user_name = appointment.user_name

            const fulldateString = `${date} ${time}`;
            const fulldate = new Date(fulldateString);
            const currentDate = new Date();

            let datestatus;
            if (fulldate > currentDate) {
                datestatus = 'future'
            }
            else if (fulldate < currentDate) {
                datestatus = 'overdue'
            }
            else {
                datestatus = 'same'
            }


            return (
                <div>
                    <div className='upcomngmain'>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px', padding: '0 14px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <p style={{ color: 'black', fontSize: '16px', fontWeight: 'bold' }}>
                                    {get_name ? get_name : user_name}
                                    {user_type === "hisd" && <span style={{ color: '#00c480', fontSize: '10px', fontWeight: 'bold' }}>(FREE HISD)</span>}
                                </p>
                                {datestatus === 'overdue' ? (
                                    <>
                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '3px' }}>
                                            <i style={{ color: "#f26f6f", marginRight: '3px', fontSize: '16px' }}><CalendarTodayIcon fontSize="5px" /></i>
                                            <p style={{ color: '#f26f6f', fontSize: '12px', fontWeight: 'bold' }}>{date} | {time}</p>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <i className="icon-back-in-time" style={{ color: "#f26f6f", fontSize: '16px', marginRight: '3px' }}><HistoryIcon fontSize='16px' /></i>
                                            <p style={{ color: '#f26f6f', fontSize: '12px', fontWeight: 'bold' }}>{timeAgo}</p>
                                        </div>
                                    </>
                                ) : (
                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '3px' }}>
                                        <i className="icon-calendar" style={{ color: "gray", fontSize: '16px', marginRight: '3px' }}><CalendarTodayIcon fontSize="5px" /></i>
                                        <p style={{ color: 'gray', fontSize: '12px' }}>{date} | <span style={{ color: 'black', fontSize: '12px' }}>{time}</span></p>
                                    </div>
                                )}
                            </div>


                            {get_profile ?
                                <img className="upcomimge" src={profile_url} alt="doctor-image" />
                                :
                                <img className='upcomimge' src={require('../../assets/empty.png')} alt="empty" />
                            }
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '3px', padding: '0 14px' }}>
                            <p style={{ color: '#874DAF', fontSize: '14px' }}>{category}</p>
                            <p style={{ color: '#874DAF', fontSize: '14px', fontWeight: 'bold' }}>${fee}</p>
                        </div>

                        {type === 'urgent' && <p style={{ color: '#00c480', fontSize: '15px', fontWeight: 'bold', padding: '0 14px' }}>Urgent Care</p>}
                        {type === 'online' && <p style={{ color: '#00c480', fontSize: '15px', fontWeight: 'bold', padding: '0 14px' }}>Online Appointment</p>}
                        {payment_method === 'Insurance' && <p style={{ color: '#00c480', fontSize: '15px', fontWeight: 'bold', padding: '0 14px' }}>Insurance</p>}

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0 14px' }}>
                            {status === 'pending' ?
                                <p style={{ color: 'black', fontSize: '14px' }}>● In-Process</p>
                                :
                                <>
                                    {status == 'wait_for_pay' ?
                                        <p style={{ color: 'black', fontSize: '14px' }}>● Waiting for Payment</p>
                                        :
                                        <p style={{ color: 'black', fontSize: '14px' }}>● requested</p>
                                    }
                                </>
                            }

                            {datestatus === 'overdue' && <p style={{ color: '#f26f6f', fontWeight: 'bold' }}>({datestatus})</p>}
                        </div>

                        {check_status == 'wait_for_approve' ?
                            <button className='see_detailbutton' onClick={() => Accept_appointment(appointment_id, time, date, payment_method)}>
                                <span style={{ fontWeight: '400', fontSize: '14px' }}>Approve</span>
                                <i style={{ color: "white", fontSize: '17px', marginLeft: '5px', marginBottom: -5 }}><ArrowForwardOutlinedIcon /></i>
                            </button>

                            :

                            <button className='see_detailbutton'
                                onClick={() => {
                                    if (get_id) {
                                        apointdeatsho(
                                            get_name, get_address, get_email, get_mobile_number, get_id, get_fcm_token, get_web_fcm, get_profile, profile_url, get_online_status, get_gender, get_experience,
                                            payment_method, date, day, time, appointment_id, fee, status, category, type, user_type,
                                        );
                                    } else {
                                        alert("Doctor not available");
                                    }
                                }}>
                                {check_status == 'pending_insu_payment' ?
                                    <>
                                        {status == 'wait_for_pay' ?
                                            <span style={{ fontWeight: '400', fontSize: '14px' }}>See Details</span>
                                            :
                                            <span style={{ fontWeight: '400', fontSize: '14px' }}>Verify Insurance</span>
                                        }
                                    </>
                                    :
                                    <span style={{ fontWeight: '400', fontSize: '14px' }}>See Details</span>
                                }
                                <i style={{ color: "white", fontSize: '17px', marginLeft: '5px', marginBottom: -5 }}><ArrowForwardOutlinedIcon /></i>
                            </button>
                        }
                    </div>
                </div>
            );
        });
    };



    const apointdeatsho = (get_name, get_address, get_email, get_mobile_number, get_id, get_fcm_token, get_web_fcm, get_profile, profile_url, get_online_status, get_gender, get_experience, payment_method, date, day, time, appointment_id, fee, status, category, type, user_type) => {
        document.body.style.overflow = 'hidden';

        // // user table
        setGet_name(get_name)
        setGet_address(get_address)
        setGet_email(get_email)
        setGet_mobile_number(get_mobile_number)
        setGet_id(get_id)
        setGet_fcm_token(get_fcm_token)
        setGet_web_fcm(get_web_fcm)
        setGet_profile(get_profile)
        setProfile_url(profile_url)
        setGet_online_status(get_online_status)
        setGet_gender(get_gender)
        setGet_experience(get_experience)

        setPayment_method(payment_method)
        setDate(date)
        setDay(day)
        setTime(time)
        setAppointment_id(appointment_id)
        setFee(fee)
        setStatus(status)
        setCategory(category)
        setApp_type(type)
        setUser_type(user_type)

        setShow(true);
    };


    const handleClose = () => {
        document.body.style.overflow = 'auto';
        setShow(false);
    };


    const handleClosad_sche = () => {
        document.body.style.overflow = 'auto';
        setAd_sche(false);
    };


    return (
        <div className="Doctorhome_website">

            <header className='docto_hometop'>
                <div className='logo_dochome_name'>
                    <div className='circle_logo'>
                        <img className="image_logo" src={mass_1} />
                    </div>

                    <h4>Hello, {user_name}!</h4>
                </div>

                <h3>Find the Best Doctor Near You</h3>

                {consult_now == true &&
                    <div className='consult_onof'>
                        <h4>Urgent Care Visit Availbility - (Appear Online)</h4>
                        <Switch
                            onChange={toggleSwitch}
                            checked={isOn2}
                            onColor="#874DAF"
                            offColor="#c6c5c5"
                            height={24}
                            width={48}
                        />
                    </div>
                }

                {/* <div style={{ cursor: 'pointer', width: 'calc(100% - 20px)', borderRadius: '10px', overflow: 'hidden', marginTop: '10px', alignSelf: 'center' }}>
                    <img src={require('../../assets/s.png')} alt="Image" style={{ width: '100%', height: 'calc(100% / 5)' }} />
                </div> */}
            </header>



            {urgent_app.length > 0 &&
                <div className='allpendingappointm'>
                    <h3>Urgent Care</h3>

                    <div className='maindivpendingappo'>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: 10 }}>
                            {render_appointment_list(urgent_app)}
                        </div>
                    </div>
                </div>
            }


            <div className='allpendingappointm'>
                <h3>Upcoming Visits</h3>

                <div className='maindivpendingappo'>
                    {skalton ?
                        <SkeletonTheme baseColor="lightgray" highlightColor="#bebebe">
                            <div className='pending_appoi'>
                                <Skeleton className='skeltofpendngupcm' />
                                <Skeleton className='skeltofpendngupcm' />
                                <Skeleton className='skeltofpendngupcm' />
                                <Skeleton className='skeltofpendngupcm' />
                            </div>
                        </SkeletonTheme>
                        :
                        <div style={{ width: '100%' }}>
                            {online_app.length === 0 ?
                                <div className='nopenrecorddiv'>
                                    <h4>No Record Found</h4>
                                </div>
                                :
                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: 10 }}>
                                    {render_appointment_list(online_app)}
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>

            <hr style={{ width: '100%', marginTop: 20 }} />

            <div className='allpendingappointm'>
                <h3>Waiting for Approval</h3>

                <div className='maindivpendingappo'>
                    {skalton ? (
                        <SkeletonTheme baseColor="lightgray" highlightColor="#bebebe">
                            <div className='pending_appoi'>
                                <Skeleton className='skeltofpendngupcm' />
                                <Skeleton className='skeltdochmapt' />
                                <Skeleton className='skeltdochmapt' />
                                <Skeleton className='skeltdochmapt' />
                            </div>
                        </SkeletonTheme>
                    ) : (
                        <div style={{ width: '100%' }}>
                            {wait_for_approval.length === 0 ?
                                <div className='norecorddiv'>
                                    <h4>No Record Found</h4>
                                </div>
                                :
                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: 10 }}>
                                    {render_appointment_list(wait_for_approval, 'wait_for_approve')}
                                </div>
                            }
                        </div>
                    )}
                </div>
            </div>


            {pending_pay_app.length > 0 &&
                <div className='allpendingappointm'>
                    <h3>Urgent Care</h3>

                    <div className='maindivpendingappo'>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: 10 }}>
                            {render_appointment_list(pending_pay_app, 'pending_insu_payment')}
                        </div>
                    </div>
                </div>
            }



            <hr className='spaceindocorsumins' />

            <div className="services-section">
                <div className="service">
                    <i className="fa fa-stethoscope"></i>
                    <h2>Doctors & Specialists</h2>
                    <p>Find the best doctors and specialists in your area.</p>
                </div>
                <div className="service">
                    <i className="fa fa-heartbeat"></i>
                    <h2>Health Records & History</h2>
                    <p>Keep track of your health records and history.</p>
                </div>
                <div className="service">
                    <i className="fa fa-medkit"></i>
                    <h2>Medications & Prescriptions</h2>
                    <p>Manage your medications and prescriptions.</p>
                </div>
            </div>


            <hr style={{ width: '100%', marginTop: 30, marginBottom: 50 }} />


            <section className='playstore_appsign'>
                <img className="image_playstore" src={mobile_screen_shot} />

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <div className='name_ty_logo'>
                        <div className='circule_for_logo'>
                            <img className="imf_log" src={mass_1} />
                        </div>
                        <h4>MAS CLINICAS</h4>
                    </div>

                    <div className='twoo_three_divs'>
                        <CheckIcon style={{ color: '#014e78', fontSize: 15 }} />
                        <h5>Download the app</h5>
                    </div>

                    <div className='twoo_three_divs'>
                        <CheckIcon style={{ color: '#014e78', fontSize: 15 }} />
                        <h5>Book Appointment</h5>
                    </div>

                    <div className='twoo_three_divs'>
                        <CheckIcon style={{ color: '#014e78', fontSize: 15 }} />
                        <h5>Consult with Doctor</h5>
                    </div>

                    <div className='two_btn_goplay'>
                        <div onClick={() => goonlinkapp("https://play.google.com/store/apps/details?id=com.masclinicas")} className='PlayStore'>
                            <img className="playstore_img" src={playstore_icon} />
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
                                <h6>GET IT ON</h6>
                                <h4>Google Play</h4>
                            </div>
                        </div>

                        <div onClick={() => goonlinkapp("https://apps.apple.com/pk/app/mas-clinicas/id1631688867")} className='PlayStore'>
                            <img className="playstore_img" src={Appstore_icon} />
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
                                <h6>Available on the</h6>
                                <h4>App Store</h4>
                            </div>
                        </div>
                    </div>

                </div>
            </section>


            <section className='footer_top'></section>
            <div className="footer_second_end">
                <div className="col-12 col-sm-12 col-lg-6 mb-4 order-0 order-sm-0"><a className="text-decoration-none" href="#"><img src="assets/img/gallery/footer-logo.png" height="51" alt="" /></a>
                    <p className="p_app_name">Mas Clinicas</p>
                    <p className="text_light">The world's most trusted <br />telehealth company.</p>
                </div>

                <div className='footor_inside'>
                    <div className="col-6 col-sm-4 col-lg-2 mb-3 order-2 order-sm-1">
                        <h5 className="Departments">Departments</h5>
                        <ul className="list-unstyled mb-md-4 mb-lg-0">
                            <li className="lh-lg"><a className="footer_link" href="#!">Eye care</a></li>
                            <li className="lh-lg"><a className="footer_link" href="#!">Cardiac are</a></li>
                            <li className="lh-lg"><a className="footer_link" href="#!">Heart care</a></li>
                        </ul>
                    </div>

                    <div className="col-6 col-sm-4 col-lg-2 mb-3 order-3 order-sm-2">
                        <h5 className="Departments">Membership</h5>
                        <ul className="list-unstyled mb-md-4 mb-lg-0">
                            <li className="lh-lg"><a className="footer_link" href="#!">Free trial</a></li>
                            <li className="lh-lg"><a className="footer_link" href="#!">Silver</a></li>
                            <li className="lh-lg"><a className="footer_link" href="#!">Premium</a></li>
                        </ul>
                    </div>

                    <div className="col-6 col-sm-4 col-lg-2 mb-3 order-3 order-sm-2">
                        <h5 className="Departments"> Customer Care</h5>
                        <ul className="list-unstyled mb-md-4 mb-lg-0">
                            <li className="lh-lg"><a className="footer_link" href="#!">About Us</a></li>
                            <li className="lh-lg"><a className="footer_link" href="#!">Contact US</a></li>
                            <li className="lh-lg"><a className="footer_link" href="#!">Get Update</a></li>
                        </ul>
                    </div>
                </div>

            </div>


            <section className='footer_end'>
                <p>All rights Reserved &copy; Your Company, 2021</p>

                {/* <div class="col-12 col-sm-8 col-md-6">
            <p class="fs--1 my-2 text-center text-md-end text-200"> Made with&nbsp;
              <svg class="bi bi-suit-heart-fill" xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#F95C19" viewBox="0 0 16 16">
                <path d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z"></path>
              </svg>&nbsp;by&nbsp;<a class="fw-bold text-info" href="https://themewagon.com/" target="_blank">ThemeWagon </a>
            </p>
         </div> */}
            </section>

            <ToastContainer />


            {spinner && (
                <div style={{ width: '100%', height: '100%', backgroundColor: 'rgba(2, 2, 2, 0.8)', position: 'absolute', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <div style={{ display: 'flex', width: '30%', backgroundColor: 'white', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: '30px', boxShadow: '0 1px 5px rgba(0, 0, 0, 0.2)', borderRadius: '6px', }}>
                        <PulseLoader
                            color={"#874DAF"}
                            loading={spinner}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                        <p style={{ fontSize: '16px', color: '#874DAF', fontWeight: 'bold', marginLeft: '15px' }}>
                            Progressing your request
                        </p>
                    </div>
                </div>
            )}

            {show == true &&
                <Modal_detail
                    show={show}
                    handleClose={handleClose}
                    getProducts={display_appointments_patient_all}

                    get_name={get_name}
                    get_address={get_address}
                    get_email={get_email}
                    get_mobile_number={get_mobile_number}
                    get_id={get_id}
                    get_fcm_token={get_fcm_token}
                    get_web_fcm={get_web_fcm}
                    get_profile={get_profile}
                    profile_url={profile_url}
                    get_online_status={get_online_status}
                    get_gender={get_gender}
                    get_experience={get_experience}

                    // appointment table same
                    payment_method={payment_method}
                    date={date}
                    day={day}
                    time={time}
                    appointment_id={appointment_id}
                    fee={fee}
                    status={status}
                    category={category}
                    appointment_type={app_type}
                    user_type={user_type}
                >
                    <p>Modal Content Goes Here</p>
                </Modal_detail>
            }


            {ad_sche &&
                <Add_schedulling show={ad_sche} handleClose={handleClosad_sche} check_doctor_sheduling={check_doctor_sheduling}>
                    <p>Modal Content Goes Here</p>
                </Add_schedulling>
            }

            {showScrollButton && (
                <button onClick={scrollToTop} className="scroll_top_button">
                    <ArrowUpwardIcon />
                </button>
            )}

        </div>
    );
}

export default Doctor_Home;
import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom"
import 'react-toastify/dist/ReactToastify.css';
import "./Type_Selection.css";
import Modal_categ from '../Modal_categ/Modal_categ';
import Modal from '../Modal/Modal';

import online_cons from '../../assets/online_cons.png';
import Online_forrr from '../../assets/Online_forrr.png';
import local_image_doc1 from '../../assets/local_image_doc1.png';

function Type_Selection({ typeModalclose, usertype, doctorId, doctorcategory, day_11, final_date_1, doctor_name, fee, category, city, email, fcm_token, web_fcm }) {

   const [type_of, setType_of] = useState('');
   const [show, setShow] = useState(false);
   const [schecdual, setSchecdual] = useState(false);

   const navigate = useNavigate();

   useEffect(() => {
      const auth = localStorage.getItem("customer");
      const parsed = JSON.parse(auth);
      const id = parsed[0].id;
   }, [])

   const handleShow = (value) => {
      const type__of = value['type_of']
      setType_of(type__of)

      if (type__of == 'online') {
         setShow(true);
      }
      else {
         document.body.style.overflow = 'auto';

         const catgry = 'urgent'
         const url = `/Doctor_by_category/${catgry}?value1=${type__of}&value2=${usertype}`;
         navigate(url);
      }

      // if (usertype == 'hisd') {
      //    setShow(true);
      // }
      // else if (doctorcategory) {
      //    document.body.style.overflow = 'auto';

      //    const url = `/Doctor_by_category/${doctorcategory}?value1=${type_of}&value2=${usertype}`;
      //    navigate(url);
      // }
      // else if (doctor_name) {
      //    setSchecdual(true);
      // }
      // else {
      //    document.body.style.overflow = 'auto';

      //    const url = `/Doctor_Details/${doctorId}?value1=${type_of}&value2=${usertype}`;
      //    navigate(url);
      // }
   };


   const handleClose = () => {
      setShow(false);
   };

   const handleschecdualClose = () => {
      setSchecdual(false);
   };


   return (
      <div className="type_selectBackground">

         <div className="typeselectContainer">
            <div className="titletypeCloseBtn">
               <h2>Category</h2>
               <button onClick={() => { typeModalclose(false); }}>X</button>
            </div>

            <hr style={{ marginTop: 15, marginBottom: 15 }} />


            <header className='headr_typeselect'>
               <div style={{ width: '91%', display: 'flex', flexDirection: 'column' }}>
                  <h4 style={{ alignSelf: 'flex-start' }}>How can we help you today?</h4>

                  <div className='main_div_inheader'>
                     <div onClick={() => handleShow({ type_of: 'online' })} className='video_consultation'>
                        <h4>Schedule Appointment</h4>
                        <h5>Verified Doctors</h5>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '85%', height: '78%' }}>
                           <img className="image_consult" src={Online_forrr} />
                        </div>
                     </div>

                     <div onClick={() => handleShow({ type_of: 'urgent' })} className='onlyurgentsingle'>
                        <h4>Urgent Care</h4>
                        <h5 style={{ display: 'flex', alignItems: 'center', marginTop: -2 }}>Doctors Online Now <p style={{ color: '#00c480', fontSize: 13, marginLeft: 2 }}>●</p></h5>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%', height: '78%', marginTop: 2 }}>
                           <img className="image_consult" src={online_cons} />
                        </div>
                     </div>

                     {/* <div className='next_two_btn'>
                        <div onClick={() => handleShow({ type_of: 'offline' })} className='book_appointm_btn'>
                           <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                              <div style={{ width: '80%', display: 'flex', flexDirection: 'column', marginTop: 10, marginLeft: 10 }}>
                                 <h4>In Person Clinic Appointment</h4>
                              </div>

                              <img className="image_book" src={local_image_doc1} />
                           </div>
                        </div>


                        <div onClick={() => handleShow({ type_of: 'urgent' })} className='free_consult_btn'>
                           <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                              <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10, marginLeft: 10 }}>
                                 <h4>Urgent Care (No Wait)</h4>
                                 <h5>Doctors Online Now</h5>
                              </div>

                              <img className="image_free" src={online_cons} />
                           </div>
                        </div>
                     </div> */}

                  </div>
               </div>
            </header>

         </div>


         {show == true &&
            <Modal_categ show={show} handleClose={handleClose} type_of={type_of} usertype={usertype}>
               <p>Modal Content Goes Here</p>
            </Modal_categ>
         }


         {schecdual &&
            <Modal show={schecdual} handleClose={handleschecdualClose} day_11={day_11} final_date_1={final_date_1} doctor_id={doctorId} doctor_name={doctor_name} fee={fee} category={category} city={city} email={email} fcm_token={fcm_token} web_fcm={web_fcm} type_of={type_of} usertype={usertype}>
               <p>Modal Content Goes Here</p>
            </Modal>
         }

      </div>
   );
}

export default Type_Selection;

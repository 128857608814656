import React, { useState, useEffect } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Rescedule_appointment_calendar from '../../MyComponent/Rescedule_appointment_calendar/Rescedule_appointment_calendar';

function Modal_reschedual_appoint({ handleClose, handlerescheappoint, get_apointments, appointment_id, doctor_id, user_id, doctor_profile, doctor_nam, user_nam, user_email, doctor_email, category, appointme_date, time, fcm_token, web_fcm, type_of }) {
    const [reschedualcalenda, setReschedualcalenda] = useState(false);

    const options = [
        'I am having schedule clash',
        'I am not available on schedule',
        'I have an activity that can not be left behind',
        'I dont want to tell',
        'Others',
    ];
    const [other, setOther] = React.useState(false);
    const [rescedule_reason, setRescedule_reason] = React.useState('');
    const [selectedOption, setSelectedOption] = React.useState('');
    const handleCheckboxChange = (option) => {
        if (option == 'Others') {
            setOther(true)
            setRescedule_reason('')
        }
        else {
            setRescedule_reason(option)
            setOther(false)
        }
        setSelectedOption(option)
    };


    const reschedual_appointment = async () => {
        if (rescedule_reason == '') {
            alert("Please select reason for reschedule appoitment.")
        }
        else {
            setReschedualcalenda(true)
        }
    }


    const handlereschecalend = () => {
        setReschedualcalenda(false);
    };


    return (
        <div className="modal_cancelappointground">

            <div className="modalcancelapointContainer">
                <div className="titlecancelapointCloseBtn">
                    <button onClick={() => { handlerescheappoint(false); }}>
                        <ArrowBackIcon />
                    </button>
                    <h3>Reschedule Appointment</h3>
                </div>

                <h3 className='hspecilh3'>Reason for Reschedule Appointment</h3>

                <div className='cancelcheckbox'>
                    {options.map((option) => (
                        <label style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10 }} key={option}>
                            <input className={selectedOption === option ? 'custom_checkbox_active' : 'custom_checkbox_inactive'} type="checkbox" value={option} checked={selectedOption === option}
                                onChange={() => handleCheckboxChange(option)}
                            />
                            <h4>{option}</h4>
                        </label>
                    ))}
                </div>

                {other == true &&
                    <textarea onChange={(e) => setRescedule_reason(e.target.value)} className="resaon_inpu" placeholder="Please describe your reason." ></textarea>
                }

                <div onClick={() => reschedual_appointment()} className='cancel_btn'>
                    <p>Submit</p>
                </div>

            </div>

            {reschedualcalenda == true &&
                <Rescedule_appointment_calendar
                    handleClose={handleClose}
                    handlerescheappoint={handlerescheappoint}
                    handlereschecalend={handlereschecalend}
                    get_apointments={get_apointments}
                    rescedule_reason={rescedule_reason}
                    time={time}
                    appointment_id={appointment_id}
                    doctorid={doctor_id}
                    userid={user_id}
                    fcm_token={fcm_token}
                    web_fcm={web_fcm}
                    doctor_profile={doctor_profile}
                    doctor_nam={doctor_nam}
                    user_nam={user_nam}
                    category={category}
                    appointme_date={appointme_date}
                    doctor_email={doctor_email}
                    user_email={user_email}
                    type_of={type_of}
                >
                    <p>Modal Content Goes Here</p>
                </Rescedule_appointment_calendar>
            }
        </div>
    );
}

export default Modal_reschedual_appoint;

import React, { useState, useEffect, useMemo, useContext } from 'react'
import './Button.css';
import { Link } from 'react-router-dom';
import { GlobalInfo } from "../../../App"
import Connection from '../../../connection';

export function Button() {
  const { loginenter } = useContext(GlobalInfo)

  const logout = async (e) => {
    document.body.style.overflow = 'auto';
    console.log("logout")

    const auth = localStorage.getItem("customer");
    const parsed = JSON.parse(auth);
    const id = parsed[0].id;
    Update_fcmtoken(id)

    localStorage.removeItem("customer");
    loginenter()
  }


  const Update_fcmtoken = async (id) => {
    try {
      let fcm_token = ''
      let result = await fetch(Connection + "update_both_token", {
        method: 'post',
        body: JSON.stringify({ id, fcm_token }),
        headers: {
          'Authorization': localStorage.getItem("JWT_TOKEN"),
          "Content-Type": 'application/json'
        }
      })

      result = await result.json()
      let record = result.response

      if (record === 'Token verification failed') {
        console.log(record);
      }
      else if (record === 'fail') {
        console.log(record);
      }
      else {
        console.log(record);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Link to='log-out'>
      <button onClick={logout} className='btn'>Log Out</button>
    </Link>
  );
}

import React, { useState, useEffect } from 'react'
import Connection from '../../connection';
import PulseLoader from "react-spinners/PulseLoader";
import { Link, useNavigate } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import CheckIcon from '@mui/icons-material/Check';

function Rescedule_appointment_calendar({ handleClose, handlerescheappoint, handlereschecalend, get_apointments, rescedule_reason, time, appointment_id, doctorid, userid, fcm_token, web_fcm, doctor_profile, doctor_nam, user_nam, category, appointme_date, doctor_email, user_email, type_of }) {
    const navigate = useNavigate();

    const [spinner, setSpinner] = React.useState(false);

    const [selectedDay, setSelectedDay] = useState(null);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedDayOfWeek, setSelectedDayOfWeek] = useState(null);
    const [daysList, setDaysList] = useState([]);
    const [skalton, setSkalton] = useState(true);
    const [cancel_app, setCancel_app] = React.useState(false);

    const [onlineSchedules, setOnlineSchedules] = useState({
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: [],
        Sunday: [],
    });

    const [offlineSchedules, setOfflineSchedules] = useState({
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: [],
        Sunday: [],
    });

    const [alreadyTimeBookedSlot, setAlreadyTimeBookedSlot] = useState([]);


    // Dialog PopUp
    const [showDialog, setShowDialog] = useState(false);
    const handleShowDialog = () => {
        toast.success('Your Appointment successfully reschedule!');
        setShowDialog(true);
    };
    const handleCloseDialog = () => {
        setShowDialog(false);
        get_apointments(userId)

        if (role == 'user') {
            handlerescheappoint(false)
        }
        else {
            handleClose(false)
        }
    };
    /////////////


    const [userId, setUserId] = useState('');
    const [role, setRole] = useState('');
    const [username, setUser_name] = useState('');


    useEffect(() => {
        calculateDaysList();

        // User ID From LocalStorage
        const auth = localStorage.getItem("customer");
        const parsed = JSON.parse(auth);
        const id = parsed[0].id;
        const user_name = parsed[0].name;
        const rolee = parsed[0].role;

        setUserId(id)
        setRole(rolee)
        setUser_name(user_name)
        /////////////

        cancel_checkkk()

        const jwt_tokens = localStorage.getItem("JWT_TOKEN");
        setTimeout(() => {
            All_Appoint_This_Doc(rolee, id, jwt_tokens)
        }, 100);
    }, [])


    const cancel_checkkk = () => {
        console.log("date => ", appointme_date)
        console.log("time =>", time)

        const appointmentDate = new Date(appointme_date);
        const [timeValue, meridiem] = time.split(" ");
        const [hoursValue, minutesValue] = timeValue.split(":").map(Number);

        let hours = hoursValue;
        if (meridiem === "pm" && hours !== 12) {
            hours += 12;
        }

        appointmentDate.setHours(hours, minutesValue);

        const currentTime = new Date();
        const twentyFourHoursFromNow = new Date(currentTime.getTime() + 86400000);

        if (currentTime >= appointmentDate) {
            // Past appointment
            // You can cancel the appointment
            // Place your cancellation logic here
            setCancel_app(true)
        }
        else if (appointmentDate <= twentyFourHoursFromNow) {
            // Appointment within the next 24 hours
            // You can cancel the appointment
            // Place your cancellation logic here
            setCancel_app(true)
        }
        else {
            // Future appointment, more than 24 hours away
            setCancel_app(false)
        }
    };




    const All_Appoint_This_Doc = async (role, id, Token) => {
        try {
            let user_id
            let doctor_id

            if (role == 'user') {
                user_id = id
                doctor_id = doctorid
            }
            else {
                user_id = userid
                doctor_id = id
            }
            let result = await fetch(Connection + "patient_Appoint_specifc_Doc", {
                method: 'post',
                body: JSON.stringify({ user_id, doctor_id }),
                headers: {
                    'Authorization': Token,
                    "Content-Type": 'application/json'
                }
            })

            result = await result.json()
            const record4 = result.response

            if (record4 == "Token verification failed") {
                console.log("When Token verification failed =>", record4)
                setAlreadyTimeBookedSlot([])
            }
            else if (record4 != 'fail') {
                let len = record4.length

                for (let i = 0; i < len; i++) {
                    const record = record4[i];

                    // Create an object with both day and time properties
                    const slot = {
                        day: record.day,
                        time: record.time,
                        date: record.date,
                    };

                    setAlreadyTimeBookedSlot(prevSlots => [...prevSlots, slot]);
                }
            }
            else {
                setAlreadyTimeBookedSlot([])
            }

            get_schedule(Token)
        } catch (error) {
            console.error('Error:', error);
        }
    };


    const get_schedule = async (Token) => {
        try {
            const doctor_id = doctorid
            let result = await fetch(Connection + "get_schedule", {
                method: 'post',
                body: JSON.stringify({ doctor_id }),
                headers: {
                    'Authorization': Token,
                    "Content-Type": 'application/json'
                }
            })

            result = await result.json()
            const record4 = result.response

            if (record4 === 'Token verification failed') {
                console.log(record4);
                setOnlineSchedules({})
                setOfflineSchedules({})
            }
            else if (record4 === "fail") {
                console.log(record4);
                setOnlineSchedules({})
                setOfflineSchedules({})
            }
            else {
                const { online_arr, offline_arr } = record4[0];

                if (online_arr != '' && online_arr != null) {
                    console.log("online array => ", online_arr)

                    const parsedOnlineArr = JSON.parse(online_arr);

                    setOnlineSchedules(parsedOnlineArr)
                }
                else {
                    setOnlineSchedules({})
                }

                if (offline_arr != '' && offline_arr != null) {
                    console.log("offline array => ", offline_arr)

                    const parsedOfflineArr = JSON.parse(offline_arr);

                    setOfflineSchedules(parsedOfflineArr)
                }
                else {
                    setOfflineSchedules({})
                }
            }
            setSkalton(false)
        } catch (error) {
            console.error('Error:', error);
            setSkalton(false)
        }
    };


    // For 7 days And time slot select
    const calculateDaysList = () => {
        const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const today = new Date().getDay();
        const updatedDaysList = daysOfWeek.slice(today).concat(daysOfWeek.slice(0, today));
        setDaysList(updatedDaysList);

        const currentDay = moment().format('dddd');
        const currentIndex = updatedDaysList.indexOf(currentDay);
        if (currentIndex !== -1) {
            const formattedDate = getFormattedDate(currentIndex);
            handleSelectDay(currentDay, formattedDate);
        }
    };

    const handleSelectDay = (day, date) => {
        const selectedDayOfWeeek = getDayOfWeek(date);
        setSelectedDay(day);
        setSelectedSlot(null);
        setSelectedDate(date);
        setSelectedDayOfWeek(selectedDayOfWeeek);
    };

    const handleSelectSlot = (slot) => {
        setSelectedSlot(slot);
    };

    const getFormattedDate = (offset) => {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + offset);

        const month = months[currentDate.getMonth()];
        const dayOfMonth = currentDate.getDate().toString().padStart(2, '0');
        const year = currentDate.getFullYear();

        return `${month} ${dayOfMonth}, ${year}`;
    };

    const getDayOfWeek = (date) => {
        const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const selectedDatee = new Date(date);
        return weekdays[selectedDatee.getDay()];
    };

    const getFormattedDisplayDate = (offset) => {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + offset);

        const month = months[currentDate.getMonth()];
        const dayOfMonth = currentDate.getDate();
        const dayOfWeek = getDayOfWeek(currentDate);

        return `${month} ${dayOfMonth} ${dayOfWeek}`;
    };

    const renderDayButton = (day, index) => {
        const formattedDisplayDate = getFormattedDisplayDate(index);
        const formattedDate = getFormattedDate(index);

        return (
            <div
                key={day}
                className={`dayButton ${selectedDay === day && 'selectedDayButton'}`}
                onClick={() => handleSelectDay(day, formattedDate)}
            >
                <span className={`dayOfWeekText ${selectedDay === day && 'selecteddayOfWeekText'}`}>{formattedDisplayDate.split(' ')[2]}</span>
                <span className={`dayOfWeekText ${selectedDay === day && 'selecteddayOfWeekText'}`}>{formattedDate}</span>
            </div>
        );
    };

    const convertDayNameToNumber = (dayName) => {
        const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        return daysOfWeek.indexOf(dayName);
    }


    const renderSlotsList = () => {
        const slotsData = type_of === 'online' ? onlineSchedules : offlineSchedules;

        const currentDate = new Date();
        const liveDayOfWeek = currentDate.getDay();

        if (slotsData != null) {
            const slots = slotsData[selectedDay] || [];

            const alreadyBookedSlots = alreadyTimeBookedSlot;

            if (slots.length > 0) {
                return (
                    <div className="slots_list">
                        {slots.map((item) => {
                            const isSlotBooked = alreadyBookedSlots.some((slot) => {
                                return slot.day === selectedDayOfWeek && slot.date === selectedDate && slot.time === item;
                            });

                            const [timeString, period] = item.split(' ');
                            const [slotHour, slotMinute] = timeString.split(':').map(Number);

                            let hour24 = slotHour;
                            if (period) {
                                if (period.toLowerCase() === 'pm' && slotHour < 12) {
                                    hour24 = slotHour + 12;
                                }
                            }

                            const slotTime = new Date(currentDate);
                            slotTime.setHours(hour24, slotMinute, 0, 0);

                            const dayNumber = convertDayNameToNumber(selectedDay);
                            const isSlotInPast = liveDayOfWeek === dayNumber && slotTime < currentDate;

                            return (
                                <div
                                    key={item}
                                    onClick={() => {
                                        if (!isSlotBooked && !isSlotInPast) {
                                            handleSelectSlot(item);
                                        }
                                        else if (isSlotInPast) {
                                            alert('Please select upcoming time slot')
                                        }
                                        else {
                                            alert('This time slot is already booked.')
                                        }
                                    }}
                                    className={`slotbutton ${selectedSlot === item ? 'selectedSlotButton' : ''
                                        } ${isSlotBooked ? 'bookedSlotButton' : ''} ${isSlotInPast ? 'pastSlotButton' : ''}`}
                                >
                                    <span
                                        className={`slotText ${selectedSlot === item ? 'selectedSlotText' : ''
                                            } ${isSlotBooked ? 'bookedSlotText' : ''} ${isSlotInPast ? 'pastSlotText' : ''}`}
                                    >
                                        {item}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                );
            } else {
                return (
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 10 }}>
                        <span style={{ marginTop: 15, color: 'black' }}>No Slots Available</span>
                    </div>
                );
            }
        } else {
            return (
                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 10 }}>
                    <span style={{ marginTop: 15, color: 'black' }}>No Slots Available</span>
                </div>
            );
        }
    };



    const Reschedule_appointment = async () => {
        try {
            if (cancel_app == false) {
                alert("Your appointment's time is less than 24 hours. You can reschedule appointment before 24 hours.")
            }
            else {
                if (selectedSlot == null || selectedSlot == '') {
                    alert("Please select time for appoitment.")
                }
                else {
                    setSpinner(true)

                    let user_id = userid
                    let resecdule_reason = rescedule_reason
                    let time = selectedSlot
                    let day = selectedDayOfWeek
                    let date = selectedDate

                    // Date And Time For Notification Date 
                    const aa = moment(new Date()).format("YYYY-MM-DD hh:mm A");
                    const split = aa.split(' ')
                    const notification_date = split[0]
                    const timee = split[1]
                    const am_pm = split[2]
                    const notification_time = timee + "" + am_pm
                    //////////////////////////////

                    const type = "reschedule"
                    let doctor_id = doctorid

                    let user_name;
                    let doctor_name;
                    let status;

                    if (role == 'user') {
                        user_name = username
                        doctor_name = doctor_nam
                        status = 'request'
                    }
                    else {
                        user_name = user_nam
                        doctor_name = username
                        status = 'pending'
                    }

                    let result = await fetch(Connection + "reschedule_appointment_1", {
                        method: 'post',
                        body: JSON.stringify({ user_id, appointment_id, resecdule_reason, time, day, date, notification_time, type, doctor_id, notification_date, user_name, doctor_name, status }),
                        headers: {
                            'Authorization': localStorage.getItem("JWT_TOKEN"),
                            "Content-Type": 'application/json'
                        }
                    })

                    const response = await result.json();
                    const record4 = response.response;

                    if (record4 === 'Token verification failed') {
                        console.log(record4);
                        alert("Something went wrong.");
                    }
                    else if (record4 == "success") {
                        handleShowDialog()
                        app_notification()
                        web_notification()
                    }
                    else {
                        alert("Something went wrong.");
                    }
                    setSpinner(false)
                }
            }
        } catch (error) {
            console.error('Error:', error);
            setSpinner(false)
            alert("Something went wrong.");
        }
    }


    const app_notification = async () => {
        let to = fcm_token;
        console.log("To is => ", to)
        fetch('https://fcm.googleapis.com/fcm/send', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'key= AAAAaCD4Bcc:APA91bETamRJX5KYdE6sCHUzquj_DpmfqiqH3ogXH1K1m1SH5U5_60OPWrPP5hHMyq_1pQcUN2WMWAPpCOzFDJJ4lDt6X3IDNwLuohzTB8ja3EDGM-FDTePfmCEYpFTsUoH-wguvF6Ux'//cloud server key
            },
            body: JSON.stringify({
                "to": to,
                "priority": "high",
                "notification": {
                    "title": "Mas Clinicas",
                    "body": username + " has rescheduled Appointment with you",
                    "sound": 'default',
                    "icon": "myicon",
                }
            }
            )
        }).then(res => res.json())
            .then(resjson => console.log("test", resjson))
            .catch(err => console.log("error =>", err))
    }


    const web_notification = async () => {
        let to = web_fcm;
        console.log("To is => ", to)
        fetch('https://fcm.googleapis.com/fcm/send', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'key= AAAAPzWsmh8:APA91bGwGLlejpat2ndQPc3708f5KqvTPRFm9oZ7RQyBB9khWgUn_2JFZJdIf5Lta7dS2M9wTj_OYaxxmZ3FRVckZGoDJpEWyUC81G1hJliAhiKUGaZJS0P3cP-T_Z5VwGKPQzNsk_75'//cloud server key
            },
            body: JSON.stringify({
                "to": to,
                "priority": "high",
                "notification": {
                    "title": "Mas Clinicas",
                    "body": username + " has rescheduled Appointment with you",
                    "sound": 'default',
                    "icon": "myicon",
                }
            }
            )
        }).then(res => res.json())
            .then(resjson => console.log("test", resjson))
            .catch(err => console.log("error =>", err))
    }


    return (
        <div className="modalBackground">

            <div className="modalContainer">
                <div className="titleCloseBtn">
                    <h2>Reschedule Appointment</h2>
                    <button onClick={() => { handlereschecalend(false); }}>X</button>
                </div>

                <hr style={{ marginTop: 15, marginBottom: 15 }} />


                <div className="title">
                    <h3 style={{ color: 'gray', fontWeight: 'normal' }}>Select Date and Time</h3>
                </div>

                <div className="daysContainer">
                    {daysList.map((day, index) => renderDayButton(day, index))}
                </div>

                <h3 style={{ color: 'gray', fontWeight: 'normal', fontSize: 16, marginTop: 5 }}>List of Schedule</h3>

                {skalton == true ?
                    <SkeletonTheme baseColor="lightgray" highlightColor="#bebebe">
                        <div className='all_time_slot'>
                            <Skeleton width={100} height={35} style={{ marginLeft: 5, marginRight: 5, marginTop: 10 }} />
                            <Skeleton width={100} height={35} style={{ marginLeft: 5, marginRight: 5, marginTop: 10 }} />
                            <Skeleton width={100} height={35} style={{ marginLeft: 5, marginRight: 5, marginTop: 10 }} />
                            <Skeleton width={100} height={35} style={{ marginLeft: 5, marginRight: 5, marginTop: 10 }} />
                            <Skeleton width={100} height={35} style={{ marginLeft: 5, marginRight: 5, marginTop: 10 }} />
                            <Skeleton width={100} height={35} style={{ marginLeft: 5, marginRight: 5, marginTop: 10 }} />
                            <Skeleton width={100} height={35} style={{ marginLeft: 5, marginRight: 5, marginTop: 10 }} />
                            <Skeleton width={100} height={35} style={{ marginLeft: 5, marginRight: 5, marginTop: 10 }} />
                            <Skeleton width={100} height={35} style={{ marginLeft: 5, marginRight: 5, marginTop: 10 }} />
                            <Skeleton width={100} height={35} style={{ marginLeft: 5, marginRight: 5, marginTop: 10 }} />
                            <Skeleton width={100} height={35} style={{ marginLeft: 5, marginRight: 5, marginTop: 10 }} />
                            <Skeleton width={100} height={35} style={{ marginLeft: 5, marginRight: 5, marginTop: 10 }} />
                        </div>
                    </SkeletonTheme>
                    :
                    <>
                        {selectedDay && (
                            <div style={{ marginTop: 5, marginBottom: 10 }}>
                                {renderSlotsList()}
                            </div>
                        )}
                    </>
                }



                <div onClick={() => Reschedule_appointment()} className='book_btn'>
                    <p>Reschedule Appointment</p>
                </div>
            </div>


            <Dialog className='freeapoint_Dialog' open={showDialog}>
                <div className='done_view'>
                    <CheckIcon />
                </div>

                <DialogTitle className='Consult_dialog_toptitl'>
                    <h4>Reschedulling Appointment Success!</h4>
                    {/* <h5> successfully booked!</h5> */}
                </DialogTitle>

                <DialogActions className='freeapoint_Ok_nd_Close'>
                    <Button onClick={handleCloseDialog} className='freeapoint_Ok'>DONE</Button>
                </DialogActions>

                <ToastContainer />
            </Dialog>



            {spinner && (
                <div style={{ width: '100%', height: '100%', backgroundColor: 'rgba(2, 2, 2, 0.8)', position: 'absolute', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <div style={{ display: 'flex', width: '30%', backgroundColor: 'white', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: '30px', boxShadow: '0 1px 5px rgba(0, 0, 0, 0.2)', borderRadius: '6px', }}>
                        <PulseLoader
                            color={"#874DAF"}
                            loading={spinner}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                        <p style={{ fontSize: '16px', color: '#874DAF', fontWeight: 'bold', marginLeft: '15px' }}>
                            Progressing your request
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Rescedule_appointment_calendar;

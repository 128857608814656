import React, { useState, useEffect, Component, useRef } from 'react'
import './Chat.css';
import { Link, parsePath, useParams, useNavigate, useLocation } from "react-router-dom"
import Connection from "../../connection";
import { user_profile, doc_profile, chat_image, chat_pdf } from '../../Image_connection';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import fileDownload from 'js-file-download';
import moment from 'moment';
import FadeLoader from "react-spinners/FadeLoader";
import SyncLoader from "react-spinners/SyncLoader";
import ClipLoader from "react-spinners/ClipLoader";
import PhotoCameraBackIcon from '@mui/icons-material/PhotoCameraBack';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SendIcon from '@mui/icons-material/Send';
import VideocamIcon from '@mui/icons-material/Videocam';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import No_Messages from '../../assets/No_Messages.png';

export default function Chat() {
  const navigate = useNavigate();
  const jwt_tokens = localStorage.getItem("JWT_TOKEN");

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const check_chat = searchParams.get('check_chat');

  const timerRef = useRef(null);
  const chatWindowRef = useRef(null);

  const [all_Chat, setAll_Chat] = useState([]);
  const [save_Chat, setSave_Chat] = useState([]);
  const [existingchat, setExistingchat] = useState([]);

  const [userId, setUserId] = useState('');
  const [user_name, setUser_name] = useState('');
  const [sender_image, setSender_image] = useState(null);
  const [profile1, setProfile1] = useState(null);
  const [role, setRole] = useState('');
  const [userfcm_token, setUserfcm_token] = useState('');
  const [userweb_fcm, setUserweb_fcm] = useState('');
  const [useronline, setUseronline] = useState('');

  const [checkid, setCheckid] = useState(true);


  const [activeItem, setActiveItem] = useState(null);
  const [chat_iiid, setChat_iiid] = useState(null);
  const [receiver_id, setReceiver_id] = useState(null);
  const [sender_id, setSender_id] = useState(null);
  const [chat_name, setChat_name] = useState(null);
  const [receiver_image, setReceiver_image] = useState(null);
  const [fcm_token, setFcm_token] = useState(null);
  const [web_fcm, setWeb_fcm] = useState(null);
  const [active, setActive] = useState(null);

  // const [new_message, setNew_message] = useState(null);
  const [new_messag_id, setNew_messag_id] = useState(null);

  const [message, setMessage] = useState('');
  const [responseMessage, setResponseMessage] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isrommload, setIsrommload] = useState(true);
  const [sendLoad, setSendLoad] = useState(false);

  useEffect(() => {
    const auth = localStorage.getItem("customer");
    const parsed = JSON.parse(auth);
    const id = parsed[0].id;
    const user_name = parsed[0].name;
    const rolee = parsed[0].role;
    const userfcm_token = parsed[0].fcm_token;
    const userweb_fcm = parsed[0].web_fcm;
    const useronline = parsed[0].online;

    let profilee1 = parsed[0].profile;
    setProfile1(profilee1)
    if (profilee1 == null) {
      setSender_image(null)
    }
    else {
      if (rolee == 'user') {
        setSender_image(user_profile + profilee1)
      }
      else {
        setSender_image(doc_profile + profilee1)
      }
    }

    setUserId(id)
    setUser_name(user_name)
    setRole(rolee)
    setUserfcm_token(userfcm_token)
    setUserweb_fcm(userweb_fcm)
    setUseronline(useronline)

    AllChat(id)


    if (check_chat == 'do_chat') {
      const receiver_id = searchParams.get('receiver_id');

      get_chat__iiid_using_receiver__id_and_sender__id(id, receiver_id);
      console.log('chat id exsit ni karti ')
    }
  }, [check_chat])


  // Chat Card Screen 
  const AllChat = async (id) => {
    try {
      let result = await fetch(Connection + `all_chat/${id}`, {
        headers: {
          'Authorization': jwt_tokens,
        }
      })
      result = await result.json()
      let record = result.response

      if (record === 'Token verification failed') {
        console.log(record);
      }
      else if (record === 'fail') {
        console.log(record);
      }
      else {
        setAll_Chat(record)
        setSave_Chat(record)
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error:', error);
      setIsLoading(false);
    }
  }

  const Serach_Chat = (e) => {
    const name = e ? e.target.value : '';
    console.log("name..", name)

    if (name == "") {
      setAll_Chat(save_Chat)
    }
    else {
      const results = save_Chat.filter(item =>
        item.chat_name.toLowerCase().includes(name.toLowerCase())
      );

      setAll_Chat(results)
    };

  };


  const handleItemClick = ({ chat_iiid, receiver_id, sender_id, chat_name, receivr_img, fcm_token, web_fcm, active, iid }) => {
    clearInterval(timerRef.current);
    console.log("...........", chat_iiid)
    console.log("...........", receiver_id)
    console.log("...........", sender_id)
    console.log("...........", chat_name)
    console.log("...........", receivr_img)
    console.log("...........", fcm_token)
    console.log("...........", web_fcm)
    console.log("...........", active)
    setExistingchat([])

    setIsrommload(true)

    setActiveItem(chat_iiid);
    setChat_iiid(chat_iiid);
    setReceiver_id(receiver_id);
    setSender_id(sender_id);
    setChat_name(chat_name);
    setReceiver_image(receivr_img);
    setNew_messag_id(iid)
    setFcm_token(fcm_token);
    setWeb_fcm(web_fcm);
    setActive(active);

    console.log('chat id exsit karti hai')

    const timerId = setInterval(() => {
      getexistingchat(chat_iiid);
    }, 1000);
    timerRef.current = timerId;
  };


  const getexistingchat = async (chat_iiid) => {
    try {
      let result = await fetch(Connection + `get_chat_messages_by_idd/${chat_iiid}`, {
        headers: {
          'Authorization': jwt_tokens,
        }
      })
      result = await result.json()
      let record = result.response

      if (record === 'Token verification failed') {
        console.log(record);
      }
      else if (record === 'fail') {
        console.log(record);
      }
      else {
        setExistingchat(record)
        // chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
      }
      setIsrommload(false)
    } catch (error) {
      console.error('Error:', error);
      setIsrommload(false);
    }
  }


  const get_chat__iiid_using_receiver__id_and_sender__id = async (id, receiver_id) => {
    try {
      setIsrommload(true)

      const recivr_img = searchParams.get('receiver_image');
      const chat_image = searchParams.get('chat_image');
      const chat_name = searchParams.get('chat_name');
      const fcm_token = searchParams.get('fcm_token');
      const web_fcm = searchParams.get('web_fcm');
      const active = searchParams.get('active');
      const sender_name = searchParams.get('sender_name');
      const receiver_name = searchParams.get('receiver_name');
      const chat_name_disable = searchParams.get('chat_name_disable');

      const sender_id = id
      let result = await fetch(Connection + "get_existing_chat_for_getting_chat_iiid", {
        method: 'post',
        body: JSON.stringify({ sender_id, receiver_id }),
        headers: {
          'Authorization': jwt_tokens,
          "Content-Type": 'application/json'
        }
      })
      const response = await result.json();
      let resjson = response.response

      if (resjson === 'Token verification failed') {
        console.log(resjson);

        setCheckid(false)

        setActiveItem();
        setReceiver_id(receiver_id);
        setSender_id(id);
        setChat_name(chat_name);
        setReceiver_image(recivr_img);
        setFcm_token(fcm_token);
        setWeb_fcm(web_fcm);
        setActive(active);

        const timerId = setInterval(() => {
          getexistingchat(chat_iiid);
        }, 1000);
        timerRef.current = timerId;
      }
      else if (resjson === "fail") {
        setCheckid(false)

        setActiveItem();
        setReceiver_id(receiver_id);
        setSender_id(id);
        setChat_name(chat_name);
        setReceiver_image(recivr_img);
        setFcm_token(fcm_token);
        setWeb_fcm(web_fcm);
        setActive(active);

        const timerId = setInterval(() => {
          getexistingchat(chat_iiid);
        }, 1000);
        timerRef.current = timerId;
      }
      else {
        let chat_iiid = resjson[0].id
        setExistingchat([])

        setActiveItem(chat_iiid);
        setChat_iiid(chat_iiid);
        setReceiver_id(receiver_id);
        setSender_id(id);
        setChat_name(chat_name);
        setReceiver_image(recivr_img);
        setFcm_token(fcm_token);
        setWeb_fcm(web_fcm);
        setActive(active);

        setCheckid(true)

        const timerId = setInterval(() => {
          getexistingchat(chat_iiid);
        }, 1000);
        timerRef.current = timerId;
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleDownload = (finalimage) => {
    // Decode the URL
    const url = decodeURIComponent(finalimage);

    // Use the fetch API to download the file
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        // Use the fileDownload function to download the file
        fileDownload(blob, 'filename.pdf');
      })
      .catch(error => console.error(error));
  }










  // For image select
  function fileSelectedHandler(event) {
    const imageSource1 = event.target.files[0];
    console.log("..file..", imageSource1)

    setTimeout(() => {
      send_first_message("image", imageSource1)
    }, 100);
  }

  // For Pdf select
  const handleFileInputChange = (event) => {
    const imageSource1 = event.target.files[0];
    console.log("pdf file", imageSource1)

    setTimeout(() => {
      send_first_message("file", imageSource1)
    }, 100);
  };
  ////////////////////////////////



  // Main Function for sending new message and send message in existing chat
  const send_first_message = async (tye_of_message, imageSource1) => {
    try {
      setSendLoad(true)
      setMessage('')
      setResponseMessage(false)
      console.log("tye_of_message", tye_of_message)

      const type_of_mesage = tye_of_message
      const type_of_message = type_of_mesage.replace(/[']/g, '')


      // getting time
      const aa = moment(new Date()).format("YYYY-MM-DD hh:mm A");
      const split = aa.split(' ')
      const time = split[1]
      const am_pm = split[2]
      const final_time = time + "" + am_pm


      const newImage = null
      const type = null
      const type_name = "type_name"

      if (type_of_message == "text") {

        if (checkid == true) {
          const chat_id = chat_iiid
          const sender_id = userId
          const time = final_time

          console.log("send_new_message")
          let result = await fetch(Connection + "send_new_message", {
            method: 'post',
            body: JSON.stringify({ chat_id, sender_id, receiver_id, message, time, new: '1' }),
            headers: {
              'Authorization': jwt_tokens,
              "Content-Type": 'application/json'
            }
          })

          result = await result.json()
          let record = result.response

          if (record === 'Token verification failed') {
            console.log(record);
            setSendLoad(false)
            alert("Something went wrong")
          }
          else if (record === 'fail') {
            console.log(record);
            setSendLoad(false)
            alert("Something went wrong")
          }
          else {
            app_notification()
            web_notification()
            setResponseMessage(true)
            setSendLoad(false)
          }
        }
        else {
          clearInterval(timerRef.current);

          const sender_id = userId
          const time = final_time
          const sender_name = user_name
          const receiver_name = chat_name

          console.log("send_first_new_message_a")
          let result = await fetch(Connection + "send_first_new_message_a", {
            method: 'post',
            body: JSON.stringify({ sender_id, receiver_id, message, time, receiver_name, sender_name }),
            headers: {
              'Authorization': jwt_tokens,
              "Content-Type": 'application/json'
            }
          })

          result = await result.json()
          let record = result.response

          if (record === 'Token verification failed') {
            console.log(record);
            setSendLoad(false)
            alert("Something went wrong")
          }
          else if (record === 'fail') {
            console.log(record);
            setSendLoad(false)
            alert("Something went wrong")
          }
          else {
            app_notification()
            web_notification()
            setResponseMessage(true)
            setCheckid(true)
            setChat_iiid(record)
            setSendLoad(false)

            const timerId = setInterval(() => {
              getexistingchat(record);
            }, 1000);
            timerRef.current = timerId;
          }
        }

      }

      else {

        if (checkid == true) {
          const chat_id = chat_iiid
          const sender_id = userId
          const time = final_time
          const chat_rom = imageSource1

          const form_data = new FormData();

          form_data.append("chat_id", chat_id);
          form_data.append("sender_id", sender_id);
          form_data.append("receiver_id", receiver_id);
          form_data.append("chat_rom", chat_rom);
          form_data.append("time", time);

          console.log("send_new_message_with_image")
          const api = Connection + "send_new_message_with_image"
          const result = await fetch(api, {
            method: "POST",
            body: form_data,
          })
            //  console.log("result123",result)
            .then((result) => result.json())
            .then((result) => {
              console.log("image send result", result);
              app_notification()
              web_notification()
              setResponseMessage(true)
              setSendLoad(false)
            })
            .catch((error) => {
              console.error('image send error', error);
              setSendLoad(false)
              alert("Something went wrong")
            })
        }
        else {
          setSendLoad(false)
          alert("Something went wrong")
          // if (type_of_message == "image") {
          //   newImage = {
          //     uri: imageSource1,
          //     name: "my_photo.jpg",
          //     type: "image/jpg",
          //   };
          //   type = "image"
          // }
          // else if (type_of_message == "file") {
          //   newImage = {
          //     //  uri: filepathforchat,
          //     name: "my_file.pdf",
          //     type: "file/pdf",
          //   };
          //   type = "file"
          //   type_name = type_name
          // }


          // const sender_id = userId
          // const time = final_time
          // const image = newImage

          // console.log("send_first_new_message_with_image")
          // let result = await fetch(Connection + "send_first_new_message_with_image", {
          //   method: 'post',
          //   body: JSON.stringify({ sender_id, receiver_id, image, type, type_name, time }),
          //   headers: {
          //     "Content-Type": 'application/json'
          //   }
          // })

          // result = await result.json()
          // console.log("result123", result)

          // if (result == "fail") {
          //   setSendLoad(false)
          //   alert("Something went wrong")
          // }
          // else {
          //   app_notification()
          //   web_notification()
          //   setResponseMessage(true)
          //   setCheckid(true)
          //   setChat_iiid(result)
          //   setSendLoad(false)
          // }
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }
  ////////////////////////


  const app_notification = async () => {
    let to = fcm_token;
    console.log("To is fcm_token=> ", to)

    fetch('https://fcm.googleapis.com/fcm/send', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'key= AAAAaCD4Bcc:APA91bETamRJX5KYdE6sCHUzquj_DpmfqiqH3ogXH1K1m1SH5U5_60OPWrPP5hHMyq_1pQcUN2WMWAPpCOzFDJJ4lDt6X3IDNwLuohzTB8ja3EDGM-FDTePfmCEYpFTsUoH-wguvF6Ux'//cloud server key
      },
      body: JSON.stringify({
        "to": to,
        "priority": "high",
        content_available: true,
        "notification": {
          "title": user_name,
          "body": message,
          "sound": 'default',
          "android_channel_id": 'Default Channel',
          "icon": sender_image,
        },
        data: {
          "chatroom": 'chatroom',
          "chat_iiid": chat_iiid,
          "receiver_id": sender_id,
          "sender_id": receiver_id,
          "chat_name": user_name,
          "receiver_image": sender_image,
          "chat_image": profile1,
          "fcm_token": userfcm_token,
          "web_fcm": userweb_fcm,
          "active": useronline,
          "new_messag_id": new_messag_id,
          "chat_name_disable": user_name,
        },
      })
    }).then(res => res.json())
      .then(resjson => console.log("test", resjson))
      .catch(err => console.log("error =>", err))
  }


  const web_notification = async () => {
    let to = web_fcm;
    console.log("To is fcm_token=> ", to)

    fetch('https://fcm.googleapis.com/fcm/send', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'key= AAAAPzWsmh8:APA91bGwGLlejpat2ndQPc3708f5KqvTPRFm9oZ7RQyBB9khWgUn_2JFZJdIf5Lta7dS2M9wTj_OYaxxmZ3FRVckZGoDJpEWyUC81G1hJliAhiKUGaZJS0P3cP-T_Z5VwGKPQzNsk_75'//cloud server key
      },
      body: JSON.stringify({
        "to": to,
        "priority": "high",
        content_available: true,
        "notification": {
          "title": user_name,
          "body": message,
          "sound": 'default',
          "android_channel_id": 'Default Channel',
          "icon": sender_image,
        },
        data: {
          "chatroom": 'chatroom',
          "chat_iiid": chat_iiid,
          "receiver_id": sender_id,
          "sender_id": receiver_id,
          "chat_name": user_name,
          "receiver_image": sender_image,
          "chat_image": profile1,
          "fcm_token": userfcm_token,
          "web_fcm": userweb_fcm,
          "active": useronline,
          "new_messag_id": new_messag_id,
          "chat_name_disable": user_name,
        },
      })
    }).then(res => res.json())
      .then(resjson => console.log("test", resjson))
      .catch(err => console.log("error =>", err))
  }



  const agora_notification = async () => {
    // console.log("sss", user_fcm_token)
    // console.log("sss", role)

    if ((fcm_token === null || fcm_token === '') && (web_fcm === null || web_fcm === '')) {
      if (role == 'doctor') {
        alert("This user is currently unavailable.");
      }
      else {
        alert("This doctor is currently unavailable.");
      }
    }
    else {
      const call_check = 'do_call'
      const url = `/AgoraCall?call_check=${call_check}&fcm_token=${fcm_token}&web_fcm=${web_fcm}`;
      navigate(url);
    }
  };


  return (
    <div className={`chat_screen_container ${activeItem ? 'active-item' : ''}`}>

      {/* Chat Card Screen */}
      <div className="chat_list_container">
        <div className="chat_list">
          <div className='Search_Chat'>
            <SearchIcon style={{ color: '#874daf' }} />
            <input className="searchchatinpu" placeholder="Search Chat" type='search' onChange={Serach_Chat} />
          </div>
          {isLoading ? (
            <div className='loader_doctor_list'>
              <FadeLoader
                color={"#874daf"}
                loading={isLoading}
                size={30}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
              <p>Loading ...</p>
            </div>
          )
            :
            (
              <>
                {all_Chat != "" ?
                  <>
                    {all_Chat.map((row) => {
                      const message = row.chat_message;
                      const splitmessage = message.split(".");

                      const chat_imge = row.chat_image;
                      let path;
                      if (userId == row.receiver_id) {
                        path = doc_profile + chat_imge
                      }
                      else {
                        path = user_profile + chat_imge
                      }

                      return userId == row.receiver_id ? (
                        <div key={row.id} className={`chat_list_item ${activeItem === row.id ? "active" : ""}`}
                          onClick={() => handleItemClick({ chat_iiid: row.id, receiver_id: row.sender_id, sender_id: row.sender_id, chat_name: row.chat_name, receivr_img: path, fcm_token: row.fcm_token, web_fcm: row.web_fcm, active: row.active, iid: row.iid })}>
                          <div className="chat_list_item_image_container">
                            {row.chat_image == null ?
                              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRt_NZykul07nU3cliFuRZQr4_q-gOdkRTmRA&usqp=CAU" alt="avatar" />
                              :
                              <>
                                <img src={path} alt="profile-image" />
                                {row.active == 'online' ?
                                  <div className='dotforonline'>
                                    <div style={{ width: 10, height: 10, borderRadius: 100, backgroundColor: 'lightgreen' }}></div>
                                  </div>
                                  :
                                  <div className='dotforonline'>
                                    <div style={{ width: 10, height: 10, borderRadius: 100, backgroundColor: 'lightgray' }}></div>
                                  </div>
                                }
                              </>
                            }
                          </div>

                          <div className="chat_list_item_details">
                            <h3>{row.chat_name}</h3>
                            {splitmessage[1] != "jpg" && splitmessage[1] != "pdf" &&
                              <p>{row.chat_message}</p>
                            }

                            {splitmessage[1] == "pdf" &&
                              <div className='file_pdf_view'>
                                <InsertDriveFileIcon className='icon' />
                                <p>File</p>
                              </div>
                            }

                            {splitmessage[1] == "jpg" &&
                              <div className='file_pdf_view'>
                                <InsertPhotoIcon className='icon' />
                                <p>Photo</p>
                              </div>
                            }
                            <span>{row.time}</span>
                          </div>
                        </div>
                      )
                        :
                        (
                          <div key={row.id} className={`chat_list_item ${activeItem === row.id ? "active" : ""}`}
                            onClick={() => handleItemClick({ chat_iiid: row.id, receiver_id: row.receiver_id, sender_id: row.sender_id, chat_name: row.chat_name, receivr_img: path, fcm_token: row.fcm_token, web_fcm: row.web_fcm, active: row.active, iid: row.iid })}>

                            <div className="chat_list_item_image_container">
                              {row.chat_image == null ? (
                                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRt_NZykul07nU3cliFuRZQr4_q-gOdkRTmRA&usqp=CAU" alt="avatar" />)
                                :
                                (
                                  <img src={path} alt="profile-image" />
                                )}
                            </div>

                            <div className="chat_list_item_details">
                              <h3>{row.chat_name}</h3>
                              {splitmessage[1] != "jpg" && splitmessage[1] != "pdf" &&
                                <p>{row.chat_message}</p>
                              }

                              {splitmessage[1] == "pdf" &&
                                <div className='file_pdf_view'>
                                  <InsertDriveFileIcon className='icon' />
                                  <p>File</p>
                                </div>
                              }

                              {splitmessage[1] == "jpg" &&
                                <div className='file_pdf_view'>
                                  <InsertPhotoIcon className='icon' />
                                  <p>Photo</p>
                                </div>
                              }
                              <span>{row.time}</span>
                            </div>
                          </div>
                        );
                    })}
                  </>
                  :
                  <div className='loader_doctor_list'>
                    <p>No Chat Record</p>
                  </div>
                }
              </>
            )}

        </div>
      </div>
      {/* End Chat Card */}


      {/* Chat Room Screen */}
      {activeItem === null ?
        <div className="chat_room_no_active_container">
          <img src={No_Messages} alt="avatar" />
          {/* <img src="https://gsuite-img.hosting.kr/wp-content/uploads/2020/11/03134224/1.-%EB%A9%94%EC%9D%B8-%EC%9D%B4%EB%AF%B8%EC%A7%80.jpg" alt="avatar" /> */}
        </div>
        :
        <div className="chat_room_container">

          <div className="chat_room_header">
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <ArrowBackIcon onClick={() => setActiveItem(null)} className='iconforbackinshort' />
              <div className="chat-room-header-image-container">
                {receiver_image ?
                  <img src={receiver_image} alt="profile-image" />
                  :
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRt_NZykul07nU3cliFuRZQr4_q-gOdkRTmRA&usqp=CAU" alt="avatar" />
                }
              </div>
              <div className="chat-room-header-details">
                <h3>{chat_name}</h3>
                <span>Last seen {active}</span>
              </div>
            </div>

            <div className='romcaldiv'>
              <button className='romcalinsdicon' onClick={() => agora_notification()}>
                <VideocamIcon className='finlyicon' />
              </button>
            </div>
          </div>


          {isrommload ? (
            <div className='loader_chat_room'>
              <SyncLoader
                color={"#874daf"}
                loading={isrommload}
                size={20}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          )
            :
            (
              <div ref={chatWindowRef} className="chat_room_messages_container">
                {existingchat.map((row) => {
                  const message = row.message
                  const splitmessage = message.split(".")

                  if (splitmessage[1] != "jpg" && splitmessage[1] != "pdf" && splitmessage[1] != "png" && splitmessage[1] != "jpeg") {
                    return row.sender_id != userId ?
                      <div className="chat_room_left_message incoming">
                        <div className="chat_room_message_image_container">
                          {receiver_image ?
                            <img src={receiver_image} alt="profile-image" />
                            :
                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRt_NZykul07nU3cliFuRZQr4_q-gOdkRTmRA&usqp=CAU" alt="avatar" />
                          }
                        </div>

                        <div className="chat_room_left_message_details">
                          <p>{message}</p>
                          <span>{row.time}</span>
                        </div>
                      </div>
                      :
                      <div style={{ justifyContent: 'flex-end', display: 'flex', width: '97%' }}>
                        <div className="chat_room_right_message outgoing">
                          <div className="chat_room_right_message_details">
                            <p>{message}</p>
                            <span>{row.time}</span>
                          </div>
                        </div>
                      </div>
                  }
                  else if (splitmessage[1] == "jpg" || splitmessage[1] == "png" || splitmessage[1] == "jpeg") {
                    const finalimage = "https://masclinicas.com/masclinicas/Chat_Images/" + message;
                    return row.sender_id != userId ?
                      <div className="chat_room_left_message incoming">
                        <div className="chat_room_message_image_container">
                          {receiver_image ?
                            <img src={receiver_image} alt="profile-image" />
                            :
                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRt_NZykul07nU3cliFuRZQr4_q-gOdkRTmRA&usqp=CAU" alt="avatar" />
                          }
                        </div>

                        <div className="chat_room_left_message_details">
                          <div className="chat_room_message_image_views_zoom">
                            <TransformWrapper>
                              <TransformComponent>
                                <img src={chat_image + message} alt="profile-image" />
                              </TransformComponent>
                            </TransformWrapper>
                          </div>
                          <span>{row.time}</span>
                        </div>
                      </div>
                      :
                      <div style={{ justifyContent: 'flex-end', display: 'flex', width: '97%' }}>
                        <div className="chat_room_right_message outgoing">
                          <div className="chat_room_right_message_details">
                            <div className="chat_room_message_image_views_zoom">
                              <TransformWrapper>
                                <TransformComponent>
                                  <img src={chat_image + message} alt="profile-image" />
                                </TransformComponent>
                              </TransformWrapper>
                            </div>
                            <span>{row.time}</span>
                          </div>
                        </div>
                      </div>
                  }
                  else if (splitmessage[1] == "pdf") {
                    const finalimage = "https://masclinicas.com/masclinicas/Chat_Images/" + message;
                    return row.sender_id != userId ?
                      <div className="chat_room_left_message incoming">
                        <div className="chat_room_message_image_container">
                          {receiver_image ?
                            <img src={receiver_image} alt="profile-image" />
                            :
                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRt_NZykul07nU3cliFuRZQr4_q-gOdkRTmRA&usqp=CAU" alt="avatar" />
                          }
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <div onClick={handleDownload(chat_pdf + message)} className="chat_room_left_pdf_details">
                            <PictureAsPdfIcon />
                            <p>{message}</p>
                          </div>
                          <span>{row.time}</span>
                        </div>
                      </div>
                      :
                      <div style={{ justifyContent: 'flex-end', display: 'flex', width: '97%' }}>
                        <div className="chat_room_right_message outgoing">
                          <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'end' }}>
                            <Link onClick={handleDownload(chat_pdf + message)}>
                              <div className="chat_room_right_pdf_details">
                                <PictureAsPdfIcon />
                                <p>{message}</p>
                              </div>
                            </Link>
                            <span>{row.time}</span>
                          </div>
                        </div>
                      </div>
                  }
                  else {
                    // code to execute if both condition1 and condition2 are false
                    return null;
                  }
                })}

              </div>
            )}

          {/* <div className='circle_top_bottom'>
          <KeyboardArrowDownIcon/>
        </div> */}

          <div className="chat_room_input_container">
            {/* <div className="custom_file_input">
              <input type="file" onChange={fileSelectedHandler} />
              <PhotoCameraBackIcon />
          </div> */}
            {isrommload == true ?
              <div className="custom_file_input" id='unselectpdf'>
                <PictureAsPdfIcon />
              </div>
              :
              <div className="custom_file_input">
                <input type="file" accept=".pdf" onChange={handleFileInputChange} />
                <PictureAsPdfIcon />
              </div>
            }

            <input type="text" placeholder="Type a message" value={message} onChange={(e) => setMessage(e.target.value)} />
            {sendLoad ? (
              <button className='butnaftersendload'>
                <ClipLoader
                  color={"#FFFFFF"}
                  loading={sendLoad}
                  size={26}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </button>
            )
              :
              <>
                {isrommload == true || message == '' || responseMessage == false ?
                  <button className='bottun_send' id='unsend'>
                    <SendIcon />
                  </button>
                  :
                  <button className='bottun_send' id='send' onClick={() => { send_first_message("text") }}>
                    <SendIcon />
                  </button>
                }
              </>
            }
          </div>
        </div>
      }
      {/* End Chat Room */}

    </div>
  );
}
import React, { useState, useEffect, createContext, memo } from 'react'
import { Link } from 'react-router-dom';
import './MoreOptionsDropdown.css';

function MoreOptionsDropdown() {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [role, setRole] = useState('');

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    useEffect(() => {
        let user = localStorage.getItem('customer');
        if (user != null) {
            const parsed = JSON.parse(user);
            const getrole = parsed[0].role;
            setRole(getrole)
        }
    }, [])

    return (
        <div className='more-options-dropdown'>
            <button className='more-options-button' onClick={toggleDropdown}>
                Setting
            </button>
            {/* {dropdownOpen && ( */}


            {role == 'user' ?
                <div className='dropdown-content'>
                    <Link to='/Patient_Wallet'>Wallet</Link>
                    <Link to='/Refund'>Refund</Link>
                    {/* <Link to='/Add_Insurance'>Insurance</Link> */}
                    <Link to='/Delete_Account'>Delete</Link>
                </div>
                :
                <div className='dropdown-content'>
                    <Link to='/Doctor_Wallet'>Wallet</Link>
                    <Link to='/Update_Schedule'>Schedule</Link>
                    <Link to='/Clinic_Number'>Clinic</Link>
                    <Link to='/Insurance_fee'>Insurance</Link>
                    <Link to='/Delete_Account'>Delete</Link>
                </div>
            }
            {/* )} */}
        </div>
    );
}

export default MoreOptionsDropdown;
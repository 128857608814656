import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';
import 'firebase/auth'
import 'firebase/messaging';

var firebaseConfig = {
  apiKey: "AIzaSyDK_XbmZCeBxYWnclUYP4BGzHBMkGWavZY",
  authDomain: "mass-clinic.firebaseapp.com",
  projectId: "mass-clinic",
  storageBucket: "mass-clinic.appspot.com",
  messagingSenderId: "271483443743",
  appId: "1:271483443743:web:ab7610daa0970c8005d32a",
  measurementId: "G-N8MNKWCGVG"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp); // Initialize the messaging service

export { firebaseApp, messaging };
import React, { useState, useEffect, createContext, memo } from 'react'
import Connection from "./connection";
import './App.css';
import Navbar from './MyComponent/Navbar/Navbar';
import { firebaseApp, messaging } from './firebase';
import { onMessage } from 'firebase/messaging';
import { useToasts } from 'react-toast-notifications';
import { Link, parsePath, useNavigate } from "react-router-dom"
import audioFile from './assets/nokia.mp3';
import addNotification from 'react-push-notification';
import { jwtDecode } from "jwt-decode";

import Login from './pages/Login/Login';
import Roll_Screen from './pages/Login/Roll_Screen';
import Home from './pages/Home/Home';
import Patient_All_Appointment from './pages/Appointment/Patient_All_Appointment';
import Chat from './pages/Chat/Chat';
import Doctor_list from './pages/Doctor_list/Doctor_list';
import Doctor_Details from './pages/Doctor_Details/Doctor_Details';
import Doctor_by_category from './pages/Doctor_by_category/Doctor_by_category';
import Patient_Wallet from './pages/Patient_profile/Patient_Wallet';
import Refund from './pages/Patient_profile/Refund';
import Add_Insurance from './pages/Add_Insurance/Add_Insurance';

import Doctor_Home from './pages/doctor/Doctor_Home';
import Doctor_Wallet from './pages/Doctor_profile/Doctor_Wallet';
import Update_Schedule from './pages/Update/Update_Schedule';
import Clinic_Number from './pages/Doctor_profile/Clinic_Number';
import Insurance_fee from './pages/Doctor_profile/Insurance_fee';

import Delete_Account from './pages/Delete_Account/Delete_Account';

import AgoraCall from "./pages/AgoraCall/AgoraCall";

import Privatecom from './pages/Privatecom'



import { Routes, Route, Navigate } from "react-router-dom"

export const GlobalInfo = createContext()

function App() {
   const { addToast, removeToast, removeAllToasts } = useToasts();
   const navigate = useNavigate();

   const [role, setRole] = useState('');
   const [auth, setAuth] = useState('');

   const [insure_check, setInsure_check] = useState('');
   const [do_free_hisd, setDo_free_hisd] = useState('');
   const [do_status, setDo_status] = useState('');
   const [tokenExpire, setTokenExpire] = useState(false);


   const loginenter = () => {
      const auth = localStorage.getItem("customer");
      setAuth(auth)

      if (auth != null) {
         const parsed = JSON.parse(auth);
         const getrole = parsed[0].role;
         const id = parsed[0].id;
         setRole(getrole)

         const jwt_tokens = localStorage.getItem("JWT_TOKEN");
         if (jwt_tokens) {
            const decodedToken = jwtDecode(jwt_tokens);

            const currentTimestamp = Date.now() / 1000; // Convert milliseconds to seconds
            if (decodedToken.exp < currentTimestamp) {
               console.log("Token is expired");
               // setTokenExpire(true)
               refreshjwttoken(id)
            }
            else {
               checkinsurancehaveornot(id, jwt_tokens)
               checkdisable(id, jwt_tokens)
               console.log("Token is not expired");
            }
         }
         else {
            console.log("No Token");
            refreshjwttoken(id)
         }
      }
   }

   useEffect(() => {
      navigator.serviceWorker.addEventListener('message', (event) => {
         console.log('Received message from service worker:', event.data);
         if (event.data.type === 'navigate') {
            window.focus();
            const url = event.data.payload;
            showToast(url)
         }
      });

      onMessage(messaging, (payload) => {
         console.log('Message received. ', payload);

         let noti_name;
         if (payload.notification && payload.notification.title) {
            noti_name = payload.notification.title;
         }
         else if (payload.data && payload.data.title) {
            noti_name = payload.data.title;
         }

         if (noti_name === "Incoming Call") {
            showToast(payload)
         }
         else {
            const notification = new Notification(payload.notification.title, {
               body: payload.notification.body,
               icon: payload.notification.icon,
            });
            notification.onclick = () => {
               console.log('Notification clicked.');
            };
         }
      });

      loginenter()

   }, [addToast]);



   const showToast = (payload) => {
      const callerName = payload.data.username;
      const callertoken = payload.data.tokenA;
      const callingTone = new Audio(audioFile);
      callingTone.play();

      const { id } = addToast(
         <div>
            <strong>Incoming call from {callerName}</strong>
            <br />
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px', marginTop: 10 }}>
               <button
                  style={{ border: '#30a171 1px solid', backgroundColor: '#e3fcef', borderRadius: 8, padding: '5px 10px 5px 10px', color: '#30a171' }}
                  onClick={() => answerCall(payload, callingTone, callertoken)}
               >
                  Answer
               </button>
               <button
                  style={{ border: 'red 1px solid', backgroundColor: '#e3fcef', borderRadius: 8, padding: '5px 10px 5px 10px', color: 'red' }}
                  onClick={() => rejectCall(payload, id, callingTone)}
               >
                  Reject
               </button>
            </div>
         </div>,
         {
            appearance: 'success',
            autoDismiss: true,
            autoDismissTimeout: 30000,
            onDismiss: (id) => rejectCall(payload, id, callingTone),
         }
      );

      // Save or use the id as needed
      console.log('Toast ID:', id);
   };


   const answerCall = (payload, callingTone, callertoken) => {
      console.log('Answering call:', payload);

      callingTone.pause();
      callingTone.currentTime = 0;

      removeAllToasts()
      const url = `/AgoraCall?callertoken=${callertoken}`;
      navigate(url);
      // navigate("/AgoraCall")
      addToast('Call answered!', { appearance: 'success', autoDismiss: true });
   };

   const rejectCall = (payload, toastId, callingTone) => {
      console.log('Rejecting call:', toastId);

      callingTone.pause();
      callingTone.currentTime = 0;

      removeAllToasts()
      // removeToast(toastId);
      addToast('Call rejected!', { appearance: 'error', autoDismiss: true });
   };



   const refreshjwttoken = async (id) => {
      try {
         let result = await fetch(Connection + "Jwt_TokenGernate", {
            method: 'post',
            body: JSON.stringify({ id }),
            headers: {
               "Content-Type": 'application/json'
            }
         })
         result = await result.json()
         const record4 = result.response

         if (record4 === 'fail') {
            console.log('Record Fail');
         }
         else {
            const Token = result.token
            localStorage.setItem("JWT_TOKEN", (Token))
            checkinsurancehaveornot(id, Token)
            checkdisable(id, Token)
         }
      } catch (error) {
         console.error('Error:', error);
      }
   };


   const checkdisable = async (id, Token) => {
      try {
         const response = await fetch(Connection + `checkdisable_a/${id}`, {
            headers: {
               'Authorization': Token
            }
         });

         // if (!response.ok) {
         //    throw new Error('Token verification failed');
         // }

         const result = await response.json();
         const record4 = result.response

         if (record4 === 'Token verification failed') {
            console.log('Token verification failed');
         }
         else if (record4 === 'fail') {
            console.log('Record Fail');
         }
         else {
            localStorage.setItem('customer', JSON.stringify(record4));
            const do_free_hisd = record4[0].free_hisd;
            const do_status = record4[0].status;
            setDo_free_hisd(do_free_hisd);
            setDo_status(do_status);
         }
      } catch (error) {
         console.error('Error:', error);
      }
   };


   const checkinsurancehaveornot = async (id, Token) => {
      try {
         const user_id = id
         let result = await fetch(Connection + "check_insurance", {
            method: 'post',
            body: JSON.stringify({ user_id }),
            headers: {
               'Authorization': Token,
               "Content-Type": 'application/json'
            }
         })
         result = await result.json()
         const record4 = result.response
         console.log(".,.,.CHECK INSURANCE,.,.", record4)

         if (record4 == 'Token verification failed') {
            console.log('Token verification failed');
         }
         else if (record4 == 'success') {
            const reco = result.data
            setInsure_check(reco)
         }
         else { }
      } catch (error) {
         console.error('Error:', error);
      }
   };


   return (

      <GlobalInfo.Provider value={{ loginenter: loginenter, do_free_hisd: do_free_hisd, do_status: do_status, insure_check: insure_check, tokenExpire: tokenExpire }}>
         {!auth ? (
            <Routes>
               <Route path='/Roll_Screen' element={<Roll_Screen />}></Route>
               <Route path='/login' element={<Login />}></Route>
               <Route path='/*' element={<Roll_Screen />}></Route>
            </Routes>
         ) : (
            <div className='topbar_page'>
               <Navbar />

               {role == 'user' ?
                  <Routes>
                     <Route element={<Privatecom />} >
                        <Route path='/AgoraCall' element={<AgoraCall />} ></Route>
                        <Route path='/' element={<Home />}></Route>
                        <Route path='/Patient_All_Appointment' element={<Patient_All_Appointment />}></Route>
                        <Route path='/Chat' element={<Chat />}></Route>
                        <Route path='/Doctor_list' element={<Doctor_list />}></Route>
                        <Route path='/Patient_Wallet' element={<Patient_Wallet />}></Route>
                        <Route path='/Refund' element={<Refund />}></Route>
                        <Route path='/Add_Insurance' element={<Add_Insurance />}></Route>
                        <Route path='/Delete_Account' element={<Delete_Account />}></Route>
                        <Route path='/Doctor_Details/:id' element={<Doctor_Details />}></Route>
                        <Route path='/Doctor_by_category/:category' element={<Doctor_by_category />}></Route>
                        <Route path='/*' element={<Navigate to={'/'} />}></Route>
                     </Route>
                  </Routes>
                  :
                  <Routes>
                     <Route element={<Privatecom />} >
                        <Route path='/AgoraCall' element={<AgoraCall />} ></Route>
                        <Route path='/' element={<Doctor_Home />}></Route>
                        <Route path='/Patient_All_Appointment' element={<Patient_All_Appointment />}></Route>
                        <Route path='/Doctor_Wallet' element={<Doctor_Wallet />}></Route>
                        <Route path='/Clinic_Number' element={<Clinic_Number />}></Route>
                        <Route path='/Insurance_fee' element={<Insurance_fee />}></Route>
                        <Route path='/Update_Schedule' element={<Update_Schedule />}></Route>
                        <Route path='/Delete_Account' element={<Delete_Account />}></Route>
                        <Route path='/Chat' element={<Chat />}></Route>
                        <Route path='/*' element={<Navigate to={'/'} />}></Route>
                     </Route>
                  </Routes>
               }
            </div>
         )}
      </GlobalInfo.Provider>

   );
}

export default memo(App);
import React, { useState, useEffect } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import "./Modal_give_review.css";
import { doc_profile } from '../../Image_connection';
import ReactStars from "react-rating-stars-component";
import PulseLoader from "react-spinners/PulseLoader";
import Connection from '../../connection';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import CheckIcon from '@mui/icons-material/Check';

function Modal_give_review({ handlegivereviw, handlereadyreviw, get_apointments, doctor_id, appointment_id, doctor_address, doctor_profile, doctor_name, category, fcm_token, web_fcm }) {
    const [user_rating, setUser_rating] = useState(0);
    const [comment, setComment] = useState('');
    const [spinner, setspinner] = React.useState(false);

    const [user_id, setUser_id] = useState('');
    const [user_name, setUser_name] = useState('');

    useEffect(() => {
        const auth = localStorage.getItem("customer");
        const parsed = JSON.parse(auth);
        const id = parsed[0].id;
        const name = parsed[0].name;
        setUser_id(id)
        setUser_name(name)
    }, [])

    const ratingChanged = (newRating) => {
        setUser_rating(newRating)
    };

    const Add_Review = async () => {
        try {
            if (user_rating == 0) {
                alert("Please give rating.")
            }
            else if (comment == '') {
                alert("Please enter your feedback")
            }
            else {
                setspinner(true)

                const id = user_id
                const rating = user_rating
                const recomend = 'Yes'
                const type = 'review'

                // Date And Time For Notification Date 
                const aa = moment(new Date()).format("YYYY-MM-DD hh:mm A");
                const split = aa.split(' ')
                const date = split[0]
                const timee = split[1]
                const am_pm = split[2]
                const time = timee + "" + am_pm
                //////////////////////////////

                let result = await fetch(Connection + "add_review", {
                    method: 'post',
                    body: JSON.stringify({ id, doctor_id, appointment_id, rating, comment, recomend, time, date, type, user_name, doctor_name }),
                    headers: {
                        'Authorization': localStorage.getItem("JWT_TOKEN"),
                        "Content-Type": 'application/json'
                    }
                })
                console.log("result review =>", result)

                result = await result.json()
                let record = result.response

                if (record === 'Token verification failed') {
                    console.log(record);
                    alert("Something went wrong.");
                }
                else if (record == "success") {
                    app_notification()
                    web_notification()
                    setReviewDone(true)
                    toast.success('Your reviews successfully added.');
                }
                else {
                    alert("Something went wrong.");
                }
                setspinner(false)
            }
        } catch (error) {
            console.error('Error:', error);
            setspinner(false)
            alert("Something went wrong.");
        }
    }


    const app_notification = async () => {
        let to = fcm_token;
        console.log("To is => ", to)

        fetch('https://fcm.googleapis.com/fcm/send', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'key= AAAAaCD4Bcc:APA91bETamRJX5KYdE6sCHUzquj_DpmfqiqH3ogXH1K1m1SH5U5_60OPWrPP5hHMyq_1pQcUN2WMWAPpCOzFDJJ4lDt6X3IDNwLuohzTB8ja3EDGM-FDTePfmCEYpFTsUoH-wguvF6Ux'//cloud server key
            },
            body: JSON.stringify({
                "to": to,
                "priority": "high",
                "notification": {
                    "title": "Mas Clinicas",
                    "body": user_name + " has successfully submitted review",
                    "sound": 'default',
                    "icon": "myicon",
                }
            }
            )
        }).then(res => res.json())
            .then(resjson => console.log("test", resjson))
            .catch(err => console.log("error =>", err))
    }

    const web_notification = async () => {
        let to = web_fcm;
        console.log("To is => ", to)

        fetch('https://fcm.googleapis.com/fcm/send', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'key= AAAAPzWsmh8:APA91bGwGLlejpat2ndQPc3708f5KqvTPRFm9oZ7RQyBB9khWgUn_2JFZJdIf5Lta7dS2M9wTj_OYaxxmZ3FRVckZGoDJpEWyUC81G1hJliAhiKUGaZJS0P3cP-T_Z5VwGKPQzNsk_75'//cloud server key
            },
            body: JSON.stringify({
                "to": to,
                "priority": "high",
                "notification": {
                    "title": "Mas Clinicas",
                    "body": user_name + " has successfully submitted review",
                    "sound": 'default',
                    "icon": "myicon",
                }
            }
            )
        }).then(res => res.json())
            .then(resjson => console.log("test", resjson))
            .catch(err => console.log("error =>", err))
    }


    const [reviewDone, setReviewDone] = useState(false);
    const handleCloseDialog = () => {
        const auth = localStorage.getItem("customer");
        const parsed = JSON.parse(auth);
        const id = parsed[0].id;

        setReviewDone(false);
        handlereadyreviw(false);
        get_apointments(id)
    };

    return (
        <div className="modal_givereviewground">

            <div className="modalgivereviewContainer">
                <div className="titlegivereviewCloseBtn">
                    <button onClick={() => { handlegivereviw(false); }}>
                        <ArrowBackIcon />
                    </button>
                    <h3>Write a Review</h3>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {doctor_profile == null ?
                        <img className="ima_apoint_doc" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIEFLVeftvEbJfqWQ2hnRMSPuF_b8VMn4PdPhXOJdkPm-bfdpWTZZYAUJyHVXS4wrsDAE&usqp=CAU" alt="doctor-image" />
                        :
                        <img
                            style={{ width: '120px', borderRadius: '100%', height: '120px', alignSelf: 'center', marginTop: '10px' }}
                            src={doctor_profile}
                            alt="Doctor Profile"
                        />
                    }

                    <p style={{ color: 'black', fontWeight: 'bold', fontSize: '20px', marginTop: '15px', textAlign: 'center' }}>
                        Dr. {doctor_name}
                    </p>
                    <p style={{ color: 'black', fontSize: '14px', textAlign: 'center', marginTop: '3px' }}>{category}</p>

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                        <ReactStars
                            count={5}
                            onChange={ratingChanged}
                            size={40}
                            isHalf={true}
                            emptyIcon={<i className="far fa-star"></i>}
                            halfIcon={<i className="fa fa-star-half-alt"></i>}
                            fullIcon={<i className="fa fa-star"></i>}
                            activeColor="#874DAF"
                        />
                    </div>

                    <div style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', margin: '18px 0 10px 18px' }}>
                        <p style={{ color: 'black', fontWeight: 'bold', fontSize: '16px' }}>Write Your Review</p>
                    </div>

                    {/* Textarea for review */}
                    <div style={{ width: '90%', height: '120px', alignSelf: 'center' }}>
                        <textarea
                            onChange={(e) => setComment(e.target.value)}
                            value={comment}
                            style={{
                                width: '100%',
                                textAlignVertical: 'top',
                                alignSelf: 'center',
                                backgroundColor: '#f1f1f1',
                                height: '120px',
                                borderRadius: '10px',
                                padding: '15px',
                                color: 'black',
                            }}
                            placeholder={"Your review here"}
                            placeholderTextColor='gray'
                        />
                    </div>

                    {/* <div style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', margin: '18px 18px 30px 18px' }}>
                        <p style={{ color: 'black', fontWeight: 'bold', fontSize: '16px' }}>Would you recommend Dr. {doctor_name} to your friends?</p>
                    </div>

                    <div style={{ marginTop: '20px', marginLeft: '20px' }}>
                        <RadioGroup selectedIndex={recommendation} size={10} onSelect={(value) => handleRecommendationChange(value)}>
                            <RadioButton style={{ labelStyle: { color: 'black', marginRight: '25px' } }} value={0}>
                                Yes
                            </RadioButton>
                            <RadioButton style={{ labelStyle: { color: 'black', marginRight: '25px' } }} value={1}>
                                No
                            </RadioButton>
                        </RadioGroup>
                    </div> */}

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignSelf: 'center', justifyContent: 'space-between', marginTop: '30px', width: '90%', marginBottom: '20px' }}>
                        <div onClick={() => { handlegivereviw(false); }} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '48%', padding: '10px', borderRadius: '20px', backgroundColor: '#c2ecfc' }}>
                            <p style={{ color: '#874DAF', fontWeight: 'bold' }}>Cancel</p>
                        </div>

                        <button onClick={() => Add_Review()} style={{ justifyContent: 'center', alignItems: 'center', width: '48%', padding: '10px', backgroundColor: '#874DAF', borderRadius: '20px', borderWidth: '1px', borderColor: '#874DAF' }}>
                            <p style={{ color: 'white', fontWeight: 'bold' }}>Submit</p>
                        </button>
                    </div>
                </div>


                <Dialog className='cancelapoint_Dialog' open={reviewDone}>
                    <div className='done_view'>
                        <CheckIcon />
                    </div>

                    <DialogTitle className='Cancel_dialog_toptitl'>
                        <h4>Review Successfull!</h4>
                        <h5> Your review has been successfully submitted. Thank you very much.</h5>
                    </DialogTitle>

                    <DialogActions className='cancelapoint_Ok_nd_Close'>
                        <Button onClick={handleCloseDialog} className='cancelapoint_Ok'>Ok</Button>
                    </DialogActions>

                    <ToastContainer />
                </Dialog>

            </div>


            {spinner && (
                <div style={{ width: '100%', height: '100%', backgroundColor: 'rgba(2, 2, 2, 0.8)', position: 'absolute', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <div style={{ display: 'flex', width: '30%', backgroundColor: 'white', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: '30px', boxShadow: '0 1px 5px rgba(0, 0, 0, 0.2)', borderRadius: '6px', }}>
                        <PulseLoader
                            color={"#874DAF"}
                            loading={spinner}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                        <p style={{ fontSize: '16px', color: '#874DAF', fontWeight: 'bold', marginLeft: '15px' }}>
                            Progressing your request
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Modal_give_review;

import React, { useState, useEffect } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link, useNavigate } from 'react-router-dom';
import './Payout_request.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Connection from '../../connection';
import PulseLoader from "react-spinners/PulseLoader";

function Payout_request({ handleClose, balance }) {
    const navigate = useNavigate();

    const [spinner, setSpinner] = useState(false);

    const [doctor_id, setDoctor_id] = React.useState('');
    const [doctor_name, setDoctor_name] = React.useState('');

    const [name, setName] = useState('');
    const [accountNo, setAccountNo] = useState('');
    const [routingNumber, setRoutingNumber] = useState('');
    const [amount, setAmount] = useState('');


    useEffect(() => {
        const auth = localStorage.getItem("customer");
        const parsed = JSON.parse(auth);
        const id = parsed[0].id;
        const name = parsed[0].name;

        setDoctor_id(id)
        setDoctor_name(name)
        setAmount(balance)
    }, [])

    const add_payout_request = async () => {
        try {
            if (name == "") {
                alert("Please enter your bank name");
            }
            else if (accountNo == "") {
                alert("Please enter your account_no");
            }
            else if (routingNumber == "") {
                alert("Please enter your Routing Number");
            }
            else if (amount == "") {
                alert("Please enter your amount.");
            }
            else {
                setSpinner(true)

                let result = await fetch(Connection + "add_payout_request", {
                    method: 'post',
                    body: JSON.stringify({ doctor_id, amount, accountNo, routingNumber, name, doctor_name }),
                    headers: {
                        'Authorization': localStorage.getItem("JWT_TOKEN"),
                        "Content-Type": 'application/json'
                    }
                })
                result = await result.json()
                const record4 = result.response

                if (record4 === 'Token verification failed') {
                    console.log(record4);
                    alert("Something went wrong try again later");
                }
                else if (record4 == "fail") {
                    alert("Something went wrong try again later");
                }
                else {
                    let user = localStorage.getItem("customer");
                    let parsed = JSON.parse(user);
                    parsed[0].balance = "0";
                    localStorage.setItem("customer", JSON.stringify(parsed))

                    toast.success('You have successfully requested for payout!');
                    navigate("/")
                }
                setSpinner(false)
            }
        } catch (error) {
            console.error('Error:', error);
            setSpinner(false)
            alert("Something went wrong try again later");
        }
    }


    return (
        <div className="payout_appointground">

            <div className="payoutapointContainer">
                <div className="titlepayoutapointCloseBtn">
                    <button onClick={() => { handleClose(false); }}>
                        <ArrowBackIcon />
                    </button>
                    <h3>Payout Request</h3>
                </div>

                <h3 id='totaltextpay' style={{ color: 'gray', fontWeight: 'normal' }}>Total Amount</h3>
                <h3 id='balanceinpayout' style={{ color: 'black', fontWeight: 'bold' }}>${balance}</h3>
                <h3 id='paydocumention'>Please enter your corect information for payout request</h3>


                <div className="label">Bank Name</div>
                <input
                    type="text"
                    placeholder="Enter bank name"
                    className="phoneinput"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />

                <div className="label">Account Number</div>
                <input
                    type="text"
                    placeholder="Enter your account number"
                    className="phoneinput"
                    value={accountNo}
                    onChange={(e) => setAccountNo(e.target.value)}
                />

                <div className="label">Routing Number</div>
                <input
                    type="text"
                    placeholder="Enter routing number"
                    className="phoneinput"
                    value={routingNumber}
                    onChange={(e) => setRoutingNumber(e.target.value)}
                />

                <div className="label">Amount</div>
                <input
                    type="text"
                    placeholder="Enter amount"
                    className="phoneinput"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    readOnly
                />


                <div onClick={() => add_payout_request()} className='payout_btn'>
                    <p>Submit</p>
                </div>

                <ToastContainer />
            </div>

            {spinner == true &&
                <div style={{ width: '100%', height: '100%', backgroundColor: 'rgba(2, 2, 2, 0.8)', position: 'absolute', top: 0, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <div style={{ display: 'flex', width: '30%', backgroundColor: 'white', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: '30px', boxShadow: '0 1px 5px rgba(0, 0, 0, 0.2)', borderRadius: '6px', }}>
                        <PulseLoader
                            color={"#874DAF"}
                            loading={spinner}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                        <p style={{ fontSize: '16px', color: '#874DAF', fontWeight: 'bold', marginLeft: '15px' }}>
                            Progressing your request
                        </p>
                    </div>
                </div>
            }
        </div>
    );
}

export default Payout_request;

import React, { useState, useEffect, useMemo, useContext } from 'react'
import { Link, parsePath, useParams, useNavigate, useLocation } from "react-router-dom"

import './Login.css';
import { GlobalInfo } from "../../App"
import Connection from "../../connection";

function Login() {
  const { loginenter } = useContext(GlobalInfo)

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const role = searchParams.get('role');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [isChecked, setIsChecked] = React.useState(true);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };


  useEffect(() => {
    console.log("role =>", role)
    if (role == 'user') {
      let remember = localStorage.getItem('remember');
      if (remember != null) {
        let parsed = JSON.parse(remember);
        let email_1 = parsed[0];
        let password_1 = parsed[1];

        setEmail(email_1)
        setPassword(password_1)
        setIsChecked(true)
      }
    }
    else {
      let remember_1 = localStorage.getItem('remember_1');
      if (remember_1 != null) {
        let parsed = JSON.parse(remember_1);
        let email_1 = parsed[0];
        let password_1 = parsed[1];

        setEmail(email_1)
        setPassword(password_1)
        setIsChecked(true)
      }
    }
  }, [])

  const Login = async (event) => {
    event.preventDefault();

    if (!email) {
      alert('Please enter your email.');
    }
    else if (!password) {
      alert('Please enter your password.');
    }
    else {
      setIsLoading(true);

      console.log(email, password);

      try {
        let result = await fetch(Connection + "user_new_login", {
          method: 'post',
          body: JSON.stringify({ email, password, role }),
          headers: {
            "Content-Type": 'application/json'
          }
        });

        if (!result.ok) {
          throw new Error('Failed to login');
        }

        result = await result.json();

        if (result.success) {
          const Token = result.token;
          const userData = result.userData;
          let rolee = userData[0].role;

          if (rolee == 'user') {
            if (isChecked == true) {
              let table = []
              table.push(email, password)
              console.log('taaaaaaabbbbbbbbbbbble', table)
              localStorage.setItem("remember", JSON.stringify(table))
            }
            else {
              localStorage.removeItem("remember");
            }
            setIsLoading(false);
            localStorage.setItem("customer", JSON.stringify(userData))
            localStorage.setItem("JWT_TOKEN", (Token))
            loginenter()
          }
          else {
            let status = userData[0].status

            if (status == 'approved') {
              if (isChecked == true) {
                let table = []
                table.push(email, password)
                console.log('taaaaaaabbbbbbbbbbbble', table)
                localStorage.setItem("remember_1", JSON.stringify(table))
              }
              else {
                localStorage.removeItem("remember_1");
              }
              setIsLoading(false);
              localStorage.setItem("customer", JSON.stringify(userData))
              localStorage.setItem("JWT_TOKEN", (Token))
              loginenter()
            }
            else {
              setIsLoading(false);
              alert("You have successfully registered but your account is under review.")
            }
          }
        }
        else {
          setIsLoading(false);
          alert(result.message || 'Invalid Email or Password.');
        }
      }
      catch (error) {
        setIsLoading(false);
        console.error('Login error:', error);
        alert('An error occurred while logging in. Please try again later.');
      }
    }
  };


  return (
    <div className="login_container">
      <div className="login_card">
        <h2>Login</h2>
        {/* {error && <p className="error">{error}</p>} */}
        <form onSubmit={Login}>
          <div className="form_first_group">
            <input
              // type="email"
              className="form_control"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              required
            />
          </div>
          <div className="form_group">
            <input
              type="password"
              className="form_control"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              required
            />
          </div>

          <div className='login_remenbr'>
            <input className={isChecked ? 'custom_checkbox_active' : 'custom_checkbox_inactive'} type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
            <h4>Remember Me</h4>
          </div>

          {isLoading ? (
            <div className="loader_container">
              <div className="loader loader-primary"></div>
            </div>
          ) : (
            <button type="submit" className="butn btn-primary">
              Login
            </button>
          )}
        </form>
        <p className="text_center mt_3">
          Don't have an account? <a href="#">Sign up</a>
        </p>
      </div>
    </div>

  );
};

export default Login;
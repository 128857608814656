import React, { useState, useEffect } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Paypal_Options_Wallet from '../../MyComponent/Paypal_Options_Wallet/Paypal_Options_Wallet';

function Add_payment({ handleClose }) {
    const [user_id, setUser_id] = React.useState('');
    const [fee, setFee] = useState('');
    const [addpay, setAddpay] = useState(false);


    useEffect(() => {
        const auth = localStorage.getItem("customer");
        const parsed = JSON.parse(auth);
        const id = parsed[0].id;

        setUser_id(id)
    }, [])

    const navigate = async () => {
        if (fee == "") {
            alert("Please enter amount.");
        }
        else {
            setAddpay(true)
        }

    }

    const handleClosadpay = () => {
        setAddpay(false);
    };

    return (
        <div className="payout_appointground">

            <div className="payoutapointContainer">
                <div className="titlepayoutapointCloseBtn">
                    <button onClick={() => { handleClose(false); }}>
                        <ArrowBackIcon />
                    </button>
                    <h3>Add Amount</h3>
                </div>

                <h3 style={{ color: 'gray', fontWeight: 'normal', fontSize: 14, marginLeft: 15, marginRight: 15, marginTop: 15, marginBottom: 10 }}>This amount will be added to your wallet which lets you quickly book a session.</h3>


                <div className="label">Add Amount</div>
                <input
                    type="text"
                    placeholder="Enter your amount"
                    className="phoneinput"
                    value={fee}
                    // onChange={(e) => setFee(e.target.value)}
                    onChange={(e) => {
                        const inputValue = e.target.value;
                        // Check if the input is numeric and its length doesn't exceed 4 characters
                        if (/^\d{0,4}$/.test(inputValue)) {
                            setFee(inputValue);
                        }
                    }}
                />


                <div onClick={() => navigate()} className='payout_btn'>
                    <p>Save</p>
                </div>
            </div>


            {addpay == true &&
                <Paypal_Options_Wallet show={addpay} handleClose={handleClose} handleClosadpay={handleClosadpay} fee={fee}>
                    <p>Modal Content Goes Here</p>
                </Paypal_Options_Wallet>
            }
        </div>
    );
}

export default Add_payment;

import React, { useState, useEffect, useMemo, useContext } from 'react'
import { BrowserRouter, Routes, Route, useNavigate, Link } from "react-router-dom"
import './Roll_Screen.css';
import Connection from "../../connection";

function Roll_Screen() {
    const navigate = useNavigate();

    const [state, setState] = useState({
        text1: 2,
        text2: 1,
        text3: 1,
        check_design: 'user',
        lang: 0,
    });

    const changebtn = (value, val) => {
        setState((prevState) => ({
            ...prevState,
            text1: 1,
            text2: 1,
            [value]: 2,
            check_design: val,
        }));
    };


    const goingnext = () => {
        const url = `/Login?role=${state.check_design}`;
        navigate(url)
    };



    return (
        <div className="roll_container">
            <div className="roll_card">
                <h2>Which one are you?</h2>

                <div className='maininrolselect'>
                    <div className={(state.text1 === 1 ? 'in_active_button' : 'active_button')} onClick={() => changebtn('text1', 'user')}>
                        <img className='patientimag' src={require('../../assets/Patient_1.png')} alt="Patient" />
                        <span className={(state.text1 === 1 ? 'in_active_text' : 'active_text')}>Patient</span>
                    </div>

                    <div className={(state.text2 === 1 ? 'in_active_button' : 'active_button')} onClick={() => changebtn('text2', 'doctor')}>
                        <img className='doctorimag' src={require('../../assets/Doctor_2.png')} alt="Doctor" />
                        <span className={(state.text2 === 1 ? 'in_active_text' : 'active_text')}>Doctor</span>
                    </div>
                </div>

                <h3>To give you a customized experience we need to know you?</h3>


                <button onClick={goingnext} type="submit" className="butn btn-primary">
                    Continue
                </button>
            </div>
        </div>

    );
};


const styles = {
    active_button: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 10,
        backgroundColor: '#874DAF',
        width: '46%',
        height: '25vh', // Adjust as needed
        boxShadow: '0px 2px 3.84px rgba(0, 0, 0, 0.25)',
        elevation: 3,
    },
    in_active_button: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 10,
        backgroundColor: 'white',
        width: '46%',
        height: '25vh', // Adjust as needed
    },
    active_text: {
        color: 'white',
        fontWeight: 'bold',
        marginTop: 10,
        fontSize: 16,
    },
    in_active_text: {
        color: 'gray',
        fontWeight: 'bold',
        marginTop: 10,
        fontSize: 16,
    },
};

export default Roll_Screen;
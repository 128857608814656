import React, { useState, useEffect } from 'react'
import "./Modal.css";
import Connection from '../../connection';
import PulseLoader from "react-spinners/PulseLoader";
import { Link, useNavigate } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import Modal_Pay_Option from '../Modal_Pay_Option/Modal_Pay_Option';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import CheckIcon from '@mui/icons-material/Check';
import Modal_payment_done from '../Modal_payment_done/Modal_payment_done';

function Modal({ setOpenModal, handleClose, show, day_11, final_date_1, doctor_id, doctor_name, insurance_fee, fee, category, city, email, fcm_token, web_fcm, type_of, usertype }) {


  const navigate = useNavigate();

  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDayOfWeek, setSelectedDayOfWeek] = useState(null);
  const [daysList, setDaysList] = useState([]);
  const [skalton, setSkalton] = useState(true);

  const [onlineSchedules, setOnlineSchedules] = useState({
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  });

  const [offlineSchedules, setOfflineSchedules] = useState({
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  });

  const [alreadyTimeBookedSlot, setAlreadyTimeBookedSlot] = useState([]);


  const [all_promos, setAll_promos] = React.useState([]);
  const [shopayment, setShopayment] = useState(false);



  const [reason, setReason] = React.useState("");
  const [symptoms, setSymptoms] = React.useState("");
  const [issue, setIssue] = React.useState("");
  const [allergic, setAllergic] = React.useState("");

  const [notification_date, setNotification_date] = React.useState("");
  const [notification_time, setNotification_time] = React.useState("");

  const [user_id, setUserId] = useState('');
  const [user_name, setUser_name] = useState('');
  const [promo_end, setPromo_end] = useState('false');

  const [showpayment_done, setShowpayment_done] = useState(false);

  const [isLoading, setIsLoading] = useState(false);



  useEffect(() => {
    calculateDaysList();

    // User ID From LocalStorage
    const auth = localStorage.getItem("customer");
    const parsed = JSON.parse(auth);
    const id = parsed[0].id;
    const user_name = parsed[0].name;
    const promo = parsed[0].promo;

    setUserId(id)
    setUser_name(user_name)

    if (promo != 'true') {
      setPromo_end('false')
    } else {
      setPromo_end('true')
    }
    /////////////


    const jwt_tokens = localStorage.getItem("JWT_TOKEN");
    check_promo(jwt_tokens)

    setTimeout(() => {
      All_Appoint_This_Doc(id, jwt_tokens)
    }, 100);


    // Date And Time For Notification
    const aa = moment(new Date()).format("YYYY-MM-DD hh:mm A");
    const split = aa.split(' ')
    const datwe = split[0]
    const time = split[1]
    const am_pm = split[2]
    const final_time = time + "" + am_pm
    setNotification_date(datwe)
    setNotification_time(final_time)
    /////////////
  }, [])


  const check_promo = async (Token) => {
    try {
      let result = await fetch(Connection + "check_promo_code_a", {
        headers: {
          'Authorization': Token,
        }
      })
      result = await result.json()
      const record4 = result.response;

      if (record4 === 'Token verification failed') {
        console.log(record4);
        setAll_promos([])
      }
      else if (record4 == "fail") {
        setAll_promos([])
      }
      else {
        setAll_promos(record4)
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }


  const All_Appoint_This_Doc = async (id, Token) => {
    try {
      const user_id = id
      let result = await fetch(Connection + "patient_Appoint_specifc_Doc", {
        method: 'post',
        body: JSON.stringify({ user_id, doctor_id }),
        headers: {
          'Authorization': Token,
          "Content-Type": 'application/json'
        }
      })

      result = await result.json()
      const record4 = result.response

      if (record4 == "Token verification failed") {
        console.log("When Token verification failed =>", record4)
        setAlreadyTimeBookedSlot([])
      }
      else if (record4 != 'fail') {
        let len = record4.length

        for (let i = 0; i < len; i++) {
          const record = record4[i];

          // Create an object with both day and time properties
          const slot = {
            day: record.day,
            time: record.time,
            date: record.date,
          };

          setAlreadyTimeBookedSlot(prevSlots => [...prevSlots, slot]);
        }
      }
      else {
        setAlreadyTimeBookedSlot([])
      }

      get_schedule(Token)
    } catch (error) {
      console.error('Error:', error);
    }
  };


  const get_schedule = async (Token) => {
    try {
      let result = await fetch(Connection + "get_schedule", {
        method: 'post',
        body: JSON.stringify({ doctor_id }),
        headers: {
          'Authorization': Token,
          "Content-Type": 'application/json'
        }
      })

      result = await result.json()
      const record4 = result.response

      if (record4 === 'Token verification failed') {
        console.log(record4);
        setOnlineSchedules({})
        setOfflineSchedules({})
      }
      else if (record4 === "fail") {
        console.log(record4);
        setOnlineSchedules({})
        setOfflineSchedules({})
      }
      else {
        const { online_arr, offline_arr } = record4[0];

        if (online_arr != '' && online_arr != null) {
          console.log("online array => ", online_arr)

          const parsedOnlineArr = JSON.parse(online_arr);

          setOnlineSchedules(parsedOnlineArr)
        }
        else {
          setOnlineSchedules({})
        }

        if (offline_arr != '' && offline_arr != null) {
          console.log("offline array => ", offline_arr)

          const parsedOfflineArr = JSON.parse(offline_arr);

          setOfflineSchedules(parsedOfflineArr)
        }
        else {
          setOfflineSchedules({})
        }
      }
      setSkalton(false)
    } catch (error) {
      console.error('Error:', error);
      setSkalton(false)
    }
  };


  // For 7 days And time slot select
  const calculateDaysList = () => {
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const today = new Date().getDay();
    const updatedDaysList = daysOfWeek.slice(today).concat(daysOfWeek.slice(0, today));
    setDaysList(updatedDaysList);

    const currentDay = moment().format('dddd');
    const currentIndex = updatedDaysList.indexOf(currentDay);
    if (currentIndex !== -1) {
      const formattedDate = getFormattedDate(currentIndex);
      handleSelectDay(currentDay, formattedDate);
    }
  };

  const handleSelectDay = (day, date) => {
    const selectedDayOfWeeek = getDayOfWeek(date);
    setSelectedDay(day);
    setSelectedSlot(null);
    setSelectedDate(date);
    setSelectedDayOfWeek(selectedDayOfWeeek);
  };

  const handleSelectSlot = (slot) => {
    setSelectedSlot(slot);
  };

  const getFormattedDate = (offset) => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + offset);

    const month = months[currentDate.getMonth()];
    const dayOfMonth = currentDate.getDate().toString().padStart(2, '0');
    const year = currentDate.getFullYear();

    return `${month} ${dayOfMonth}, ${year}`;
  };

  const getDayOfWeek = (date) => {
    const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const selectedDatee = new Date(date);
    return weekdays[selectedDatee.getDay()];
  };

  const getFormattedDisplayDate = (offset) => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + offset);

    const month = months[currentDate.getMonth()];
    const dayOfMonth = currentDate.getDate();
    const dayOfWeek = getDayOfWeek(currentDate);

    return `${month} ${dayOfMonth} ${dayOfWeek}`;
  };

  const renderDayButton = (day, index) => {
    const formattedDisplayDate = getFormattedDisplayDate(index);
    const formattedDate = getFormattedDate(index);

    return (
      <div
        key={day}
        className={`dayButton ${selectedDay === day && 'selectedDayButton'}`}
        onClick={() => handleSelectDay(day, formattedDate)}
      >
        <span className={`dayOfWeekText ${selectedDay === day && 'selecteddayOfWeekText'}`}>{formattedDisplayDate.split(' ')[2]}</span>
        <span className={`dayOfWeekText ${selectedDay === day && 'selecteddayOfWeekText'}`}>{formattedDate}</span>
      </div>
    );
  };

  const convertDayNameToNumber = (dayName) => {
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    return daysOfWeek.indexOf(dayName);
  }


  const renderSlotsList = () => {
    const slotsData = type_of === 'online' ? onlineSchedules : offlineSchedules;

    const currentDate = new Date();
    const liveDayOfWeek = currentDate.getDay();

    if (slotsData != null) {
      const slots = slotsData[selectedDay] || [];

      const alreadyBookedSlots = alreadyTimeBookedSlot;

      if (slots.length > 0) {
        return (
          <div className="slots_list">
            {slots.map((item) => {
              const isSlotBooked = alreadyBookedSlots.some((slot) => {
                return slot.day === selectedDayOfWeek && slot.date === selectedDate && slot.time === item;
              });

              const [timeString, period] = item.split(' ');
              const [slotHour, slotMinute] = timeString.split(':').map(Number);

              let hour24 = slotHour;
              if (period) {
                if (period.toLowerCase() === 'pm' && slotHour < 12) {
                  hour24 = slotHour + 12;
                }
              }

              const slotTime = new Date(currentDate);
              slotTime.setHours(hour24, slotMinute, 0, 0);

              const dayNumber = convertDayNameToNumber(selectedDay);
              const isSlotInPast = liveDayOfWeek === dayNumber && slotTime < currentDate;

              return (
                <div
                  key={item}
                  onClick={() => {
                    if (!isSlotBooked && !isSlotInPast) {
                      handleSelectSlot(item);
                    }
                    else if (isSlotInPast) {
                      alert('Please select upcoming time slot')
                    }
                    else {
                      alert('This time slot is already booked.')
                    }
                  }}
                  className={`slotbutton ${selectedSlot === item ? 'selectedSlotButton' : ''
                    } ${isSlotBooked ? 'bookedSlotButton' : ''} ${isSlotInPast ? 'pastSlotButton' : ''}`}
                >
                  <span
                    className={`slotText ${selectedSlot === item ? 'selectedSlotText' : ''
                      } ${isSlotBooked ? 'bookedSlotText' : ''} ${isSlotInPast ? 'pastSlotText' : ''}`}
                  >
                    {item}
                  </span>
                </div>
              );
            })}
          </div>
        );
      } else {
        return (
          <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 10 }}>
            <span id='noslotavalable' style={{ marginTop: 10, color: 'black' }}>No Slots Available</span>
          </div>
        );
      }
    } else {
      return (
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 10 }}>
          <span id='noslotavalable' style={{ marginTop: 10, color: 'black' }}>No Slots Available</span>
        </div>
      );
    }
  };



  const handlShowpayment = () => {
    // handleClose(false)
    if (type_of != 'urgent') {
      if (!selectedSlot) {
        alert('Please select Appointment time for moving next.');
        return false
      }
    }
    if (!reason) {
      alert('Please Enter Reason for Visit.');
      return false
    }
    if (!symptoms) {
      alert('Please Enter your Symptoms.');
      return false
    }
    if (!issue) {
      alert('Please enter allergies or pre-existing health issues.');
      return false
    }
    if (!allergic) {
      alert('Please enter allergic to any certain medications.');
      return false
    }

    if (usertype == "hisd") {
      setTimeout(() => {
        setShowpayment_done(true);
      }, 100);
    }
    else {
      setTimeout(() => {
        setShopayment(true);
      }, 100);
    }
  };



  const handlClosepayment = () => {
    setShopayment(false);
  };

  const handlepaymentdoneClose = () => {
    setShowpayment_done(false);
  };



  return (
    <div className="modalBackground">

      <div className="modalContainer">
        <div className="titleCloseBtn">
          {type_of === "urgent" &&
            <h2>Urgent Care</h2>
          }
          {type_of === "offline" &&
            <h2>Clinic Appointment</h2>
          }
          {type_of === "online" &&
            <h2>Online Appointment</h2>
          }
          <button onClick={() => { handleClose(false); }}>X</button>
        </div>

        <hr style={{ marginTop: 15, marginBottom: 15 }} />

        {type_of != "urgent" &&
          <>
            <div className="title">
              <h3 style={{ color: 'gray', fontWeight: 'normal' }}>Select Date and Time</h3>
            </div>

            <div className="daysContainer">
              {daysList.map((day, index) => renderDayButton(day, index))}
            </div>

            <h3 style={{ color: 'gray', fontWeight: 'normal', fontSize: 16, marginTop: 5 }}>List of Schedule</h3>

            {skalton == true ?
              <SkeletonTheme baseColor="lightgray" highlightColor="#bebebe">
                <div className='all_time_slot'>
                  <Skeleton width={100} height={35} style={{ marginLeft: 5, marginRight: 5, marginTop: 10 }} />
                  <Skeleton width={100} height={35} style={{ marginLeft: 5, marginRight: 5, marginTop: 10 }} />
                  <Skeleton width={100} height={35} style={{ marginLeft: 5, marginRight: 5, marginTop: 10 }} />
                  <Skeleton width={100} height={35} style={{ marginLeft: 5, marginRight: 5, marginTop: 10 }} />
                  <Skeleton width={100} height={35} style={{ marginLeft: 5, marginRight: 5, marginTop: 10 }} />
                  <Skeleton width={100} height={35} style={{ marginLeft: 5, marginRight: 5, marginTop: 10 }} />
                  <Skeleton width={100} height={35} style={{ marginLeft: 5, marginRight: 5, marginTop: 10 }} />
                  <Skeleton width={100} height={35} style={{ marginLeft: 5, marginRight: 5, marginTop: 10 }} />
                  <Skeleton width={100} height={35} style={{ marginLeft: 5, marginRight: 5, marginTop: 10 }} />
                  <Skeleton width={100} height={35} style={{ marginLeft: 5, marginRight: 5, marginTop: 10 }} />
                  <Skeleton width={100} height={35} style={{ marginLeft: 5, marginRight: 5, marginTop: 10 }} />
                  <Skeleton width={100} height={35} style={{ marginLeft: 5, marginRight: 5, marginTop: 10 }} />
                </div>
              </SkeletonTheme>
              :
              <>
                {selectedDay && (
                  <div style={{ marginTop: 5, marginBottom: 10 }}>
                    {renderSlotsList()}
                  </div>
                )}
              </>
            }
          </>
        }


        <div className="title">
          <h3 style={{ color: 'gray', fontWeight: 'normal' }}>Issue for Add Appointment</h3>
        </div>

        <div class="patient_first_name">
          <div className='update_width_both_name_email'>
            <div className='update_width_name'>
              <input type="text" class="" id="name" placeholder="Reason for Visit" onChange={(e) => setReason(e.target.value)} />
            </div>

            <div className='update_width_name'>
              <input type="email" class="" id="email" placeholder="Describe illness or health problem." onChange={(e) => setSymptoms(e.target.value)} />
            </div>
          </div>

          <div className='update_width_both_name_email' style={{ marginTop: 15 }}>
            <div className='update_width_name'>
              <input type="text" class="" id="name" placeholder="Do you have any allergies or health issues?" onChange={(e) => setIssue(e.target.value)} />
            </div>

            <div className='update_width_name'>
              <input type="email" class="" id="email" placeholder="Are you allergic to any certain medications?" onChange={(e) => setAllergic(e.target.value)} />
            </div>
          </div>
        </div>


        <div onClick={handlShowpayment} className='book_btn'>
          <p>Book Appointment</p>
        </div>


      </div>



      {shopayment &&
        <Modal_Pay_Option
          shopayment={true}
          handlClosepayment={handlClosepayment}
          day={selectedDayOfWeek}
          date={selectedDate}
          time={selectedSlot}
          doctor_id={doctor_id}
          doctor_name={doctor_name}
          insurance_fee={insurance_fee}
          fee={fee}
          category={category}
          city={city}
          reason={reason}
          symptoms={symptoms}
          issue={issue}
          allergic={allergic}
          all_promos={all_promos}
          email={email}
          fcm_token={fcm_token}
          web_fcm={web_fcm}
          type_of={type_of}
          usertype={usertype}
          check_payment_ins_or_appointment={'Appointment'}
        >
          <p>Modal Content Goes Here</p>
        </Modal_Pay_Option>
      }



      {showpayment_done &&
        <Modal_payment_done
          showpayment_done={true}
          handlepaymentdoneClose={handlepaymentdoneClose}
          day={selectedDayOfWeek}
          date={selectedDate}
          time={selectedSlot}
          // insurance_fee={insurance_fee}
          // appointment_price={fee}
          category={category}
          doctor_name={doctor_name}
          city={city}
          doctor_id={doctor_id}
          reason={reason}
          symptoms={symptoms}
          issue={issue}
          allergic={allergic}
          method={"hisd"}
          email={email}
          fcm_token={fcm_token}
          web_fcm={web_fcm}
          promo_end={promo_end}
          nav_payment_done={'Appointment'}

          type_of={type_of}
          usertype={usertype}
        >
          <p>Modal Appointment Done Goes Here</p>
        </Modal_payment_done>
      }



      {isLoading == true &&
        <div className='spinner_main_loader'>
          <div className='spinner_inside'>
            <PulseLoader
              color={"#874DAF"}
              loading={isLoading}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        </div>
      }
    </div>
  );
}

export default Modal;
